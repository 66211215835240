"use client";

import type { AnalyticsBrowser } from "@segment/analytics-next";
import { createContext } from "react";

import type { AnalyticsEvent } from "../types";

export type AnalyticsContext = {
  analytics: AnalyticsBrowser;
  logout: Function;
  track: (props: AnalyticsEvent) => Promise<void>;
};

export const analyticsContext = createContext<AnalyticsContext>({
  analytics: null,
  logout: () => {},
  track: () => Promise.resolve(),
});
