"use client";

import type {
  Queue,
  QueueContentType,
  QueueContextShape,
  QueueManagedItem,
  Track,
} from "@packages/sdk";
import {
  useRequestCollectionNextUp,
  useRequestQueue,
  useRequestQueueCurrentItemIndex,
  useRequestQueueItems,
  useRequestQueueNextItems,
  useRequestQueueSkips,
} from "@packages/sdk";
import type { ComponentPropsWithoutRef } from "react";
import { useCallback, useEffect, useRef, useState } from "react";
import isEqual from "react-fast-compare";
import { v4 as uuid } from "uuid";

import { CompletionCacheProvider } from "../CompletionCacheProvider";
import { QueueContext } from "./queueContext";

export type QueueProviderProps = Omit<
  ComponentPropsWithoutRef<typeof QueueContext.Provider>,
  "value"
>;

export const QueueProvider = ({ children, ...props }: QueueProviderProps) => {
  const { requestGet: requestCollectionNextUp } = useRequestCollectionNextUp();
  const { requestPut: requestSetQueue } = useRequestQueue();
  const { mutatePut: requestUpdatePosition } =
    useRequestQueueCurrentItemIndex();
  const { mutatePost: requestAddToQueue } = useRequestQueueItems();
  const [fullQueue, setFullQueue] = useState<Array<QueueManagedItem>>([]);
  const [pastQueue, setPastQueue] = useState<Array<QueueManagedItem>>([]);
  const [upcomingQueue, setUpcomingQueue] = useState<Array<QueueManagedItem>>(
    [],
  );
  const [hourlySkipsRemaining, setHourlySkipsRemaining] = useState<number>(0);
  const [dailySkipsRemaining, setDailySkipsRemaining] = useState<number>(0);
  const [position, setPosition] = useState<number | null>(null);
  const [currentItem, setCurrentItem] = useState<QueueManagedItem | null>(null);
  const [shuffled, setShuffled] = useState<boolean>(false);
  const [repeating, setRepeating] = useState<"none" | "one" | "all">("none");
  const [loading, setLoading] = useState<boolean>(false);

  const { mutatePost: radioSkip, query: initialSkips } = useRequestQueueSkips();
  const { mutatePost: nextRadioItems } = useRequestQueueNextItems();

  useEffect(() => {
    if (initialSkips?.data) {
      setHourlySkipsRemaining(initialSkips.data.hourly_skips_remaining);
      setDailySkipsRemaining(initialSkips.data.daily_skips_remaining);
    }
  }, [initialSkips?.data]);

  const addToQueue: QueueContextShape["addToQueue"] = async ({
    id,
    position,
    type,
  }) => {
    setLoading(true);
    const result =
      type !== "album"
        ? setLocalQueue(
            await requestAddToQueue.mutateAsync({
              content: {
                content_id:
                  type === "collection-next"
                    ? await getPrayerFromCollection(id)
                    : id,
                content_type: type === "collection-next" ? "prayer" : type,
              },
              queue_position: position,
            }),
          )
        : null;
    setLoading(false);
    return result;
  };

  const addNextItems = async () =>
    setLocalQueue(await nextRadioItems.mutateAsync());

  const getPrayerFromCollection = async (
    collectionId: number,
  ): Promise<number> => {
    const nextUp = await requestCollectionNextUp.request({ id: collectionId });
    return nextUp.id;
  };

  const back: QueueContextShape["back"] = useCallback(() => {
    if (pastQueue.length === 0 && repeating !== "all") {
      return null;
    }
    const changeTo = pastQueue.pop();
    if (!changeTo) return null;

    const newPosition = fullQueue.findIndex((i) => i.uuid === changeTo.uuid);
    if (currentItem) upcomingQueue.unshift(currentItem);

    // intentionally not awaited
    requestUpdatePosition.mutate({ current_item_index: newPosition });

    setCurrentItem(changeTo);
    setPastQueue([...pastQueue]);
    setUpcomingQueue([...upcomingQueue]);
    setPosition(newPosition);

    return changeTo;
  }, [fullQueue, pastQueue, upcomingQueue, repeating, currentItem]);

  const forward: QueueContextShape["forward"] = useCallback(
    async (skip: boolean) => {
      if (skip && currentItem?.type === "radio_song") {
        // throws if the user cannot skip anymore
        const result = await radioSkip.mutateAsync();
        setHourlySkipsRemaining(result.hourly_skips_remaining);
        setDailySkipsRemaining(result.daily_skips_remaining);
      }

      if (
        repeating === "one" ||
        (repeating === "all" && fullQueue.length === 1)
      ) {
        return currentItem;
      }

      if (repeating === "all" && upcomingQueue.length === 0) {
        const changeTo = pastQueue.shift();
        if (!changeTo) return null;

        if (currentItem) pastQueue.push(currentItem);

        // intentionally not awaited
        requestUpdatePosition.mutate({ current_item_index: 0 });

        setCurrentItem(changeTo);
        setPastQueue([]);
        setUpcomingQueue([...pastQueue]);
        setPosition(0);

        return changeTo;
      }

      let full: QueueManagedItem[], upcoming: QueueManagedItem[];
      if (upcomingQueue.length === 0 && currentItem?.type === "radio_song") {
        full = await addNextItems();
        upcoming = full;
      } else {
        full = fullQueue;
        upcoming = upcomingQueue;
      }

      const changeTo = upcoming.shift();
      if (!changeTo) return null;

      const newPosition = full.findIndex((i) => i.uuid === changeTo.uuid);
      if (currentItem) pastQueue.push(currentItem);

      // intentionally not awaited
      requestUpdatePosition.mutate({ current_item_index: newPosition });

      setCurrentItem(changeTo);
      setPosition(newPosition);
      setUpcomingQueue([...upcomingQueue]);
      setPastQueue([...pastQueue]);

      return changeTo;
    },
    [currentItem, repeating, fullQueue, pastQueue, upcomingQueue],
  );

  const newQueue: QueueContextShape["newQueue"] = useCallback(
    async ({ items }) => {
      setLoading(true);
      let targetItem = null;
      const backendQueue = await requestSetQueue.request({
        content: await Promise.all(
          items.map(async (i) => {
            let content_id = i.id;
            let content_type = i.type;
            if (i.type === "collection-next") {
              content_id = await getPrayerFromCollection(i.id);
              content_type = "prayer";
            } else if (i.type === "album") {
              content_type = "collection";
              targetItem = i.target;
            }
            return {
              content_id,
              content_type: content_type as QueueContentType,
            };
          }),
        ),
        current_item_index: position ?? 0,
      });
      if (targetItem) {
        const newPosition = backendQueue.items.findIndex(
          (i) => i.type === "prayer" && i.prayer.id === targetItem,
        );
        requestUpdatePosition.mutate({ current_item_index: newPosition });
        backendQueue.current_item_index = newPosition;
      }
      setLoading(false);
      return setLocalQueue(backendQueue);
    },
    [position],
  );

  const localReorder: QueueContextShape["localReorder"] = useCallback(
    (newUpcomingQueue) => {
      setUpcomingQueue(newUpcomingQueue);
    },
    [],
  );

  const clearQueue: QueueContextShape["clearQueue"] = () => {
    return setLocalQueue({
      current_item_index: null,
      items: [],
    });
  };

  // refs to prevent rerenders of audio
  const lastRequest = useRef<number | null>(null);
  const fullQueueRef = useRef<typeof fullQueue>(fullQueue);
  const newQueueRef = useRef<typeof newQueue>(newQueue);

  useEffect(() => {
    fullQueueRef.current = fullQueue;
  }, [fullQueue]);

  useEffect(() => {
    newQueueRef.current = newQueue;
  }, [newQueue]);

  useEffect(() => {
    return () => {
      setCurrentItem(null);
    };
  }, []);

  const refresh: QueueContextShape["refresh"] = async () => {
    // if we last requested content less than 24 hours ago, whatever error triggered this request is not related to
    // CloudFront permissions
    if (!lastRequest.current || Date.now() - lastRequest.current >= 86400) {
      await newQueueRef.current({
        items: fullQueueRef.current.map((qi) => ({
          type: "audio",
          id: qi.selected_audio.id,
        })),
      });
    }

    return Promise.reject({ error: 100, message: "refresh too soon" });
  };

  const repeat: QueueContextShape["repeat"] = useCallback(() => {
    let newState: typeof repeating;
    switch (repeating) {
      case "none":
        newState = "all";
        break;
      case "all":
        newState = "one";
        break;
      case "one":
      default:
        newState = "none";
        break;
    }

    setRepeating(newState);

    return newState;
  }, [repeating]);

  const shuffle: QueueContextShape["shuffle"] = useCallback(() => {
    if (!shuffled) {
      const oldArr: QueueManagedItem[] = Object.assign([], upcomingQueue);
      const newArr: QueueManagedItem[] = [];
      while (newArr.length < upcomingQueue.length) {
        let nextIdx: number;
        if (newArr.length === 0 && oldArr.length > 1) {
          nextIdx = Math.random() * (oldArr.length - 1) + 1;
        } else {
          nextIdx = Math.random() * oldArr.length;
        }
        newArr.push(oldArr.splice(nextIdx, 1)[0]);
      }

      setShuffled(true);
      setUpcomingQueue(newArr);

      return true;
    }

    setShuffled(false);
    setUpcomingQueue(fullQueue.slice((position ?? 0) + 1));

    return false;
  }, [shuffled, upcomingQueue, fullQueue, position, currentItem]);

  const setLocalQueue = useCallback(
    (newQueue: Queue) => {
      // this works because every "add", "swap", and "new" queue request gets a complete list of the queue from
      // the backend; we don't need to track each item individually because, if we've added or changed anything,
      // then all the links are updated
      lastRequest.current = Date.now();

      const newItems = [];

      for (const itm of newQueue.items) {
        // match to an existing item, OR determine that it's new
        const managedItm: QueueManagedItem = {
          ...itm,
          errored: false,
          uuid: uuid(),
        };
        const existing = fullQueue.find(
          (qi) => qi.selected_audio.id === itm.selected_audio.id,
        );
        if (existing && !existing.errored && !newItems.includes(existing)) {
          newItems.push(existing);
        } else {
          newItems.push(managedItm);
        }
      }

      setFullQueue(newItems);
      setPastQueue(
        newQueue.current_item_index > 0
          ? newItems.slice(0, newQueue.current_item_index)
          : [],
      );
      setUpcomingQueue(
        newQueue.current_item_index >= 0
          ? newItems.slice(newQueue.current_item_index + 1)
          : newItems,
      );
      if (
        position !== newQueue.current_item_index ||
        !isEqual(currentItem, newItems[newQueue.current_item_index])
      ) {
        setPosition(newQueue.current_item_index);
        setCurrentItem(newItems[newQueue.current_item_index]);
      }
      return newItems;
    },
    [fullQueue, position, currentItem],
  );

  const swap = useCallback(
    async (newItem: Track): Promise<void> => {
      await newQueue({
        items: fullQueue.map((qItem: QueueManagedItem, idx: number) => {
          if (idx === position) {
            return { id: newItem.id, type: "audio" };
          }
          return { id: qItem.selected_audio.id, type: "audio" };
        }),
      });
    },
    [fullQueue, position],
  );

  const deleteItem = useCallback(
    async (uuid: string): Promise<void> => {
      localReorder(upcomingQueue.filter((qi) => qi.uuid !== uuid));
      if (!shuffled) {
        await newQueue({
          items: fullQueue
            .filter((qi) => qi.uuid !== uuid)
            .map((qi) => ({ id: qi.selected_audio.id, type: "audio" })),
        });
      }
    },
    [fullQueue, upcomingQueue, shuffled],
  );

  const skipTo = useCallback(
    (uuid: string): QueueManagedItem | null => {
      const newPosition = fullQueue.findIndex((i) => i.uuid === uuid);

      const changeTo = fullQueue[newPosition];
      if (!changeTo) return null;

      const numToSkip = upcomingQueue.findIndex((i) => i.uuid === uuid);

      const skipped = upcomingQueue.splice(0, numToSkip + 1); // plus new current
      if (currentItem) {
        pastQueue.push(currentItem);
      }
      pastQueue.push(...skipped.slice(0, -1)); // now minus current

      // intentionally not awaited
      requestUpdatePosition.mutate({ current_item_index: newPosition });

      setCurrentItem(changeTo);
      setPosition(newPosition);
      setPastQueue([...pastQueue]);
      setUpcomingQueue([...upcomingQueue]);

      return changeTo;
    },
    [fullQueue, upcomingQueue, currentItem, pastQueue],
  );

  return (
    <QueueContext.Provider
      value={{
        addToQueue,
        back,
        clearQueue,
        currentItem,
        dailySkipsRemaining,
        delete: deleteItem,
        forward,
        fullQueue,
        hourlySkipsRemaining,
        loading,
        localReorder,
        newQueue,
        pastQueue,
        position,
        refresh,
        repeat,
        repeating,
        shuffle,
        shuffled,
        skipTo,
        swap,
        upcomingQueue,
      }}
      {...props}
    >
      <CompletionCacheProvider>{children}</CompletionCacheProvider>
    </QueueContext.Provider>
  );
};
