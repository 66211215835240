"use client";

import * as stylex from "@stylexjs/stylex";
import type { ForwardRefExoticComponent, RefAttributes } from "react";
import { forwardRef, useState } from "react";
import type { Control } from "react-hook-form";
import { useController } from "react-hook-form";

import {
  colors,
  fontSizes,
  lineHeights,
  numericPercentages,
  numericPixels,
  radius,
  semanticColors,
  spacing,
  stroke,
} from "../../../../../global/stylex/vars.stylex";
import {
  type HallowElement,
  type HallowElementProps,
  type WithStylexArray,
} from "../../../../types";
import { Text } from "../../../_base";

const hallowElement: HallowElement = "input";

const styles = stylex.create({
  input: {
    backgroundColor: colors.transparent,
    borderColor: semanticColors.neutralsLowest,
    borderRadius: radius.ms,
    borderStyle: "solid",
    borderWidth: stroke.regular,
    color: semanticColors.primary,
    fontSize: fontSizes.bodyM,
    height: numericPixels[40],
    lineHeight: lineHeights.bodyM,
    minHeight: numericPixels[64],
    outline: "none",
    padding: `${spacing.ms} ${spacing.m}`,
    paddingTop: numericPixels[28],
    transition: "border 200ms ease-in",
    width: numericPercentages[100],
    ":hover:not(:disabled):not(:focus)": {
      borderColor: semanticColors.neutralsVeryLow,
    },
    ":disabled": {
      color: semanticColors.neutralsMedHigh,
      cursor: "not-allowed",
    },
    ":focus": {
      borderColor: semanticColors.primary,
      outline: "none",
    },
  },
  label: {
    display: "block",
    position: "relative",
  },
  placeholder: {
    color: semanticColors.neutralsHigh,
    left: numericPixels[18],
    pointerEvents: "none",
    position: "absolute",
    top: numericPixels[22],
    transformOrigin: "0% 50%",
    transition: "all 250ms",
  },
  populated: {
    top: numericPixels[10],
    transform: "scale(.75)",
  },
});

export type TextInputProps = WithStylexArray<
  HallowElementProps<typeof hallowElement>
>;

export const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
  ({ name, value, placeholder, styleXArray = [], ...props }, ref) => {
    const [focused, setFocused] = useState<boolean>(false);
    return (
      <label
        onBlur={() => setFocused(false)}
        onFocus={() => setFocused(true)}
        {...stylex.props(styles.label, styleXArray)}
      >
        <Text
          size="m"
          type="body"
          styleXArray={[
            styles.placeholder,
            (focused || !!value) && styles.populated,
          ]}
        >
          {placeholder}
        </Text>
        <input
          {...props}
          value={value}
          {...stylex.props(styles.input, styleXArray)}
          ref={ref}
        />
      </label>
    );
  },
) as ForwardRefExoticComponent<
  Omit<TextInputProps, "ref"> & RefAttributes<HTMLInputElement>
> & { Field: (props: TextInputFieldProps) => JSX.Element };

type TextInputFieldProps = TextInputProps & {
  control: Control<any, any>;
};

TextInput.Field = ({
  control,
  name,
  required,
  ...props
}: TextInputFieldProps) => {
  const {
    field: { ref, ...field },
  } = useController({
    name,
    control,
    rules: { required },
  });

  return (
    <TextInput
      {...field}
      {...props}
      value={field.value}
      ref={ref}
      onChange={(event) => field.onChange?.(event?.target.value)}
    />
  );
};

TextInput.displayName = "TextInput";
