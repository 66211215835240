"use client";

import { useContext, useEffect, useState } from "react";

import { Theme } from "../types";
import { themeContext } from "./themeContext";

type CurrentTheme = Theme.DARK | Theme.LIGHT;
export const useTheme = () => {
  const {
    setTheme,
    theme,
    themeContainerRef,
    fontStyle,
    setFontStyle,
    letterSpacing,
    setLetterSpacing,
    wordSpacing,
    setWordSpacing,
  } = useContext(themeContext);

  const getCurrentTheme = (
    theme: Theme,
    isSystemDark: boolean,
  ): CurrentTheme => {
    return theme === Theme.MATCH
      ? isSystemDark
        ? Theme.DARK
        : Theme.LIGHT
      : theme;
  };
  const [currentTheme, setCurrentTheme] = useState(Theme.LIGHT);

  const toggleTheme = () =>
    setTheme(theme === Theme.DARK ? Theme.LIGHT : Theme.DARK);

  useEffect(() => {
    if (!window) return;
    const darkModeMatch = window.matchMedia("(prefers-color-scheme: dark)");
    setCurrentTheme(getCurrentTheme(theme, darkModeMatch.matches));
    darkModeMatch.onchange = (event) => {
      setCurrentTheme(getCurrentTheme(theme, event.matches));
    };
  }, [theme]);

  return {
    setTheme,
    theme,
    themeContainerRef,
    currentTheme,
    toggleTheme,
    fontStyle,
    setFontStyle,
    letterSpacing,
    setLetterSpacing,
    wordSpacing,
    setWordSpacing,
  };
};
