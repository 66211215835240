import { CommunityStatus, CommunityType } from "../../src";

export const MOCK_DATA_COMMUNITY = {
  id: 152,
  code: "",
  description: null,
  image_url: "https://d3eq070d6vdeo4.cloudfront.net/community_profile_152.webp",
  color_hex: "#362247",
  address: null,
  name: "Dan & Crew",
  status: CommunityStatus.launched,
  launched_at: null,
  type: CommunityType.basic,
  created_at: "2024-07-18T20:57:25.000Z",
  updated_at: "2024-07-18T20:58:13.000Z",
  is_nonadmin_content_allowed: true,
  member_summary: {
    member_count: 2,
    image_urls: [
      "https://images-dev.hallow.app/avatars/30.png",
      "https://d3tv5g0y9izdfo.cloudfront.net/avatars/26.png",
    ],
    is_member: false,
  },
  sharing_defaults: {
    intentions: true,
    prompt_responses: true,
  },
  is_admin: false,
  has_requested_admin: false,
  parent_community_id: null,
  tag_text: null,
  address_label: "",
  simple_address_label: "",
};
