"use client";

import { useTranslations } from "@packages/i18n";
import type { Images } from "@packages/sdk";
import * as stylex from "@stylexjs/stylex";
import type { ComponentProps, ReactNode } from "react";

import {
  numericPercentages,
  numericPixels,
  radius,
  semanticColors,
} from "../../../../../global/stylex/vars.stylex";
import { useIsDesktopViewport } from "../../../../hooks";
import { stylexDynamics } from "../../../../lib";
import { type WithStylexArray } from "../../../../types";
import type { RGBAObject } from "../../../../utils";
import { HallowImage } from "../HallowImage";

// Importing pseudo-classes from another file is currently not supported by stylex
const cssMediaIsDesktopViewport = "@media (min-width: 768px)";

const styles = stylex.create({
  image: {
    backgroundColor: semanticColors.neutralsMedium,
    flexShrink: 0,
    objectFit: "cover",
    objectPosition: "center",
    objectRepeat: "no-repeat",
  },
  imageXSmall: {
    borderRadius: radius.s,
  },
  imageSmall: {
    borderRadius: radius.s,
  },
  imageMedium: {
    borderRadius: radius.s,
  },
  imageContainerMedium: {
    aspectRatio: "13/9",
    height: "auto",
    maxWidth: "100%",
    position: "relative",
    width: {
      [cssMediaIsDesktopViewport]: 210,
      default: 160,
    },
  },
  imageContainerLarge: {
    aspectRatio: "13/9",
    height: {
      [cssMediaIsDesktopViewport]: numericPixels[480],
      default: "auto",
    },
    maxWidth: {
      [cssMediaIsDesktopViewport]: "70vw",
      default: numericPercentages[100],
    },
    minWidth: {
      [cssMediaIsDesktopViewport]: "50vw",
      default: numericPercentages[100],
    },
    position: "relative",
  },
  gradientLarge: {
    height: numericPixels[480],
    marginLeft: "-1px",
    position: "absolute",
    width: 160,
    zIndex: 1,
  },
  imageContainerSmall: {
    position: "relative",
  },
});

export type ThumbnailProps = WithStylexArray<
  {
    gradientColor?: RGBAObject;
    size: "xs" | "s" | "m" | "l";
    imageSrc: Images;
    imageOverlay?: ReactNode;
    title: string;
    alt?: string;
  } & Omit<
    ComponentProps<"div" | "img">,
    "size" | "title" | "src" | "width" | "height"
  >
>;

export const Thumbnail = ({
  styleXArray = [],
  size,
  imageSrc,
  imageOverlay,
  title,
  gradientColor = { r: 0, g: 0, b: 0 },
  alt,
  ...props
}: ThumbnailProps) => {
  const isDesktop = useIsDesktopViewport();
  try {
    const t = useTranslations();
    if (!alt) alt = t("general_img_alt", { "0": title });
  } catch (e) {
    // no i18n setup, ignore
  }

  if (size === "xs") {
    return (
      <div {...stylex.props(styles.imageContainerSmall)}>
        <HallowImage
          src={imageSrc as Images}
          size={size}
          alt={alt}
          width={56}
          height={56}
          {...stylex.props(styles.image, styles.imageXSmall, styleXArray)}
          {...(props as Omit<
            ComponentProps<"img">,
            "width" | "height" | "src"
          >)}
        />
        {imageOverlay ?? null}
      </div>
    );
  } else if (size === "s") {
    return (
      <div {...stylex.props(styles.imageContainerSmall)}>
        <HallowImage
          src={imageSrc as Images}
          size={size}
          alt={alt}
          width={isDesktop ? 80 : 72}
          height={isDesktop ? 80 : 72}
          {...stylex.props(styles.image, styles.imageSmall, styleXArray)}
          {...(props as Omit<
            ComponentProps<"img">,
            "width" | "height" | "src"
          >)}
        />
        {imageOverlay ?? null}
      </div>
    );
  } else if (size === "m") {
    return (
      <div {...stylex.props(styles.imageContainerMedium)} {...props}>
        <HallowImage
          src={imageSrc as Images}
          size={size}
          alt={alt}
          {...stylex.props(styles.image, styles.imageMedium, styleXArray)}
        />
        {imageOverlay ?? null}
      </div>
    );
  } else {
    if (isDesktop) {
      return (
        <div {...stylex.props(styles.imageContainerLarge)} {...props}>
          <div
            {...stylex.props(
              styles.gradientLarge,
              stylexDynamics.backgroundGradient({
                red: gradientColor.r,
                green: gradientColor.g,
                blue: gradientColor.b,
                direction: "to left",
              }),
            )}
          ></div>
          <HallowImage
            src={imageSrc as Images}
            size={size}
            alt={alt}
            {...stylex.props(styles.image, styleXArray)}
          />
          {imageOverlay ?? null}
        </div>
      );
    }
    return (
      <div {...stylex.props(styles.imageContainerLarge)} {...props}>
        <HallowImage
          src={imageSrc as Images}
          size={size}
          alt={alt}
          {...stylex.props(styles.image, styles.imageMedium, styleXArray)}
        />
      </div>
    );
  }
};
