"use client";

import * as stylex from "@stylexjs/stylex";

import { semanticColors } from "../../../../../../global/stylex/vars.stylex";
import type { CarouselApi } from "../../../../../hooks";
import type { IconButtonProps } from "../../../../_base";
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  IconButton,
} from "../../../../_base";

const cssMediaMinWidthTablet = "@media (min-width: 768px)";

const styles = stylex.create({
  button: {
    background: semanticColors.secondary,
    borderColor: semanticColors.neutralsLowest,
    borderStyle: "solid",
    borderWidth: "2px",
    boxShadow: `0 0 16px 0 ${semanticColors.higherContrastLowest}`,
    cursor: "pointer",
    display: "none",
    marginTop: "-20px",
    opacity: 0,
    position: "absolute",
    scale: 0.95,
    top: "50%",
    transition:
      "opacity 200ms ease-in, scale 150ms ease-in, display 200ms allow-discrete",
    zIndex: 10,
    ":hover": {
      scale: 1.05,
    },
  },
  buttonHover: {
    "@starting-style": {
      opacity: 0,
      scale: 0.95,
    },
    display: {
      [cssMediaMinWidthTablet]: "flex",
      default: "none",
    },
    opacity: 1,
    scale: 1,
  },
  next: (props: { pageOffset: CarouselApi["pageOffset"] }) => ({
    right: {
      [cssMediaMinWidthTablet]: `${props.pageOffset[1][1]}px`,
      default: `${props.pageOffset[1][0]}px`,
    },
    transform: "translate(50%, 0)",
  }),
  previous: (props: { pageOffset: CarouselApi["pageOffset"] }) => ({
    left: {
      [cssMediaMinWidthTablet]: `${props.pageOffset[0][1]}px`,
      default: `${props.pageOffset[0][0]}px`,
    },
    transform: "translate(-50%, 0)",
  }),
});

export type CarouselButtonProps = Partial<IconButtonProps> & {
  carouselApi: CarouselApi;
  direction: "next" | "previous";
  visible?: boolean;
};

export const CarouselButton = ({
  carouselApi,
  direction,
  visible = false,
  ...props
}: CarouselButtonProps) => {
  return (
    <IconButton
      icon={
        direction === "next" ? (
          <ChevronRightIcon fill={semanticColors.primary} />
        ) : (
          <ChevronLeftIcon fill={semanticColors.primary} />
        )
      }
      size="m"
      styleXArray={[
        styles.button,
        direction === "next"
          ? styles.next({ pageOffset: carouselApi.pageOffset })
          : styles.previous({ pageOffset: carouselApi.pageOffset }),
        visible ? styles.buttonHover : null,
      ]}
      {...props}
    />
  );
};
