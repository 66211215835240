"use client";

import * as SelectPrimitive from "@radix-ui/react-select";
import stylex from "@stylexjs/stylex";
import * as React from "react";

import {
  colors,
  radius,
  semanticColors,
  spacing,
} from "../../../../../global/stylex/vars.stylex";
import { CheckmarkIcon } from "../../../_base/icons";

const styles = stylex.create({
  item: {
    alignItems: "center",
    backgroundColor: {
      default: colors.transparent,
      ":hover": semanticColors.onOverlayLow,
      ":focus": semanticColors.onOverlayLow,
      ":active": semanticColors.onOverlayLow,
    },
    borderRadius: radius.m,
    color: {
      default: semanticColors.primary,
      ":hover": semanticColors.primary,
      ":focus": semanticColors.primary,
      ":active": semanticColors.neutralsMedHigh,
    },
    columnGap: spacing.m,
    cursor: "pointer",
    display: "flex",
    outline: "none",
    padding: spacing.m,
  },
  checkIcon: {
    lineHeight: 0,
    marginLeft: "auto",
  },
});

type SelectItemProps = React.ComponentPropsWithoutRef<
  typeof SelectPrimitive.Item
>;

export const SelectItem = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Item>,
  SelectItemProps
>(({ children, ...props }, ref) => (
  <SelectPrimitive.Item ref={ref} {...stylex.props(styles.item)} {...props}>
    <SelectPrimitive.ItemText>{children}</SelectPrimitive.ItemText>
    <span {...stylex.props(styles.checkIcon)}>
      <SelectPrimitive.ItemIndicator>
        <CheckmarkIcon.SimpleOn />
      </SelectPrimitive.ItemIndicator>
    </span>
  </SelectPrimitive.Item>
));

SelectItem.displayName = "SelectItem";
