"use client";

import { HallowAnalyticsEvent, useAnalytics } from "@packages/analytics";
import { useTranslations } from "@packages/i18n";
import {
  getContentId,
  getContentImage,
  getContentTitle,
  useMasterQueue,
} from "@packages/media";
import type { QueueManagedItem } from "@packages/sdk";
import * as stylex from "@stylexjs/stylex";
import type { JSX } from "react";
import { useRef, useState } from "react";
import type { DropEvent } from "react-aria";

import { themes } from "../../../../../global/stylex/themes.stylex";
import {
  numericPercentages,
  numericPixels,
  numericValues,
  radius,
  semanticColors,
  shades,
  shadesRaw,
  spacing,
  stroke,
  zIndices,
} from "../../../../../global/stylex/vars.stylex";
import {
  FullscreenModal,
  IconButton,
  Loader,
  QueueIcon,
} from "../../../../components";
import { stylexDynamics } from "../../../../lib";
import type { WithButtonWrapper } from "../../../../types";
import { colorMix } from "../../../../utils";
import { QueueControls, QueueItem } from "../../blocks";
import { UIQueue } from "../UIQueue";

const placeholderStyles = stylex.create({
  default: {
    borderRadius: radius.full,
    height: numericValues[0],
    outlineColor: semanticColors.onColorHighest,
    outlineStyle: "solid",
    outlineWidth: stroke.regular,
    position: "relative",
    width: numericPercentages[100],
  },
  title: {
    background: semanticColors.onColorHighest,
    color: semanticColors.onColorLowest,
    left: numericPercentages[50],
    padding: spacing.t,
    position: "absolute",
    top: numericPixels[2],
    transform: "translateX(-50%)",
  },
});

const modalStyles = stylex.create({
  close: {
    marginLeft: "revert",
    marginRight: "auto",
  },
  fonts: {
    color: semanticColors.primary,
  },
  placement: {
    margin: "auto",
  },
  zAxis: {
    zIndex: zIndices.queueContent,
  },
});

const overlayStyles = stylex.create({
  default: {
    background: shades.shade60,
    display: "none",
    inset: 0,
    placeContent: "center",
    placeItems: "center",
    position: "fixed",
    zIndex: zIndices.loading,
  },
  active: {
    display: "flex",
  },
});

export type QueueProps = WithButtonWrapper;

export const Queue = ({
  Wrapper = ({ children }) => <>{children}</>,
}: QueueProps) => {
  const t = useTranslations();
  const queueRef = useRef<HTMLDivElement>(null);
  const {
    itemRefs,
    dropProps,
    queue,
    onDragMove,
    dragIndex,
    onDragEnd,
    onDragStart,
    draggedTitle,
  } = useMasterQueue({ queueRef });
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const analytics = useAnalytics();

  const colorHex = getContentImage(queue.currentItem)?.color_hex ?? "#000000";

  const Placeholder = ({ title }: { title: string }) => (
    <div {...stylex.props(placeholderStyles.default)}>
      <div {...stylex.props(placeholderStyles.title)}>{title}</div>
    </div>
  );

  const handleDragStart = (title: string) => {
    setIsDragging(true);
    onDragStart(title);
  };

  const handleDragEnd = () => {
    setIsDragging(false);
    onDragEnd();
  };

  const past = queue.pastQueue.map((qi) => (
    <QueueItem key={qi.uuid} item={qi} variant={"past"} />
  ));

  const upcoming: JSX.Element[] = [];
  let addedPlaceholder = false;

  for (let i = 0; i < queue.upcomingQueue.length; i++) {
    if (dragIndex === i && draggedTitle) {
      upcoming.push(
        <Placeholder
          key={`dragPlaceholder_${draggedTitle}`}
          title={draggedTitle}
        />,
      );
      addedPlaceholder = true;
    }

    const qi = queue.upcomingQueue[i];
    upcoming.push(
      <QueueItem
        key={qi.uuid}
        ref={itemRefs.current[qi.uuid]}
        item={qi}
        variant={"future"}
        onDragStart={() => {
          analytics.track({ event: HallowAnalyticsEvent.DraggedQueueItem });
          return handleDragStart(getContentTitle(qi));
        }}
        onDragEnd={handleDragEnd}
        onDragMove={onDragMove}
      />,
    );
  }

  if (!addedPlaceholder && dragIndex !== null && draggedTitle !== null) {
    upcoming.push(
      <Placeholder
        key={`dragPlaceholder_${draggedTitle}`}
        title={draggedTitle}
      />,
    );
  }

  const current = queue.currentItem ? (
    <QueueItem
      key={queue.currentItem?.uuid ?? "currentItem"}
      item={queue.currentItem}
      variant={"current"}
    />
  ) : null;

  if (queue.repeating === "all") {
    upcoming.push(
      ...queue.pastQueue.map((qi) => {
        return (
          <QueueItem variant={"repeat"} item={qi} key={`repeat_${qi.uuid}`} />
        );
      }),
    );
    if (queue.currentItem)
      upcoming.push(
        <QueueItem
          variant={"repeat"}
          item={queue.currentItem}
          key={`repeat_${queue.currentItem.uuid}`}
        />,
      );
    upcoming.push(
      ...queue.upcomingQueue.map((qi) => {
        return (
          <QueueItem variant={"repeat"} item={qi} key={`repeat_${qi.uuid}`} />
        );
      }),
    );
  }

  const handleDelete = async (e: DropEvent) => {
    const itm = e.items[0];
    if (itm.kind === "text") {
      const txt = await itm.getText("text/json");
      const obj = JSON.parse(txt) as QueueManagedItem;
      queue.delete(obj.uuid);
    }
  };

  return (
    <FullscreenModal
      trigger={
        <Wrapper asChild>
          <IconButton
            icon={<QueueIcon fill={semanticColors.onColorVeryHigh} />}
            variant={"transparentWhite"}
            onClickCapture={() =>
              analytics.track({
                event: HallowAnalyticsEvent.TappedQueue,
                properties: {
                  enabled: true,
                  content_id: getContentId(queue.currentItem),
                  content_type: queue.currentItem?.type,
                },
              })
            }
            tabIndex={0}
          />
        </Wrapper>
      }
      contentStyleXArray={[
        themes.dark,
        modalStyles.fonts,
        modalStyles.zAxis,
        stylexDynamics.colorMixBackgroundColor({
          source: colorHex,
          adjustment: shadesRaw.shade30,
        }),
      ]}
      closeStyleXArray={[modalStyles.close]}
    >
      <div
        {...stylex.props(
          overlayStyles.default,
          queue.loading ? overlayStyles.active : null,
        )}
      >
        <Loader />
      </div>
      <UIQueue
        ref={queueRef}
        past={{ title: t("recently_played"), items: past }}
        future={{ title: t("playing_next"), items: upcoming }}
        current={current}
        isDragging={isDragging}
        onDelete={handleDelete}
        styleXArray={[modalStyles.placement]}
        titleBgStyleXArray={[
          stylexDynamics.colorMixBackgroundColor({
            source: colorHex,
            adjustment: shadesRaw.shade30,
          }),
        ]}
        masksColor={
          colorMix<"string">({
            source: colorHex,
            adjustment: shadesRaw.shade30,
            format: "string",
          }) as `rgba(${number},${number},${number},${number})`
        }
        {...dropProps}
      >
        <QueueControls Wrapper={Wrapper} />
      </UIQueue>
    </FullscreenModal>
  );
};
