"use client";

import { useModal } from "@ebay/nice-modal-react";
import { HallowAnalyticsEvent, useAnalytics } from "@packages/analytics";
import { useTranslations } from "@packages/i18n";
import {
  getContentAlbumImage,
  getContentDescription,
  getContentMetadata,
  getContentTitle,
  getShareProperties,
} from "@packages/media";
import type { QueueManagedItem } from "@packages/sdk";
import {
  redirectToAuth,
  useRequestFavorite,
  useRequestPrayer,
  useSession,
} from "@packages/sdk";
import * as stylex from "@stylexjs/stylex";

import { themes } from "../../../../../../global/stylex/themes.stylex";
import {
  colors,
  spacing,
  zIndices,
} from "../../../../../../global/stylex/vars.stylex";
import { ThemeContainer } from "../../../../../theme";
import type { WithStylexArray } from "../../../../../types";
import { Theme } from "../../../../../types";
import { IconButton } from "../../../_core";
import { MoreIcon, ShareIcon, StarIcon } from "../../../icons";
import { DropdownMenu, DropdownMenuItem, ShareModal } from "../../../modals";
import ContentMoreMenuHeader from "./ContentMoreMenuHeader";

const styles = stylex.create({
  dropdownMenuContent: { paddingTop: spacing.l },
  modalZ: {
    zIndex: zIndices.modalOverQueue,
  },
  noBackground: {
    background: colors.transparent,
  },
  modalPadding: {
    paddingTop: spacing.l,
  },
});

export type MediaMoreMenuProps = WithStylexArray<{
  content: QueueManagedItem;
}>;

export const MediaMoreMenu = ({ content, styleXArray }: MediaMoreMenuProps) => {
  const { mutationPost: updateFavorite } = useRequestFavorite({
    id: content?.type === "prayer" ? content.prayer.id : null,
  });
  const { query: prayerQuery } = useRequestPrayer({
    id: content?.type === "prayer" ? content.prayer.id : null,
  });
  const { status } = useSession();
  const shareModal = useModal(ShareModal);
  const analytics = useAnalytics();
  const t = useTranslations();

  const onClickFavorite = () => {
    if (status === "authenticated") updateFavorite.mutate();
    else redirectToAuth();
  };

  return (
    <ThemeContainer
      theme={Theme.DARK}
      as="div"
      onClick={(e) => e.stopPropagation()}
      styleXArray={[styles.noBackground]}
    >
      <DropdownMenu
        trigger={
          <IconButton
            icon={<MoreIcon />}
            onClick={(e) => {
              // The stopImmediatePropagation call prevents next top loader from firing unnecessarily
              e.nativeEvent.stopImmediatePropagation();
              e.preventDefault();
            }}
            variant="transparentPrimary"
          />
        }
        align="end"
        contentStyleXArray={[
          styles.dropdownMenuContent,
          themes.dark,
          styles.modalZ,
          styleXArray,
        ]}
        modal={false}
      >
        <ContentMoreMenuHeader
          title={getContentTitle(content)}
          subtitle={getContentDescription(content)}
          metadata={getContentMetadata(content)}
          imageSrc={getContentAlbumImage(content)}
        />
        {content?.type !== "radio_song" ? (
          <DropdownMenuItem
            startIcon={
              prayerQuery.data?.is_favorite ? (
                <StarIcon.Off />
              ) : (
                <StarIcon.Outline />
              )
            }
            onClick={onClickFavorite}
          >
            {prayerQuery.data?.is_favorite
              ? t("dialog_prayer_options_unfavorite")
              : t("dialog_prayer_options_favorite")}
          </DropdownMenuItem>
        ) : null}
        <DropdownMenuItem
          startIcon={<ShareIcon />}
          onClick={() => {
            const event =
              content?.type === "prayer"
                ? "Tapped Share Prayer"
                : content?.type === "radio_song"
                  ? "Tapped Share Radio Station"
                  : null;
            if (event) {
              const props: Record<string, number> = {};
              if (content.type === "prayer") props.prayer = content.prayer.id;
              else if (content.type === "radio_song")
                props.radio_station = content.radio_station.id;
              analytics.track({
                event,
                ...props,
              });
            }
            return shareModal.show({
              ...getShareProperties(content, [
                themes.dark,
                styles.modalZ,
                styles.modalPadding,
              ]),
              overlayProps: {
                onPointerDown: () => {
                  analytics.track({
                    event: HallowAnalyticsEvent.ClosedShareAudioView,
                  });
                },
              },
            });
          }}
        >
          {t("dialog_prayer_options_share")}
        </DropdownMenuItem>
      </DropdownMenu>
    </ThemeContainer>
  );
};
