"use client";

import { Range, Root, Track } from "@radix-ui/react-slider";
import * as stylex from "@stylexjs/stylex";
import type { StyleXVar } from "@stylexjs/stylex/lib/StyleXTypes";
import { type ComponentProps } from "react";

import {
  colors,
  numericPercentages,
  numericPixels,
  radius,
  semanticColors,
  spacing,
} from "../../../../../global/stylex/vars.stylex";
import type { StyleXArray, WithStylexArray } from "../../../../types";

export type SliderProps = WithStylexArray<
  Omit<ComponentProps<typeof Root>, "value" | "defaultValue"> & {
    value?: number;
    defaultValue?: number;
    size?: "small" | "large";
    onColor?: boolean;
    color?: StyleXVar<string>;
    trackStyleXArray?: StyleXArray;
    rangeStyleXArray?: StyleXArray;
  }
>;

const styles = stylex.create({
  range: {
    backgroundColor: colors.white,
    borderRadius: radius.full,
    display: "block",
    height: numericPercentages[100],
    minWidth: spacing.m,
    position: "absolute",
  },
  rangeOnColor: {
    backgroundColor: semanticColors.onColorVeryHigh,
  },
  root: {
    display: "block",
    height: spacing.s,
    width: numericPercentages[100],
  },
  smallRange: {
    minWidth: spacing.s,
  },
  smallRoot: {
    width: numericPixels[240],
  },
  track: {
    backgroundColor: semanticColors.onColorHigh,
    borderRadius: radius.full,
    cursor: "pointer",
    display: "block",
    height: numericPercentages[100],
    position: "relative",
    width: numericPercentages[100],
  },
  disabledTrack: {
    cursor: "default",
  },
  dynamicColor: (color: string) => ({
    backgroundColor: color,
  }),
});

export const Slider = ({
  size = "large",
  value = 0,
  step = 1,
  defaultValue = 0,
  styleXArray = [],
  orientation = "horizontal",
  onColor,
  color,
  disabled,
  rangeStyleXArray = [],
  trackStyleXArray = [],
  ...props
}: SliderProps) => {
  return (
    <Root
      value={[isNaN(value) ? 0 : value]}
      defaultValue={[defaultValue]}
      step={step}
      orientation={orientation}
      disabled={disabled}
      {...props}
      {...stylex.props(
        styles.root,
        size === "small" ? styles.smallRoot : null,
        styleXArray,
      )}
    >
      <Track
        {...stylex.props(
          styles.track,
          disabled ? styles.disabledTrack : null,
          trackStyleXArray,
        )}
      >
        <Range
          {...stylex.props(
            styles.range,
            size === "small" ? styles.smallRange : null,
            onColor ? styles.rangeOnColor : null,
            color ? styles.dynamicColor(color) : null,
            rangeStyleXArray,
          )}
        />
      </Track>
    </Root>
  );
};
