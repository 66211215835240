"use client";

import stylex from "@stylexjs/stylex";
import { forwardRef } from "react";

import {
  semanticColors,
  spacing,
} from "../../../../../global/stylex/vars.stylex";
import { Text } from "../../../_base/_core/Text";
import { useFormField } from "./FormField";

const styles = stylex.create({
  message: {
    color: semanticColors.errorError,
    marginHorizontal: spacing.xs,
  },
});

export const FormMessage = forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLParagraphElement>
>(({ className, children, ...props }, ref) => {
  const { error, formMessageId } = useFormField();
  const body = error ? String(error?.message) : children;

  if (!body) {
    return null;
  }

  return (
    <Text
      ref={ref}
      type="detail"
      size="l"
      id={formMessageId}
      styleXArray={[styles.message]}
      {...props}
    >
      {body}
    </Text>
  );
});

FormMessage.displayName = "FormMessage";
