import { Slot, Slottable } from "@radix-ui/react-slot";
import type { SVGProps } from "react";
import React, { cloneElement } from "react";

export type IconWrapperProps = SVGProps<SVGSVGElement> & {
  children: React.ReactElement | React.ReactElement[];
  label?: string;
  size?: number;
};

export const IconWrapper = ({
  children,
  fill = "currentColor",
  size = 24,
  style = {},
  ...props
}: IconWrapperProps): JSX.Element => {
  const Comp = Slot as unknown as React.FC<React.SVGProps<SVGSVGElement>>;
  return (
    <Comp
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      style={{ ...style, minWidth: size }}
    >
      <Slottable>{children}</Slottable>
    </Comp>
  );
};

export const InlineIcon = ({ icon }: { icon: IconElement }) => {
  return cloneElement(icon, { size: 12, viewBox: "0 0 24 24" });
};

export type BasicIconProps = Omit<IconWrapperProps, "children">;

export type IconElement = React.ReactElement<IconWrapperProps>;
