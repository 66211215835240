"use client";

import * as SelectPrimitive from "@radix-ui/react-select";
import stylex from "@stylexjs/stylex";
import * as React from "react";

import {
  colors,
  fontSizes,
  lineHeights,
  numericPixels,
  radius,
  semanticColors,
  spacing,
  stroke,
} from "../../../../../global/stylex/vars.stylex";
import type { WithStylexArray } from "../../../../types";
import { ChevronDownIcon } from "../../../_base/icons";

const styles = stylex.create({
  trigger: {
    alignItems: "center",
    backgroundColor: colors.transparent,
    borderColor: semanticColors.neutralsLowest,
    borderRadius: radius.ms,
    borderStyle: "solid",
    borderWidth: stroke.regular,
    color: {
      default: semanticColors.primary,
      ":is([data-placeholder])": semanticColors.neutralsHigh,
    },
    display: "flex",
    fontSize: fontSizes.bodyM,
    gap: spacing.xs,
    height: numericPixels[40],
    justifyContent: "space-between",
    lineHeight: lineHeights.bodyM,
    minHeight: numericPixels[64],
    outline: "none",
    padding: `${spacing.ms} ${spacing.m}`,
    transition: "border 200ms ease-in",
    width: spacing.full,
    ":hover:not(:disabled):not(:focus)": {
      borderColor: semanticColors.neutralsVeryLow,
    },
    ":disabled": {
      color: semanticColors.neutralsMedHigh,
      cursor: "not-allowed",
    },
    ":focus": {
      borderColor: semanticColors.primary,
      outline: "none",
    },
  },
});

type SelectTriggerProps = WithStylexArray<
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Trigger>
>;

export const SelectTrigger = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Trigger>,
  SelectTriggerProps
>(({ children, styleXArray, ...props }, ref) => (
  <SelectPrimitive.Trigger
    ref={ref}
    {...stylex.props(styles.trigger, styleXArray)}
    {...props}
  >
    {children}
    <SelectPrimitive.Icon asChild>
      <ChevronDownIcon />
    </SelectPrimitive.Icon>
  </SelectPrimitive.Trigger>
));

SelectTrigger.displayName = "SelectTrigger";
