import type { HallowElementProps } from "../../../../types";

export const PurplePresentIcon = (props: HallowElementProps<"svg">) => (
  <svg
    {...props}
    width="100%"
    viewBox="0 0 196 222"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M95.9351 68.3755L73.3662 50.1904L10.7173 66.9415L31.8227 88.7168L95.9351 68.3755Z"
      fill="#7636AC"
    />
    <path
      d="M95.9352 68.3755L163.039 88.7168L95.9352 116.713V68.3755Z"
      fill="#380268"
    />
    <path
      d="M119.216 50.1904L184.138 66.9415L163.039 88.7168L95.9352 68.3755L119.216 50.1904Z"
      fill="#9857CF"
    />
    <path
      d="M95.9352 68.3755V116.713L31.8228 88.7168L95.9352 68.3755Z"
      fill="#5A2884"
    />
    <path
      opacity="0.5"
      d="M95.9352 116.713V68.3755L71.8011 106.173L95.9352 116.713Z"
      fill="#162547"
    />
    <path
      d="M119.947 71.6375C116.359 72.0613 112.86 70.1051 111.346 66.7827L108.236 67.7523L107.606 65.725L110.715 64.7554C110.079 61.1583 111.854 57.5647 115.048 55.8764C115.861 55.4492 116.231 54.4864 115.96 53.6083L105.861 21.0894C105.542 20.0655 104.455 19.4926 103.432 19.8113L84.9282 25.5679C84.0644 25.8357 83.4614 26.6731 83.5698 27.5715C84.0542 31.5211 81.6625 35.3757 77.7294 36.5996C73.7962 37.8235 69.6463 36.0063 67.8034 32.4771C67.3833 31.6736 66.4144 31.3278 65.5505 31.5957L47.0469 37.3522C46.0238 37.6709 45.4513 38.7592 45.7697 39.783L55.8719 72.2986C56.1429 73.1767 56.9966 73.7564 57.9079 73.6513C61.4954 73.2275 64.995 75.1837 66.5093 78.5061L69.4633 77.5873L70.0935 79.6147L67.1394 80.5334C67.7763 84.1304 66.0011 87.7275 62.8065 89.4124C61.9934 89.8396 61.6242 90.8024 61.8952 91.6805L84.8774 165.665C85.1959 166.689 86.2833 167.262 87.3064 166.943L143.688 149.402C144.711 149.084 145.284 147.995 144.965 146.971L121.983 72.9868C121.712 72.1087 120.858 71.529 119.947 71.6341V71.6375ZM75.5443 77.9196L74.9142 75.8922L80.3616 74.1971L80.9917 76.2245L75.5443 77.9196ZM86.4426 74.5294L85.8124 72.502L91.2599 70.8069L91.89 72.8342L86.4426 74.5294ZM97.3408 71.1391L96.7107 69.1118L102.158 67.4167L102.788 69.444L97.3408 71.1391Z"
      fill="#FFDF39"
    />
    <path
      d="M75.5359 97.77C73.9844 92.7728 76.2711 87.5993 81.417 85.9991C86.5629 84.3989 91.2752 87.3925 92.8268 92.3897C94.4258 97.5394 92.1933 102.526 87.0474 104.13C81.9015 105.73 77.1316 102.923 75.5326 97.77H75.5359ZM87.5894 122.037L104.26 78.4966L109.975 80.7273L93.3045 124.268L87.5894 122.037ZM87.0643 94.1831C86.5189 92.4304 84.9064 91.2404 83.1583 91.7828C81.4102 92.3253 80.7564 94.2238 81.2984 95.9765C81.8439 97.7293 83.4564 98.9192 85.2045 98.3768C86.9525 97.8344 87.6064 95.9359 87.0643 94.1831ZM104.738 110.375C103.186 105.378 105.473 100.204 110.619 98.604C115.765 97.0038 120.477 99.9973 122.029 104.995C123.628 110.144 121.395 115.131 116.249 116.735C111.104 118.335 106.334 115.528 104.735 110.375H104.738ZM116.266 106.788C115.721 105.035 114.108 103.845 112.36 104.388C110.612 104.93 110.06 106.798 110.606 108.547C111.151 110.3 112.658 111.524 114.41 110.978C116.161 110.432 116.812 108.537 116.27 106.785L116.266 106.788Z"
      fill="#D9BA16"
    />
    <path
      d="M69.3854 66.6673L67.5764 67.2165L62.2272 48.4008L64.0362 47.8516L69.3854 66.6673Z"
      fill="#D9BA16"
    />
    <path
      d="M76.1371 64.616L72.143 65.8297L66.7938 47.014L70.7879 45.8003L76.1371 64.616Z"
      fill="#D9BA16"
    />
    <path
      d="M80.8901 63.1717L78.8913 63.7785L73.5421 44.9628L75.5409 44.356L80.8901 63.1717Z"
      fill="#D9BA16"
    />
    <path
      d="M83.878 62.2635L81.8826 62.8703L76.5334 44.0546L78.5288 43.4478L83.878 62.2635Z"
      fill="#D9BA16"
    />
    <path
      d="M88.3023 60.9173L85.2432 61.8462L79.894 43.0339L82.9531 42.1016L88.3023 60.9173Z"
      fill="#D9BA16"
    />
    <path
      d="M90.1318 60.3611L89.5152 60.551L84.166 41.7353L84.7826 41.5488L90.1318 60.3611Z"
      fill="#D9BA16"
    />
    <path
      d="M93.4856 59.3411L91.3446 59.9954L85.9954 41.1797L88.1398 40.5288L93.4856 59.3411Z"
      fill="#D9BA16"
    />
    <path
      d="M98.7975 57.7273L95.7384 58.6597L90.3892 39.844L93.4483 38.915L98.7975 57.7273Z"
      fill="#D9BA16"
    />
    <path
      d="M100.627 57.1712L100.01 57.361L94.6611 38.5453L95.2777 38.3589L100.627 57.1712Z"
      fill="#D9BA16"
    />
    <path
      d="M103.981 56.1541L101.84 56.805L96.4905 37.9893L98.6349 37.3384L103.981 56.1541Z"
      fill="#D9BA16"
    />
    <path
      d="M31.8228 88.7168L95.9352 116.713V194L34.0451 156.67L31.8228 88.7168Z"
      fill="#5A2884"
    />
    <path
      d="M57.5491 99.9487V170.855L66.994 176.554V104.075L57.5491 99.9487Z"
      fill="#ED759A"
    />
    <path
      opacity="0.5"
      d="M32.2056 100.366L32.5579 111.157L70.8492 129.315L95.9352 116.713L32.2056 100.366Z"
      fill="#380268"
    />
    <path
      d="M95.9352 116.713V194L160.254 156.67L163.039 88.7168L95.9352 116.713Z"
      fill="#9857CF"
    />
    <path
      d="M127.19 103.776V175.968L136.367 170.642V99.9487L127.19 103.776Z"
      fill="#FFACD0"
    />
    <path
      d="M195.5 85.8452L131.506 114.079L95.9352 116.713L163.039 88.7167L195.5 85.8452Z"
      fill="#C49EE7"
    />
    <path
      d="M147.105 26.5767C146.004 32.5321 145.11 33.4215 139.123 34.518C145.11 35.6136 146.004 36.5034 147.105 42.4586C148.208 36.5031 149.102 35.614 155.088 34.5184C149.101 33.4215 148.208 32.5327 147.105 26.5767Z"
      fill="#E6E6E7"
    />
    <path
      d="M31.8222 29.6479C30.6872 35.7857 29.7654 36.7023 23.5958 37.8324C29.7656 38.9616 30.6871 39.8786 31.8222 46.016C32.9582 39.8783 33.8797 38.9619 40.0495 37.8328C33.879 36.7023 32.9582 35.7863 31.8222 29.6479Z"
      fill="#E6E6E7"
    />
    <path
      d="M135.084 60.9312C133.934 67.1428 133.002 68.0704 126.758 69.2137C133.002 70.3569 133.934 71.2844 135.084 77.4963C136.233 71.2841 137.165 70.357 143.409 69.2137C137.165 68.0703 136.233 67.143 135.084 60.9312Z"
      fill="#F6F5F8"
    />
    <path
      d="M125.857 35.6553C125.356 38.3637 124.949 38.7681 122.226 39.2666C124.949 39.7651 125.356 40.1695 125.857 42.878C126.358 40.1693 126.764 39.7651 129.487 39.2666C126.764 38.7681 126.358 38.3638 125.857 35.6553Z"
      fill="#E6E6E7"
    />
    <path
      d="M56.1762 40.8403C55.5955 43.9783 55.1245 44.4468 51.9702 45.0244C55.1248 45.6019 55.5956 46.0705 56.1762 49.2085C56.7569 46.0703 57.2277 45.602 60.3821 45.0244C57.2278 44.4468 56.757 43.9784 56.1762 40.8403Z"
      fill="#F6F5F8"
    />
    <path
      d="M0.5 85.8452L61.8142 114.079L95.9352 116.713L31.8228 88.7167L0.5 85.8452Z"
      fill="#9869BF"
    />
    <path
      d="M64.0318 0.34082C62.4533 8.87206 61.1728 10.146 52.5969 11.7163C61.1735 13.2863 62.4534 14.5602 64.0318 23.0917C65.6107 14.5598 66.8907 13.2866 75.4668 11.7163C66.8909 10.1459 65.6111 8.87235 64.0318 0.34082Z"
      fill="#E6E6E7"
    />
  </svg>
);

PurplePresentIcon.displayName = "PurplePresentIcon";
