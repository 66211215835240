"use client";

import { usePathname, useRouter, useSearchParams } from "next/navigation";
import type { Dispatch, SetStateAction } from "react";
import { useEffect, useState } from "react";

export type UseSearchParamStateProps<
  OptionsMultipleType extends boolean = false,
  ValueType extends string = string,
> = {
  defaultValue?: OptionsMultipleType extends true ? ValueType[] : ValueType;
  key: string;
  multiple?: OptionsMultipleType;
  removeFromUrlIfDefaultValue?: boolean;
};

export const useSearchParamState = <
  OptionsMultipleType extends boolean = false,
  ValueType extends string = string,
>({
  defaultValue,
  key,
  multiple,
  removeFromUrlIfDefaultValue,
}: UseSearchParamStateProps<OptionsMultipleType>) => {
  const pathname = usePathname();
  const router = useRouter();
  const searchParams = useSearchParams();

  const [state, setState] = useState<
    UseSearchParamStateProps<OptionsMultipleType>["defaultValue"]
  >(
    (multiple
      ? searchParams.getAll(key)?.length > 0
        ? searchParams.getAll(key)
        : (defaultValue ?? [])
      : searchParams.get(key)?.length > 0
        ? searchParams.get(key)
        : (defaultValue ??
          "")) as UseSearchParamStateProps<OptionsMultipleType>["defaultValue"],
  );

  useEffect(() => {
    const newSearchParams = new URLSearchParams(searchParams.toString());

    newSearchParams.delete(key);

    if (
      !(
        removeFromUrlIfDefaultValue &&
        JSON.stringify(defaultValue) === JSON.stringify(state)
      )
    ) {
      if (multiple && state.length !== 0)
        (state as string[]).forEach((stateValue) => {
          if (stateValue.length > 0) newSearchParams.append(key, stateValue);
        });
      else if (state.length > 0) newSearchParams.set(key, state as string);
      else newSearchParams.delete(key);
    }

    newSearchParams.sort();

    router.push(
      `${pathname}${newSearchParams.toString() ? "?" + newSearchParams.toString() : ""}`,
    );
  }, [state]);

  return [state, setState] as [
    UseSearchParamStateProps<OptionsMultipleType, ValueType>["defaultValue"],
    Dispatch<
      SetStateAction<
        UseSearchParamStateProps<OptionsMultipleType, ValueType>["defaultValue"]
      >
    >,
  ];
};
