import * as stylex from "@stylexjs/stylex";
import { forwardRef } from "react";

import { spacing } from "../../../../../../global/stylex/vars.stylex";
import { CAROUSEL_GRID_SIZES } from "../../../../../constants";
import type { CarouselApi } from "../../../../../hooks";
import type {
  CarouselGridSize,
  HallowElement,
  HallowElementProps,
  WithStylexArray,
} from "../../../../../types";

/**
 * Constants
 * styleX requires these to be statically defined in this file
 */
const cssMediaMinWidth2 = "@media (min-width: 768px)";
const confinedCssMediaMinWidth1 =
  "@media (min-width: 640px) and (max-width: 767px)";
const confinedCssMediaMinWidth2 =
  "@media (min-width: 768px) and (max-width: 1023px)";
const confinedCssMediaMinWidth3 =
  "@media (min-width: 1024px) and (max-width: 1263px)";
const confinedCssMediaMinWidth4 =
  "@media (min-width: 1264px) and (max-width: 1535px)";
const confinedCssMediaMinWidth5 = "@media (min-width: 1536px)";

const hallowElement: HallowElement = "div";

const getPositionStyles = ({
  gap,
  isEnd,
  isStart,
}: {
  gap: CarouselApi["gap"];
  isEnd: boolean;
  isStart: boolean;
}) => {
  if (isEnd && isStart) return null;
  if (isEnd) return stylesPosition.end({ gap });
  if (isStart) return stylesPosition.start({ gap });
  return stylesPosition.base({ gap });
};

const styles = stylex.create({
  determinedElement: {
    flexShrink: 0,
    height: spacing.full,
    minWidth: spacing.none,
    scrollSnapAlign: "start",
  },
});

const stylesPosition = stylex.create({
  base: (props: { gap: CarouselApi["gap"] }) => ({
    marginLeft: {
      [cssMediaMinWidth2]: `${props.gap[1]}px`,
      default: `${props.gap[0]}px`,
    },
    marginRight: {
      [cssMediaMinWidth2]: `${props.gap[1]}px`,
      default: `${props.gap[0]}px`,
    },
  }),
  end: (props: { gap: CarouselApi["gap"] }) => ({
    marginLeft: {
      [cssMediaMinWidth2]: `${props.gap[1]}px`,
      default: `${props.gap[0]}px`,
    },
  }),
  start: (props: { gap: CarouselApi["gap"] }) => ({
    marginRight: {
      [cssMediaMinWidth2]: `${props.gap[1]}px`,
      default: `${props.gap[0]}px`,
    },
  }),
});

/* ((100% - gap * (#columns -1)) / #columns) */
const stylesSize = stylex.create({
  width: (props: { gap: CarouselApi["gap"]; size: CarouselGridSize }) => ({
    width: {
      [confinedCssMediaMinWidth1]:
        props.size[1] === 1
          ? spacing.full
          : `calc(calc(100% - calc(${props.gap[0]}px * ${props.size[1] - 1})) / ${props.size[1]})`,
      [confinedCssMediaMinWidth2]:
        props.size[2] === 1
          ? spacing.full
          : `calc(calc(100% - calc(${props.gap[1]}px * ${props.size[2] - 1})) / ${props.size[2]})`,
      [confinedCssMediaMinWidth3]:
        props.size[3] === 1
          ? spacing.full
          : `calc(calc(100% - calc(${props.gap[1]}px * ${props.size[3] - 1})) / ${props.size[3]})`,
      [confinedCssMediaMinWidth4]:
        props.size[4] === 1
          ? spacing.full
          : `calc(calc(100% - calc(${props.gap[1]}px * ${props.size[4] - 1})) / ${props.size[4]})`,
      [confinedCssMediaMinWidth5]:
        props.size[5] === 1
          ? spacing.full
          : `calc(calc(100% - calc(${props.gap[1]}px * ${props.size[5] - 1})) / ${props.size[5]})`,
      default:
        props.size[0] === 1
          ? spacing.full
          : `calc(calc(100% - calc(${props.gap[0]}px * ${props.size[0] - 1})) / ${props.size[0]})`,
    },
  }),
});

/* (100% - (offset1 + offset2 + ((2 * (#columns - 1)) * gap))) / #columns) */
const stylesSizePageOffet = stylex.create({
  width: (props: {
    gap: CarouselApi["gap"];
    pageOffset: CarouselApi["pageOffset"];
    size: CarouselGridSize;
  }) => ({
    width: {
      [confinedCssMediaMinWidth1]:
        props.size[1] === 1
          ? `calc(100% - calc(2 * ${(props.pageOffset[0][0] + props.pageOffset[1][0]) / 2}px))`
          : `calc(calc(100% - ${props.pageOffset[0][0] + props.pageOffset[1][0] + 2 * (props.size[1] - 1) * props.gap[0]}px) / ${props.size[1]})`,
      [confinedCssMediaMinWidth2]:
        props.size[2] === 1
          ? `calc(100% - calc(2 * ${(props.pageOffset[0][1] + props.pageOffset[1][1]) / 2}px))`
          : `calc(calc(100% - ${props.pageOffset[0][1] + props.pageOffset[1][1] + 2 * (props.size[2] - 1) * props.gap[1]}px) / ${props.size[2]})`,
      [confinedCssMediaMinWidth3]:
        props.size[3] === 1
          ? `calc(100% - calc(2 * ${(props.pageOffset[0][1] + props.pageOffset[1][1]) / 2}px))`
          : `calc(calc(100% - ${props.pageOffset[0][1] + props.pageOffset[1][1] + 2 * (props.size[3] - 1) * props.gap[1]}px) / ${props.size[3]})`,
      [confinedCssMediaMinWidth4]:
        props.size[4] === 1
          ? `calc(100% - calc(2 * ${(props.pageOffset[0][1] + props.pageOffset[1][1]) / 2}px))`
          : `calc(calc(100% - ${props.pageOffset[0][1] + props.pageOffset[1][1] + 2 * (props.size[4] - 1) * props.gap[1]}px) / ${props.size[4]})`,
      [confinedCssMediaMinWidth5]:
        props.size[5] === 1
          ? `calc(100% - calc(2 * ${(props.pageOffset[0][1] + props.pageOffset[1][1]) / 2}px))`
          : `calc(calc(100% - ${props.pageOffset[0][1] + props.pageOffset[1][1] + 2 * (props.size[5] - 1) * props.gap[1]}px) / ${props.size[5]})`,
      default:
        props.size[0] === 1
          ? `calc(100% - calc(2 * ${(props.pageOffset[0][0] + props.pageOffset[1][0]) / 2}px))`
          : `calc(calc(100% - ${props.pageOffset[0][0] + props.pageOffset[1][0] + 2 * (props.size[0] - 1) * props.gap[0]}px) / ${props.size[0]})`,
    },
  }),
});

export type CarouselItemProps = WithStylexArray<
  HallowElementProps<typeof hallowElement>
> & {
  carouselApi: CarouselApi;
  isEnd?: boolean;
  isStart?: boolean;
};

export const CarouselItem = forwardRef<any, CarouselItemProps>(
  (
    {
      carouselApi,
      children,
      isEnd = false,
      isStart = false,
      styleXArray,
      ...props
    },
    ref,
  ) => {
    const { gap, pageOffset, size, fixedWidth } = carouselApi;

    const DeterminedElement = hallowElement;
    const determinedCarouselGridSize =
      CAROUSEL_GRID_SIZES[size as keyof typeof CAROUSEL_GRID_SIZES] ??
      (size as CarouselGridSize);

    return (
      <DeterminedElement
        ref={ref}
        {...props}
        {...stylex.props(
          styles.determinedElement,
          getPositionStyles({ gap, isEnd, isStart }),
          fixedWidth &&
            (pageOffset
              ? stylesSizePageOffet.width({
                  gap,
                  pageOffset: pageOffset,
                  size: determinedCarouselGridSize,
                })
              : stylesSize.width({ gap, size: determinedCarouselGridSize })),
          styleXArray,
        )}
      >
        {children}
      </DeterminedElement>
    );
  },
);

CarouselItem.displayName = "CarouselItem";
