import type { AppQueueContentType } from "@packages/sdk";
import { redirectToAuth, useSession } from "@packages/sdk";
import type { MouseEvent, MouseEventHandler } from "react";
import { useCallback } from "react";

import { usePlayer } from "./usePlayer";

export type UseAddToQueueProps = {
  type: AppQueueContentType;
  id: number;
};

export type UseAddToQueueReturn = {
  handleClickNext: MouseEventHandler<HTMLDivElement | HTMLButtonElement>;
  handleClickLast: MouseEventHandler<HTMLDivElement | HTMLButtonElement>;
  stringKeyNext: string;
  stringKeyLast: string;
};

export const useAddToQueue = ({
  type,
  id,
}: UseAddToQueueProps): UseAddToQueueReturn => {
  const player = usePlayer();
  const { status } = useSession();

  const handleClickNext = useCallback(
    (e: MouseEvent<HTMLDivElement | HTMLButtonElement>) => {
      e.stopPropagation();
      if (status === "authenticated")
        player?.addToQueue({ type, id, position: "next" });
      else redirectToAuth();
    },
    [player, status],
  );

  const handleClickLast = useCallback(
    (e: MouseEvent<HTMLDivElement | HTMLButtonElement>) => {
      e.stopPropagation();
      if (status === "authenticated")
        player?.addToQueue({ type, id, position: "last" });
      else redirectToAuth();
    },
    [player, status],
  );

  let stringKeyNext = "play_next";
  let stringKeyLast = "play_last";
  if (type === "collection") {
    stringKeyNext = "play_all_next";
    stringKeyLast = "play_all_later";
  }

  return { handleClickLast, handleClickNext, stringKeyNext, stringKeyLast };
};
