import { HallowAnalyticsEvent, useAnalytics } from "@packages/analytics";
import type { Images, Section, SectionItem } from "@packages/sdk";
import * as stylex from "@stylexjs/stylex";
import { forwardRef } from "react";

import { spacing } from "../../../../../../global/stylex/vars.stylex";
import type {
  HallowElement,
  HallowElementProps,
  WithAsChild,
} from "../../../../../types";
import {
  deepMergeStyleXProps,
  determineChildrenInject,
  determineElementFromAsChild,
} from "../../../../../utils";
import type { AuthorProps } from "../../../_core";
import { Author, IconButton } from "../../../_core";
import { MoreIcon } from "../../../icons";
import type { CampaignLargeProps } from "../CampaignLarge";
import { CampaignLarge } from "../CampaignLarge";
import { CampaignModalDropdownMenu } from "../CampaignModalDropdownMenu";

const hallowElement: HallowElement = "div";

const styles = stylex.create({
  campaignModalDropdownMenuContent: {
    marginRight: spacing.s,
  },
  determinedElement: {
    display: "flex",
    flexDirection: "column",
    gap: spacing.m,
  },
});

export type CampaignLargeWithAuthorProps = WithAsChild<
  HallowElementProps<"div">
> & {
  authorProps?: AuthorProps;
  campaignId: number;
  campaignName?: string;
  campaignLargeProps?: Partial<CampaignLargeProps>;
  color: string;
  description: string;
  illustrationImgSrc?: string;
  imgSrc?: Images;
  item?: SectionItem;
  joined?: boolean;
  hasEnded?: boolean;
  section?: Section;
  tags: string[];
  title: string;
};

export const CampaignLargeWithAuthor = forwardRef<
  any,
  CampaignLargeWithAuthorProps
>(
  (
    {
      asChild = false,
      authorProps,
      campaignId,
      campaignName,
      campaignLargeProps,
      color,
      children,
      description,
      illustrationImgSrc,
      imgSrc,
      item,
      joined,
      hasEnded,
      section,
      tags,
      title,
      ...props
    },
    ref,
  ) => {
    const analytics = useAnalytics();

    const DeterminedElement = determineElementFromAsChild({
      asChild,
      hallowElement,
    });

    const determinedChildren = determineChildrenInject({
      afterChildrenInject: [
        <Author
          key="author"
          rightActions={[
            <CampaignModalDropdownMenu
              key={`${campaignId}_dropdown_menu`}
              campaignId={campaignId}
              campaignName={campaignName}
              campaignSmallProps={{
                color,
                description,
                illustrationImgSrc,
                imgSrc,
                metadata: tags ? tags[0] : null,
                title,
              }}
              dropdownMenuProps={{
                align: "end",
                modal: false,
              }}
              joined={joined}
              hasEnded={hasEnded}
              trigger={
                <IconButton
                  icon={<MoreIcon />}
                  onPointerDown={() =>
                    // TODO: mobile calls this track event from parent
                    analytics.track({
                      event: HallowAnalyticsEvent.TappedSectionMoreMenu,
                      properties: {
                        // TODO: notes say page_id = -1 for campaign_list 'Viewed Screen' event, but requestPagesTrusted returns 83
                        // does home have a different page_id? JP: yes, and we have different homes (day/night)
                        // when we refactor this to call from parent, add proper page ids
                        page_id: -1,
                        section_id: section?.id,
                        section_item_id: item?.item_id ?? null,
                        section_title: section?.title ?? null,
                        section_type: section?.type ?? null,
                        index: section?.items.indexOf(item) ?? null,
                        item_type: item?.type ?? null,
                      },
                    })
                  }
                  variant="transparentNeutral"
                />
              }
            />,
          ]}
          {...authorProps}
        />,
        <CampaignLarge
          color={color}
          description={description}
          illustrationImgSrc={illustrationImgSrc}
          imgSrc={imgSrc}
          joined={joined}
          key="campaign"
          tags={tags}
          title={title}
          {...campaignLargeProps}
        />,
      ],
      children: children as JSX.Element,
    });

    return (
      <DeterminedElement
        ref={ref}
        {...deepMergeStyleXProps({
          object1: stylex.props(styles.determinedElement),
          object2: props,
        })}
      >
        {determinedChildren}
      </DeterminedElement>
    );
  },
);

CampaignLargeWithAuthor.displayName = "CampaignLargeWithAuthor";
