"use client";

import type { Dispatch, SetStateAction } from "react";
import { createContext } from "react";

export type ConsentContext = {
  ajsAnonymousId: null | string;
  consent: number[];
  isConsenting: boolean;
  isLoading: boolean;
  setAjsAnonymousId: Dispatch<SetStateAction<null | string>>;
  setConsent: Dispatch<SetStateAction<number[]>>;

  /** TODO: remove/improve when ConsentModal/CookieManager are improved */
  isConsentModalVisible: boolean;
  saveConsent: (props: Pick<ConsentContext, "consent">) => Promise<void>;
  setIsConsentModalVisible: Dispatch<SetStateAction<boolean>>;
};

export const consentContext = createContext<ConsentContext>({
  ajsAnonymousId: null,
  consent: [],
  isConsenting: false,
  isLoading: true,
  setAjsAnonymousId: () => {},
  setConsent: () => {},

  /** TODO: remove/improve when ConsentModal/CookieManager are improved */
  isConsentModalVisible: false,
  saveConsent: () => Promise.resolve(),
  setIsConsentModalVisible: () => {},
});
