import * as stylex from "@stylexjs/stylex";
import type { HTMLAttributes } from "react";

import {
  semanticColors,
  spacing,
} from "../../../../../global/stylex/vars.stylex";
import type { StyleXArray, WithStylexArray } from "../../../../types";

const styles = stylex.create({
  aside: {
    display: "flex",
    flexDirection: "column",
    gap: spacing.m,
    height: spacing.full,
    left: 0,
    maxHeight: `calc(100vh - ${spacing.xxxl})`,
    overflowX: "hidden",
    overflowY: "auto",
    paddingBottom: spacing.xxxl,
    paddingLeft: spacing.m,
    paddingRight: spacing.m,
    paddingTop: spacing.m,
    position: "sticky",
    top: 64,
    width: spacing.full,
  },
  asideClosed: {
    maxWidth: 72,
    minWidth: 72,
  },
  asideExpanded: {
    maxWidth: spacing.sidebar,
    minWidth: spacing.sidebar,
  },
  border: {
    backgroundColor: semanticColors.neutralsLowest,
    height: `calc(100% - ${spacing.xl})`,
    position: "absolute",
    right: 0,
    top: 0,
    width: spacing.t,
  },
  container: {
    position: "relative",
  },
});

export type SidebarProps = WithStylexArray<
  HTMLAttributes<HTMLDivElement> & {
    borderStyleXArray?: StyleXArray;
    state?: "closed" | "expanded";
  }
>;

export const Sidebar = ({
  borderStyleXArray,
  children,
  state = "expanded",
  styleXArray = [],
  ...props
}: SidebarProps) => {
  return (
    <div {...stylex.props(styles.container)}>
      <aside
        {...stylex.props(
          styles.aside,
          state === "closed" ? styles.asideClosed : styles.asideExpanded,
          styleXArray,
        )}
        {...props}
      >
        {children}
      </aside>
      <div {...stylex.props(styles.border, borderStyleXArray)} />
    </div>
  );
};
