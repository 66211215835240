"use client";

import * as SelectPrimitive from "@radix-ui/react-select";
import stylex from "@stylexjs/stylex";
import * as React from "react";

import {
  radius,
  semanticColors,
  spacing,
  stroke,
  zIndices,
} from "../../../../../global/stylex/vars.stylex";
import { useTheme } from "../../../../theme";
import type { WithStylexArray } from "../../../../types";
import { SelectScrollDownButton } from "./SelectScrollDownButton";
import { SelectScrollUpButton } from "./SelectScrollUpButton";

const styles = stylex.create({
  content: {
    backgroundColor: semanticColors.onOverlayBackground,
    borderColor: semanticColors.neutralsLowest,
    borderRadius: radius.l,
    borderStroke: stroke.regular,
    borderStyle: "solid",
    color: semanticColors.primary,
    maxHeight: "var(--radix-select-content-available-height)",
    minWidth: "100px",
    overflow: "hidden",
    padding: spacing.s,
    position: "relative",
    zIndex: zIndices.modalContent,
  },
});

type SelectContentProps = WithStylexArray<
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Content>
>;

export const SelectContent = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Content>,
  SelectContentProps
>(({ children, styleXArray, ...props }, ref) => {
  const { themeContainerRef } = useTheme();
  return (
    <SelectPrimitive.Portal container={themeContainerRef.current}>
      <SelectPrimitive.Content
        ref={ref}
        {...props}
        {...stylex.props(styles.content, styleXArray)}
      >
        <SelectScrollUpButton />
        <SelectPrimitive.Viewport>{children}</SelectPrimitive.Viewport>
        <SelectScrollDownButton />
      </SelectPrimitive.Content>
    </SelectPrimitive.Portal>
  );
});

SelectContent.displayName = "SelectContent";
