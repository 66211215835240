"use client";

import { useTranslations } from "@packages/i18n";
import { useEffect } from "react";

import { useAlert } from "../components";

type ErrorNotificationProps = {
  isError: boolean;
  title?: string;
  description?: string;
};

export function useErrorNotification({
  isError,
  title,
  description,
}: ErrorNotificationProps) {
  const alert = useAlert({ button: "Ok" });
  const t = useTranslations();

  useEffect(() => {
    if (isError && !alert.visible) {
      alert.show({
        id: "error-notification",
        title: title ?? t("general_word_error"),
        description,
      });
    }
  }, [isError]);
}
