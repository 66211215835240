import stylex from "@stylexjs/stylex";

import { spacing } from "../../../../../global/stylex/vars.stylex";
import type { HallowElementProps, WithStylexArray } from "../../../../types";

const styles = stylex.create({
  headerContainer: {
    alignItems: "center",
    display: "flex",
    gap: spacing.xxs,
    justifyContent: "space-between",
    padding: spacing.m,
    width: spacing.full,
  },
});

type PanelHeaderProps = WithStylexArray<HallowElementProps<"div">>;

export const PanelHeader = ({
  children,
  styleXArray,
  ...props
}: PanelHeaderProps) => {
  return (
    <div {...stylex.props(styles.headerContainer, styleXArray)} {...props}>
      {children}
    </div>
  );
};
