import * as stylex from "@stylexjs/stylex";
import type { ReactNode } from "react";
import { forwardRef, Fragment } from "react";

import {
  numericPercentages,
  numericValues,
  radius,
  semanticColors,
  spacing,
} from "../../../../../global/stylex/vars.stylex";
import type {
  HallowElement,
  HallowElementProps,
  KeyedElement,
  WithAsChild,
  WithStylexArray,
} from "../../../../types";
import {
  determineChildrenInject,
  determineElementFromAsChild,
} from "../../../../utils";
import type { IconElement } from "../../icons";
import { InlineIcon, MoreIcon, PlaceholderIcon } from "../../icons";
import { ModalDropdownMenu } from "../../modals";
import { Text } from "../Text";

const hallowElement: HallowElement = "div";

const styles = stylex.create({
  base: {
    "-webkit-tap-highlight-color": "transparent",
    alignItems: "center",
    backgroundColor: {
      default: semanticColors.background,
      ":hover": semanticColors.neutralsLowest,
      ":focus": semanticColors.neutralsLowest,
      ":active": semanticColors.neutralsLowest,
    },
    borderRadius: radius.m,
    color: semanticColors.primary,
    cursor: "pointer",
    display: "flex",
    gap: spacing.ms,
    justifyContent: "space-between",
    opacity: {
      default: numericValues[1],
      ":focus": numericValues[0.6],
      ":active": numericValues[0.6],
    },
    paddingBottom: spacing.s,
    paddingLeft: spacing.m,
    paddingRight: spacing.m,
    paddingTop: spacing.s,
    width: numericPercentages[100],
  },
  content: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    gap: spacing.t,
    width: spacing.t,
  },
  disabled: {
    opacity: 0.3,
    pointerEvents: "none",
  },
  image: {
    borderRadius: radius.s,
    height: spacing.xxxl,
    objectFit: "cover",
    width: spacing.xxxl,
  },
  metaData: {
    alignItems: "center",
    color: semanticColors.neutralsMedium,
    display: "flex",
    gap: spacing.xs,
  },
  number: {
    color: semanticColors.neutralsMedium,
  },
  rightActionsContainer: {
    alignItems: "center",
    display: "flex",
    gap: spacing.m,
  },
});

export type ListCollectionDetailProps = WithAsChild<
  WithStylexArray<HallowElementProps<typeof hallowElement>>
> & {
  description?: string;
  disabled?: boolean;
  imageSrc?: string;
  metaData?: string[];
  metaDataIcon?: IconElement;
  moreMenuContent?: ReactNode;
  number?: JSX.Element | number | string;
  rightActions?: KeyedElement | KeyedElement[];
  title: string;
};

export const ListCollectionDetail = forwardRef<any, ListCollectionDetailProps>(
  (
    {
      asChild = false,
      children,
      description,
      disabled = false,
      imageSrc,
      metaData,
      metaDataIcon = <PlaceholderIcon />,
      moreMenuContent,
      number = "00",
      rightActions,
      styleXArray = [],
      title,
      ...props
    },
    ref,
  ) => {
    const DeterminedElement = determineElementFromAsChild({
      asChild,
      hallowElement,
    });

    const DeterminedChildren = determineChildrenInject({
      afterChildrenInject: [
        number ? (
          <Text
            key="number"
            size="s"
            styleXArray={[styles.number]}
            type="title"
          >
            {number}
          </Text>
        ) : null,
        imageSrc ? (
          <img
            alt={description}
            key="img"
            src={imageSrc}
            {...stylex.props(styles.image)}
          />
        ) : null,
        <span key="content" {...stylex.props(styles.content)}>
          <Text key={"title"} overflow="ellipsis" size="m" type="title">
            {title}
          </Text>
          {description && (
            <Text
              key={"description"}
              overflow="ellipsis"
              size="xl"
              type="detail"
            >
              {description}
            </Text>
          )}
          {metaData?.length > 0 && (
            <span key={"metadata"} {...stylex.props(styles.metaData)}>
              {metaData?.map((metaDataItem, index) => (
                <Fragment key={`${metaDataItem}${index}`}>
                  <Text
                    key={"metadataText"}
                    overflow="ellipsis"
                    size="l"
                    type="detail"
                  >
                    {metaDataItem}
                  </Text>
                  {index < metaData.length - 1 && (
                    <InlineIcon key={"metadataIcon"} icon={metaDataIcon} />
                  )}
                </Fragment>
              ))}
            </span>
          )}
        </span>,
        moreMenuContent ? (
          <ModalDropdownMenu trigger={<MoreIcon key="icon-more" />}>
            {moreMenuContent}
          </ModalDropdownMenu>
        ) : null,
        rightActions ? (
          <div
            key={"rightActions"}
            {...stylex.props(styles.rightActionsContainer)}
          >
            {rightActions}
          </div>
        ) : null,
      ],
      children: children as JSX.Element,
    });

    return (
      <DeterminedElement
        ref={ref}
        {...stylex.props(
          styles.base,
          disabled ? styles.disabled : null,
          styleXArray,
        )}
        {...props}
      >
        {DeterminedChildren}
      </DeterminedElement>
    );
  },
);

ListCollectionDetail.displayName = "ListCollectionDetail";
