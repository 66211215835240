"use client";

import { useTranslations } from "@packages/i18n";
import type { MediaSizes } from "@packages/media";
import { usePlayer } from "@packages/media";
import * as stylex from "@stylexjs/stylex";
import { forwardRef, useCallback } from "react";

import { numericValues } from "../../../../../../global/stylex/vars.stylex";
import type { ButtonProps } from "../../../../../components";
import { PauseIcon, PlayIcon } from "../../../../../components";
import { useDynamicViewport } from "../../../../../hooks";
import type { MediaButtonProps } from "../MediaButton";
import { MediaButton } from "../MediaButton";

const buttonStyles = stylex.create({
  default: {
    height: "auto",
    width: "auto",
  },
  "expanded-mobile": {
    padding: numericValues[0],
  },
  "expanded-desktop": {
    padding: numericValues[0],
  },
});

export type PlayPauseProps = Omit<ButtonProps, "size" | "color"> & {
  size?: MediaButtonProps["size"];
};

export const PlayPause = forwardRef<HTMLButtonElement, PlayPauseProps>(
  ({ size: buttonSize = null, ...props }, ref) => {
    const player = usePlayer();
    const isDesktop = useDynamicViewport({ minimumWidth: 1024 });
    const size: MediaSizes = `${player?.display ?? "hidden"}-${isDesktop ? "desktop" : "mobile"}`;
    const t = useTranslations();

    const handlePlayPause = useCallback(
      (e) => {
        e.stopPropagation();
        if (player?.paused) {
          player.play();
        } else {
          player?.pause();
        }
      },
      [player],
    );

    return (
      <MediaButton
        largeIcon={
          !player || player.paused ? <PlayIcon.XL /> : <PauseIcon.XL />
        }
        smallIcon={
          !player || player.paused ? <PlayIcon.Medium /> : <PauseIcon.Medium />
        }
        size={buttonSize}
        onClick={handlePlayPause}
        styleXArray={[
          buttonStyles.default,
          buttonSize === "l"
            ? buttonStyles["expanded-desktop"]
            : buttonStyles[size],
        ]}
        ref={ref}
        {...props}
        title={t(
          !player || player.paused
            ? "button_description_play"
            : "button_description_pause",
        )}
      />
    );
  },
);

PlayPause.displayName = "PlayPause";
