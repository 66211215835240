"use client";

import type { CommunitiesAdminMostPopular } from "@packages/sdk";
import * as stylex from "@stylexjs/stylex";
import Link from "next/link";

import { spacing } from "../../../../../../global/stylex/vars.stylex";
import { useCarousel } from "../../../../../hooks";
import { Collection2Icon, ContentMedium } from "../../../../_base";
import { Carousel } from "../../../layout";

const LOADING_SKELETONS_TOTAL = 8;

const styles = stylex.create({
  carousel: {
    maxWidth: `calc(100vw - ${spacing.sidebar})`,
    paddingBottom: spacing.m,
    paddingTop: spacing.m,
  },
});

export type CarouselCommunityMostPopularProps = {
  data: CommunitiesAdminMostPopular;
  id: number;
  loading?: boolean;
};

export const CarouselCommunityMostPopular = ({
  data,
  id,
  loading = false,
}: CarouselCommunityMostPopularProps) => {
  const carouselApi = useCarousel({
    pageOffset: [
      [24, 32],
      [16, 24],
    ],
    size: [2, 2, 3, 4, 5, 5],
  });

  return (
    <Carousel
      carouselApi={carouselApi}
      containerStylexArray={[styles.carousel]}
    >
      {loading
        ? [...Array(LOADING_SKELETONS_TOTAL)].map((index) => (
            <ContentMedium key={index} loading />
          ))
        : data?.results?.map((mostPopular, index) => {
            const title = mostPopular.name ?? mostPopular.title;
            const description =
              mostPopular.short_desc ??
              mostPopular.label_desc ??
              mostPopular.description;
            const metadata =
              mostPopular?.lengths ?? mostPopular.sessions ?? null;
            const href = `/admin/${id}/dashboard/${
              mostPopular.sessions
                ? `collections/${mostPopular.id}`
                : `prayers/${mostPopular.id}`
            }`;
            return (
              <ContentMedium
                asChild
                description={description}
                imgSrc={mostPopular.images}
                metadata={metadata}
                metadataInlineIcon={
                  mostPopular.sessions ? <Collection2Icon.Small /> : null
                }
                rank={index + 1}
                title={title}
              >
                <Link href={href} />
              </ContentMedium>
            );
          })}
    </Carousel>
  );
};
