import * as stylex from "@stylexjs/stylex";

import {
  colors,
  radius,
  spacing,
  zIndices,
} from "../../../../../../global/stylex/vars.stylex";
import type { CardProps } from "../../../../refactor";
import { Card } from "../../../../refactor";
import type { TextProps } from "../../../_core";

const styles = stylex.create({
  description: {
    textAlign: "center",
  },
  imgNoDescription: {
    borderBottomLeftRadius: radius.m,
    borderBottomRightRadius: radius.m,
  },
  imgContainerNoDescription: {
    borderBottomLeftRadius: radius.m,
    borderBottomRightRadius: radius.m,
  },
  infoContainer: {
    height: "60px",
    paddingBottom: spacing.none,
    paddingLeft: spacing.m,
    paddingRight: spacing.m,
  },
  infoContainerNoDescription: {
    display: "none",
  },
  mask: {
    paddingLeft: spacing.m,
    paddingRight: spacing.m,
  },
  maskNoDescription: {
    borderBottomLeftRadius: radius.m,
    borderBottomRightRadius: radius.m,
    justifyContent: "flex-end",
    marginBottom: spacing.none,
    paddingBottom: spacing.m,
  },
  title: {
    textAlign: "center",
    textShadow: `0 0 40px ${colors.black}, 0 0 80px ${colors.black}, 0 0 120px ${colors.black}`,
    "::after": {
      content: "attr(data-content)",
      left: 0,
      position: "absolute",
      right: 0,
      textAlign: "center",
      textShadow: "none",
      top: 0,
      zIndex: zIndices.absolute,
    },
  },
  titleNoDescription: {
    paddingBottom: spacing.l,
    textWrap: "wrap",
    whiteSpace: null,
  },
});

export type CategoryMediumProps = CardProps & {
  noDescription?: boolean;
};

export const CategoryMedium = ({
  description,
  descriptionProps,
  imgContainerStyleXArray,
  imgSrc,
  imgStyleXArray,
  infoContainerStyleXArray,
  maskProps,
  noDescription,
  title,
  titleProps,
  ...props
}: CategoryMediumProps) => (
  <Card
    description={description}
    imgSrc={imgSrc}
    title={title}
    {...props}
    descriptionProps={
      {
        ...descriptionProps,
        styleXArray: [styles.description, descriptionProps?.styleXArray],
      } as TextProps
    }
    imgContainerStyleXArray={[
      noDescription ? styles.imgContainerNoDescription : null,
      imgContainerStyleXArray,
    ]}
    imgStyleXArray={[
      noDescription ? styles.imgNoDescription : null,
      imgStyleXArray,
    ]}
    infoContainerStyleXArray={[
      styles.infoContainer,
      noDescription ? styles.infoContainerNoDescription : null,
      infoContainerStyleXArray,
    ]}
    maskProps={{
      ...maskProps,
      styleXArray: [
        styles.mask,
        noDescription ? styles.maskNoDescription : null,
        maskProps?.styleXArray,
      ],
    }}
    titleProps={{
      ...titleProps,
      styleXArray: [
        styles.title,
        noDescription ? styles.titleNoDescription : null,
        titleProps?.styleXArray,
      ],
    }}
  />
);
