import stylex from "@stylexjs/stylex";

import {
  fontFamilies,
  numericPixels,
  numericValues,
  radius,
  semanticColors,
  spacing,
} from "../../../../../global/stylex/vars.stylex";
import type { WithStylexArray } from "../../../../types";
import { Text } from "../../../_base/_core/Text";
import { Skeleton } from "../../_core";

const styles = stylex.create({
  container: {
    alignItems: "center",
    background: semanticColors.background,
    border: `2px solid ${semanticColors.neutralsLowest}`,
    borderRadius: radius.m,
    display: "flex",
    flexDirection: "column",
    gap: spacing.xxs,
    justifyContent: "center",
    padding: spacing.l,
    textAlign: "center",
  },
  valueText: {
    alignSelf: "stretch",
    fontFamily: fontFamilies.sansSerif,
    fontSize: numericPixels[32],
    fontWeight: numericValues[400],
  },
  labelText: {
    alignSelf: "stretch",
    color: semanticColors.neutralsHigh,
  },
  skeleton: {
    height: "106px",
  },
});

export type StatisticCardProps = WithStylexArray<{
  value: string;
  label: string;
  loading?: boolean;
}>;

export const StatisticCard = ({
  value,
  label,
  loading,
  styleXArray = [],
}: StatisticCardProps) => {
  if (loading) {
    return <Skeleton styleXArray={[styles.skeleton]} />;
  }
  return (
    <div {...stylex.props(styles.container, ...styleXArray)}>
      <span {...stylex.props(styles.valueText)}>{value}</span>
      <Text type="detail" size="l" styleXArray={[styles.labelText]}>
        {label}
      </Text>
    </div>
  );
};

StatisticCard.displayName = "StatisticCard";
