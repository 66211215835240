"use client";

import { useTranslations } from "@packages/i18n";
import type * as SelectPrimitive from "@radix-ui/react-select";

import type { StyleXArray } from "../../../../../types";
import { Select, SelectValue } from "../Select";
import { SelectContent } from "../SelectContent";
import { SelectItem } from "../SelectItem";
import { SelectTrigger } from "../SelectTrigger";

export type SelectMenuProps = SelectPrimitive.SelectProps & {
  options: { label: string; value: string }[];
  placeholder?: string;
  triggerStyleXArray?: StyleXArray;
  contentStyleXArray?: StyleXArray;
};

export const SelectMenu = ({
  options,
  placeholder,
  triggerStyleXArray = [],
  contentStyleXArray = [],
  ...props
}: SelectMenuProps) => {
  const t = useTranslations();
  return (
    <Select {...props}>
      <SelectTrigger styleXArray={triggerStyleXArray}>
        <SelectValue placeholder={placeholder ?? t("general_word_select")} />
      </SelectTrigger>
      <SelectContent styleXArray={contentStyleXArray}>
        {options.map((option) => (
          <SelectItem key={option.value} value={option.value}>
            {option.label}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
};
