"use client";

import { useModal } from "@ebay/nice-modal-react";
import { HallowAnalyticsEvent, useAnalytics } from "@packages/analytics";
import {
  getShareProperties,
  useCurrentQueueItem,
  usePlayer,
} from "@packages/media";
import * as stylex from "@stylexjs/stylex";
import { Fragment, useContext } from "react";

import { themes } from "../../../../../../global/stylex/themes.stylex";
import {
  numericPercentages,
  semanticColors,
  spacing,
  zIndices,
} from "../../../../../../global/stylex/vars.stylex";
import { IconButton, ShareIcon, ShareModal } from "../../../../../components";
import type { WithButtonWrapper } from "../../../../../types";
import { PlayerInternalContext, Queue, TextOnScreen } from "../../../core";
import { VolumeControl } from "../../controls";
import { MediaMenu } from "../MediaMenu";

const styles = stylex.create({
  controlSet: {
    alignItems: "center",
    columnGap: spacing.s,
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    justifyContent: "flex-end",
  },
  fullWidth: {
    width: numericPercentages[100],
  },
  smallContainer: {
    justifyContent: "space-between",
  },
  withVolume: {
    justifyContent: "space-between",
  },
  leftSet: {
    justifyContent: "flex-start",
  },
  modalZ: {
    zIndex: zIndices.modalOverQueue,
  },
  modalPadding: {
    paddingTop: spacing.l,
  },
});

export type SettingsControlsProps = WithButtonWrapper<{
  size?: "s" | "l";
  withVolume?: boolean;
}>;

export const SettingsControls = ({
  size = "l",
  withVolume = false,
  Wrapper = ({ children }) => <Fragment>{children}</Fragment>,
}: SettingsControlsProps) => {
  const player = usePlayer();
  const { currentItem } = useCurrentQueueItem();
  const { collapsePlayer, transcriptOpen, transcriptOpenChangeHandler } =
    useContext(PlayerInternalContext);
  const shareModal = useModal(ShareModal);
  const analytics = useAnalytics();

  const ShareAndSettings = (
    <>
      <Wrapper asChild>
        <IconButton
          icon={<ShareIcon fill={semanticColors.onColorVeryHigh} />}
          variant={"transparentWhite"}
          tabIndex={0}
          onClick={() => {
            const event =
              currentItem?.type === "prayer"
                ? "Tapped Share Prayer"
                : currentItem?.type === "radio_song"
                  ? "Tapped Share Radio Station"
                  : null;
            if (event) {
              const props: Record<string, number> = {};
              if (currentItem.type === "prayer") {
                props.prayer = currentItem.prayer.id;
              } else if (currentItem.type === "radio_song") {
                props.radio_station = currentItem.radio_station.id;
              }
              analytics.track({
                event,
                ...props,
              });
            }
            return shareModal.show({
              ...getShareProperties(currentItem, [
                themes.dark,
                styles.modalZ,
                styles.modalPadding,
              ]),
              overlayProps: {
                onPointerDown: () => {
                  analytics.track({
                    event: HallowAnalyticsEvent.ClosedShareAudioView,
                  });
                },
              },
            });
          }}
        />
      </Wrapper>
      <MediaMenu Wrapper={Wrapper} />
    </>
  );

  const TextAndQueue =
    currentItem?.type === "radio_song" ? null : (
      <>
        <TextOnScreen
          id={currentItem?.selected_audio?.id}
          prayerId={currentItem?.prayer?.id}
          iconColor={semanticColors.onColorVeryHigh}
          Wrapper={Wrapper}
          onClick={(e) => {
            if (player?.display === "expanded") collapsePlayer(e);
          }}
          open={transcriptOpen}
          onOpenChange={transcriptOpenChangeHandler}
        />
        <Queue Wrapper={Wrapper} />
      </>
    );

  return (
    <div
      {...stylex.props(
        styles.controlSet,
        styles.fullWidth,
        size === "s" ? styles.smallContainer : null,
        withVolume ? styles.withVolume : null,
      )}
      onClick={(e) => {
        if (e.currentTarget !== e.target) e.stopPropagation();
      }}
    >
      {withVolume ? (
        <>
          <div {...stylex.props(styles.controlSet, styles.leftSet)}>
            {ShareAndSettings}
            <VolumeControl
              value={player?.volume ?? 1}
              onVolumeChange={(v) => player?.setVolume(v)}
              iconColor={semanticColors.onColorVeryHigh}
              onOverlay={true}
              Wrapper={Wrapper}
            />
          </div>
          <div {...stylex.props(styles.controlSet)}>{TextAndQueue}</div>
        </>
      ) : (
        <>
          {ShareAndSettings}
          {TextAndQueue}
        </>
      )}
    </div>
  );
};
