"use client";

import {
  Description,
  type ToastProps as RadixToastProps,
} from "@radix-ui/react-toast";
import * as stylex from "@stylexjs/stylex";
import { type PropsWithChildren } from "react";

import {
  fontSizes,
  fontWeights,
  lineHeights,
  semanticColors,
  spacing,
  stroke,
} from "../../../../global/stylex/vars.stylex";
import type { IconElement } from "../../_base";
import { Popup } from "../Popup";

export type ToastProps = PropsWithChildren<{
  type?: RadixToastProps["type"];
  icon?: IconElement;
}>;

const styles = stylex.create({
  message: {
    color: semanticColors.primary,
    fontSize: fontSizes.titleS,
    fontWeight: fontWeights.titleS,
    lineHeight: lineHeights.titleS,
  },
  toast: {
    borderColor: semanticColors.neutralsLowest,
    borderStyle: "solid",
    borderWidth: stroke.light,
    maxWidth: "max-content",
    padding: `${spacing.xxs} ${spacing.ms}`,
  },
  toastWithIcon: {
    padding: `${spacing.xxs} ${spacing.ms} ${spacing.xxs} ${spacing.xxs}`,
  },
});

export const Toast = ({ children, icon, type = "foreground" }: ToastProps) => {
  return (
    <Popup
      durationMs={2500}
      type={type}
      styleXArray={[styles.toast]}
      icon={icon}
    >
      <Description {...stylex.props(styles.message)}>{children}</Description>
    </Popup>
  );
};
