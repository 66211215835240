"use client";

import { HallowAnalyticsEvent, useAnalytics } from "@packages/analytics";
import { useTranslations } from "@packages/i18n";
import type { TrustedCollection } from "@packages/sdk";
import { redirectToAuth, useRequestChallenge, useSession } from "@packages/sdk";
import type { MouseEvent } from "react";

import type { ButtonProps } from "../../../_base";
import { Button } from "../../../_base";

type JoinChallengeButtonProps = {
  collection: Pick<TrustedCollection, "id">;
  isFullWidth?: boolean;
  size?: ButtonProps["size"];
  variant?: ButtonProps["variant"];
};

export const JoinChallengeButton = ({
  collection,
  isFullWidth = false,
  size = "m",
  variant = "white",
}: JoinChallengeButtonProps) => {
  const analytics = useAnalytics();
  const { status } = useSession();
  const t = useTranslations();
  const { mutationPost: joinChallenge } = useRequestChallenge({
    id: collection.id,
  });

  const handleJoinChallenge = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    analytics.track({
      event: HallowAnalyticsEvent.TappedJoinChallenge,
      properties: {
        challenge: collection.id,
        collection: collection.id,
      },
    });

    status === "authenticated" ? joinChallenge.mutate() : redirectToAuth();
  };

  //TODO: Add is loading state to the button once we get designs for that
  return (
    <Button
      {...({ size, variant } as ButtonProps)}
      isFullWidth={isFullWidth}
      onClick={handleJoinChallenge}
      disabled={joinChallenge.isPending}
    >
      {t("challenge_details_join_challenge")}
    </Button>
  );
};
