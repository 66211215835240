"use client";

import { createContext } from "react";

export type ABContext = {
  isLoading: boolean;
};

export const abContext = createContext<ABContext>({
  isLoading: true,
});
