"use client";

import * as stylex from "@stylexjs/stylex";
import { forwardRef, Fragment } from "react";

import {
  colors,
  numericPixels,
  numericValues,
  radius,
  semanticColors,
  semanticColorsRaw,
  spacing,
  stroke,
} from "../../../../../global/stylex/vars.stylex";
import type {
  HallowElement,
  HallowElementProps,
  WithAsChild,
  WithStylexArray,
} from "../../../../types";
import type { CSSColor } from "../../../../utils";
import {
  colorMix,
  determineChildrenInject,
  determineElementFromAsChild,
} from "../../../../utils";
import type { IconElement } from "../../icons";
import type { TextProps } from "../Text";
import { Text } from "../Text";

const Element: HallowElement = "button";

const dynamicStylesSizeChildrenIconValues: Record<
  NonNullable<ButtonProps["size"]>,
  { icon: string; noIcon: string }
> = {
  xs: { icon: spacing.xxs, noIcon: spacing.s },
  s: { icon: spacing.xs, noIcon: spacing.ms },
  m: { icon: spacing.s, noIcon: spacing.m },
  l: { icon: spacing.m, noIcon: spacing.l },
};

const dynamicStylesSizeChildrenIcon = stylex.create({
  padding: (
    buttonProps: Pick<ButtonProps, "endIcon" | "size" | "startIcon">,
  ) => ({
    paddingLeft: buttonProps.startIcon
      ? dynamicStylesSizeChildrenIconValues[
          buttonProps.size as NonNullable<ButtonProps["size"]>
        ].icon
      : dynamicStylesSizeChildrenIconValues[
          buttonProps.size as NonNullable<ButtonProps["size"]>
        ].noIcon,
    paddingRight: buttonProps.endIcon
      ? dynamicStylesSizeChildrenIconValues[
          buttonProps.size as NonNullable<ButtonProps["size"]>
        ].icon
      : dynamicStylesSizeChildrenIconValues[
          buttonProps.size as NonNullable<ButtonProps["size"]>
        ].noIcon,
  }),
});

const styles = stylex.create({
  base: {
    alignItems: "center",
    border: "none",
    borderRadius: radius.full,
    boxSizing: "border-box",
    cursor: "pointer",
    display: "flex",
    justifyContent: "space-between",
    transition:
      "background-color 100ms ease-out, border-color 100ms ease-out, color 100ms ease-out",
    whiteSpace: "nowrap",
  },
  disabled: {
    cursor: "not-allowed",
    opacity: numericValues["0.5"],
  },
  fullWidth: {
    justifyContent: "center",
    width: "100%",
  },
  icon: {
    flexShrink: 0,
  },
  transparent: {
    backgroundColor: colors.transparent,
    color: semanticColors.neutralsLower,
  },
});

const stylesSize = stylex.create({
  xs: {
    gap: spacing.t,
    height: spacing.l,
    paddingBottom: "none",
    paddingLeft: spacing.s,
    paddingRight: spacing.s,
    paddingTop: "none",
  },
  s: {
    gap: spacing.xxs,
    height: spacing.xl,
    paddingBottom: "none",
    paddingLeft: spacing.m,
    paddingRight: spacing.m,
    paddingTop: "none",
  },
  m: {
    gap: spacing.xxs,
    height: numericPixels[40],
    paddingBottom: spacing.s,
    paddingLeft: spacing.ml,
    paddingRight: spacing.ml,
    paddingTop: spacing.s,
  },
  l: {
    gap: spacing.s,
    height: numericPixels[56],
    paddingBottom: spacing.m,
    paddingLeft: spacing.xl,
    paddingRight: spacing.xl,
    paddingTop: spacing.m,
  },
});

const stylesVariant = stylex.create({
  primary: () => ({
    backgroundColor: {
      default: semanticColors.primary,
      ":focus-visible": semanticColors.neutralsHigher,
      ":hover": semanticColors.neutralsHigher,
      ":active": semanticColors.neutralsHigher,
    },
    color: {
      default: semanticColors.secondary,
      ":focus-visible": semanticColors.neutralsLow,
      ":hover": semanticColors.secondary,
      ":active": semanticColors.neutralsLow,
    },
  }),
  secondary: () => ({
    backgroundColor: {
      default: semanticColors.secondary,
      ":focus-visible": semanticColors.neutralsLowest,
      ":hover": semanticColors.neutralsLowest,
      ":active": semanticColors.neutralsLowest,
    },
    color: {
      default: semanticColors.primary,
      ":focus-visible": semanticColors.neutralsMedium,
      ":hover": semanticColors.primary,
      ":active": semanticColors.neutralsMedium,
    },
  }),
  neutral: () => ({
    backgroundColor: {
      default: semanticColors.neutralsLowest,
      ":focus-visible": semanticColors.neutralsVeryLow,
      ":hover": semanticColors.neutralsVeryLow,
      ":active": semanticColors.neutralsVeryLow,
    },
    color: {
      default: semanticColors.primary,
      ":focus-visible": semanticColors.neutralsMedHigh,
      ":hover": semanticColors.primary,
      ":active": semanticColors.neutralsMedHigh,
    },
  }),
  lighten: () => ({
    backgroundColor: {
      default: semanticColors.onColorHigh,
      ":focus-visible": semanticColors.onColorVeryHigh,
      ":hover": semanticColors.onColorVeryHigh,
      ":active": semanticColors.onColorVeryHigh,
    },
    color: {
      default: semanticColors.onColorHighest,
      ":focus-visible": semanticColors.onColorVeryHigh,
      ":hover": semanticColors.onColorHighest,
      ":active": semanticColors.onColorVeryHigh,
    },
  }),
  darken: () => ({
    backgroundColor: {
      default: semanticColors.onColorLow,
      ":focus-visible": semanticColors.onColorVeryLow,
      ":hover": semanticColors.onColorVeryLow,
      ":active": semanticColors.onColorVeryLow,
    },
    color: {
      default: semanticColors.onColorHighest,
      ":focus-visible": semanticColors.onColorVeryHigh,
      ":hover": semanticColors.onColorHighest,
      ":active": semanticColors.onColorVeryHigh,
    },
  }),
  color: (color) => ({
    backgroundColor: {
      default: color,
      ":hover": colorMix({
        adjustment: semanticColorsRaw.fixedDarkenMedLow as CSSColor,
        source: color,
      }),
      ":focus-visible": colorMix({
        adjustment: semanticColorsRaw.fixedDarkenMedLow as CSSColor,
        source: color,
      }),
      ":active": colorMix({
        adjustment: semanticColorsRaw.fixedDarkenMedLow as CSSColor,
        source: color,
      }),
    },
    color: {
      default: semanticColors.onColorHighest,
      ":hover": semanticColors.onColorHighest,
      ":focus-visible": semanticColors.onColorVeryHigh,
      ":active": semanticColors.onColorVeryHigh,
    },
  }),
  white: () => ({
    backgroundColor: {
      default: semanticColors.onColorHighest,
      ":hover": colorMix({
        adjustment: semanticColorsRaw.fixedDarkenLow as CSSColor,
        source: semanticColorsRaw.onColorHighest as CSSColor,
      }),
      ":focus-visible": colorMix({
        adjustment: semanticColorsRaw.fixedDarkenLow as CSSColor,
        source: semanticColorsRaw.onColorHighest as CSSColor,
      }),
      ":active": colorMix({
        adjustment: semanticColorsRaw.fixedDarkenLow as CSSColor,
        source: semanticColorsRaw.onColorHighest as CSSColor,
      }),
    },
    color: {
      default: colors.black,
      ":hover": colors.black,
      ":focus-visible": semanticColors.onColorVeryLow,
      ":active": semanticColors.onColorVeryLow,
    },
  }),
  outline: () => ({
    backgroundColor: {
      default: semanticColors.secondary,
      ":hover": semanticColors.neutralsLowest,
      ":focus-visible": semanticColors.neutralsLowest,
      ":active": semanticColors.neutralsLowest,
    },
    borderColor: semanticColors.higherContrastLowest,
    borderStyle: "solid",
    borderWidth: stroke.regular,
    color: {
      default: semanticColors.primary,
      ":hover": semanticColors.primary,
      ":focus-visible": semanticColors.neutralsMedium,
      ":active": semanticColors.neutralsMedium,
    },
  }),
  transparentNeutral: () => ({
    backgroundColor: {
      default: colors.transparent,
      ":hover": semanticColors.neutralsLowest,
      ":focus-visible": semanticColors.neutralsLowest,
      ":active": semanticColors.neutralsLowest,
    },
    color: {
      default: semanticColors.neutralsMedium,
      ":hover": semanticColors.neutralsMedium,
      ":focus-visible": semanticColors.neutralsLow,
      ":active": semanticColors.neutralsLow,
    },
  }),
  transparentPrimary: () => ({
    backgroundColor: {
      default: colors.transparent,
      ":hover": semanticColors.neutralsLowest,
      ":focus-visible": semanticColors.neutralsLowest,
      ":active": semanticColors.neutralsLowest,
    },
    color: {
      default: semanticColors.primary,
      ":hover": semanticColors.primary,
      ":focus-visible": semanticColors.neutralsMedium,
      ":active": semanticColors.neutralsMedium,
    },
  }),
  transparentWhite: () => ({
    backgroundColor: {
      default: colors.transparent,
      ":hover": semanticColors.fixedLightenLow,
      ":focus-visible": semanticColors.fixedLightenLow,
      ":active": semanticColors.fixedLightenLow,
    },
    color: {
      default: semanticColors.onColorHighest,
      ":hover": semanticColors.onColorHighest,
      ":focus-visible": semanticColors.onColorVeryHigh,
      ":active": semanticColors.onColorVeryHigh,
    },
  }),
});

const stylesVariantDisabled = stylex.create({
  color: (color) => ({
    backgroundColor: color,
    color: semanticColors.onColorHighest,
  }),
  darken: () => ({
    backgroundColor: semanticColors.onColorLow,
    color: semanticColors.onColorHighest,
  }),
  lighten: () => ({
    backgroundColor: semanticColors.onColorHigh,
    color: semanticColors.onColorHighest,
  }),
  neutral: () => ({
    backgroundColor: semanticColors.neutralsLowest,
    color: semanticColors.primary,
  }),
  outline: () => ({
    backgroundColor: semanticColors.secondary,
    color: semanticColors.primary,
  }),
  primary: () => ({
    backgroundColor: semanticColors.primary,
    color: semanticColors.secondary,
  }),
  secondary: () => ({
    backgroundColor: semanticColors.secondary,
    color: semanticColors.primary,
  }),
  transparentNeutral: () => ({
    backgroundColor: colors.transparent,
    color: semanticColors.neutralsHigh,
  }),
  transparentPrimary: () => ({
    backgroundColor: colors.transparent,
    color: semanticColors.primary,
  }),
  transparentWhite: () => ({
    backgroundColor: colors.transparent,
    color: semanticColors.onColorHighest,
  }),
  white: () => ({
    backgroundColor: semanticColors.onColorHighest,
    color: colors.black,
  }),
});

export type ButtonProps = WithAsChild<
  WithStylexArray<
    Omit<HallowElementProps<typeof Element>, "color"> & {
      endIcon?: IconElement;
      isFullWidth?: boolean;
      startIcon?: IconElement;
      textProps?: Omit<TextProps, "children">;
    }
  >
> &
  (
    | {
        color?: null;
        size?: "s" | "m" | "l";
        variant?:
          | "primary"
          | "secondary"
          | "neutral"
          | "lighten"
          | "darken"
          | "white"
          | "outline"
          | "transparentPrimary";
      }
    | {
        color?: null;
        size?: "xs" | "s" | "m" | "l";
        variant?: "transparentNeutral" | "transparentWhite";
      }
    | {
        color?: string;
        size?: "s" | "m" | "l";
        variant?: "color";
      }
  );

export const Button = forwardRef<any, ButtonProps>(
  (
    {
      asChild = false,
      children,
      color = semanticColorsRaw.accentMedium.default,
      disabled = false,
      endIcon,
      isFullWidth = false,
      size = "m",
      startIcon,
      styleXArray = [],
      textProps,
      variant = "primary",
      ...props
    },
    ref,
  ) => {
    const DeterminedChildren = determineChildrenInject({
      afterChildrenInject: [
        endIcon && <Fragment key="endIcon">{endIcon}</Fragment>,
      ],
      beforeChildrenInject: [
        startIcon && <Fragment key="startIcon">{startIcon}</Fragment>,
      ],
      children: children as JSX.Element,
    });

    const DeterminedElement = determineElementFromAsChild({
      asChild,
      hallowElement: Element,
    });

    return (
      <Text
        asChild
        ref={ref}
        {...({ size, type: "button", ...textProps } as TextProps)}
      >
        <DeterminedElement
          {...stylex.props(
            styles.base,
            isFullWidth ? styles.fullWidth : null,
            stylesSize[size],
            stylesVariant[variant](color),
            children && (endIcon || startIcon)
              ? dynamicStylesSizeChildrenIcon.padding({
                  endIcon,
                  size,
                  startIcon,
                })
              : null,
            disabled ? styles.disabled : null,
            disabled ? stylesVariantDisabled[variant](color) : null,
            ...styleXArray,
          )}
          disabled={disabled}
          {...props}
        >
          {DeterminedChildren}
        </DeterminedElement>
      </Text>
    );
  },
);

Button.displayName = "Button";
