import * as stylex from "@stylexjs/stylex";
import { forwardRef } from "react";

import { spacing } from "../../../../../../global/stylex/vars.stylex";
import type { CarouselApi } from "../../../../../hooks";
import type {
  HallowElement,
  HallowElementProps,
  WithStylexArray,
} from "../../../../../types";
import { CarouselItem } from "../CarouselItem";

const cssMediaMinWidthMS = "@media (min-width: 768px)";

const hallowElement: HallowElement = "div";

const styles = stylex.create({
  base: (props: {
    pageOffsetForPosition: CarouselApi["pageOffset"][number];
  }) => ({
    margin: 0,
    minHeight: spacing.t,
    width: {
      [cssMediaMinWidthMS]: `${props.pageOffsetForPosition[1]}px`,
      default: `${props.pageOffsetForPosition[0]}px`,
    },
  }),
});

export type CarouselPageOffsetProps = WithStylexArray<
  HallowElementProps<typeof hallowElement>
> & {
  carouselApi: CarouselApi;
  pageOffsetPosition?: "end" | "start";
};

export const CarouselPageOffset = forwardRef<
  HTMLDivElement,
  CarouselPageOffsetProps
>(
  (
    { carouselApi, pageOffsetPosition = "start", styleXArray, ...props },
    ref,
  ) => (
    <CarouselItem
      carouselApi={carouselApi}
      ref={ref}
      styleXArray={[
        styles.base({
          pageOffsetForPosition:
            carouselApi.pageOffset[pageOffsetPosition === "end" ? 1 : 0],
        }),
        styleXArray,
      ]}
      {...props}
    />
  ),
);

CarouselPageOffset.displayName = "CarouselPageOffset";
