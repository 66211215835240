"use client";

import * as stylex from "@stylexjs/stylex";
import { type ComponentProps, useState } from "react";
import {
  Line,
  LineChart as LineChartRecharts,
  ResponsiveContainer,
  Tooltip as TooltipRecharts,
  XAxis,
  YAxis,
} from "recharts";

import { spacing } from "../../../../../../global/stylex/vars.stylex";
import { useStylex } from "../../../../../hooks";
import { Skeleton } from "../../../_core";
import { Dot } from "../Dot";
import { Tick } from "../Tick";
import { Tooltip } from "../Tooltip";

const styles = stylex.create({
  container: (props: { height: number }) => ({
    height: props.height,
    width: spacing.full,
  }),
});

const stylesLoading = stylex.create({
  container: (props: { height: number }) => ({
    height: props.height,
    width: spacing.full,
  }),
});
export type LineChartProps = ComponentProps<typeof LineChartRecharts> & {
  height?: number;
  loading?: boolean;
};

export const LineChart = ({
  data,
  height = 260,
  loading,
  ...props
}: LineChartProps) => {
  const [activeDotCy, setActiveDotCy] = useState<number>();

  const { getStylexColorVarValue } = useStylex();

  if (loading)
    return <Skeleton styleXArray={[stylesLoading.container({ height })]} />;

  return (
    <div {...stylex.props(styles.container({ height }))}>
      <ResponsiveContainer>
        <LineChartRecharts data={data} {...props}>
          <XAxis
            dataKey="tick"
            interval={0}
            stroke={getStylexColorVarValue({ color: "neutralsLowest" })}
            strokeWidth={2}
            tick={<Tick dataLength={data?.length} />}
            tickLine={false}
          />
          <YAxis
            hide
            dataKey="value"
            domain={[
              (dataMin: number) => dataMin * 0.9,
              (dataMax: number) => (dataMax === 0 ? 1 : dataMax * 1.1),
            ]}
          />
          <Line
            activeDot={false}
            dataKey="value"
            dot={false}
            stroke={getStylexColorVarValue({ color: "accentMedium" })}
            strokeWidth={2}
            type="linear"
          />
          <TooltipRecharts
            content={({ payload }) => (
              <Tooltip
                label={payload[0]?.payload?.label}
                value={payload[0]?.value as string}
              />
            )}
            cursor={{
              stroke: getStylexColorVarValue({ color: "neutralsLowest" }),
              strokeWidth: 2,
            }}
            /** Height of Tooltip is ~54px  */
            /** Determine if space exists to center vertically with the activeDot's cy cooridinate */
            position={{
              y:
                activeDotCy > height - 55
                  ? activeDotCy - 54
                  : activeDotCy < 35
                    ? activeDotCy
                    : activeDotCy - 27,
            }}
          />
          <Line
            activeDot={({ cx, cy }) => {
              setActiveDotCy(cy);
              return <Dot x={cx} y={cy} />;
            }}
            dataKey="value"
            dot={false}
            stroke="none"
          />
        </LineChartRecharts>
      </ResponsiveContainer>
    </div>
  );
};

LineChart.displayName = "LineChart";
