"use client";

import { useABContext } from "@packages/ab";
import type { ReactNode } from "react";

import { LoadingWrapper } from "./LoadingWrapper";

export type LoadingWrapperABProps = {
  children: ReactNode;
};

export const LoadingWrapperAB = ({ children }: LoadingWrapperABProps) => {
  const { isLoading } = useABContext();
  return <LoadingWrapper isLoading={isLoading}>{children}</LoadingWrapper>;
};
