"use client";

import { useTranslations } from "@packages/i18n";
import { Provider, Viewport } from "@radix-ui/react-toast";
import * as stylex from "@stylexjs/stylex";
import type { ComponentProps, Dispatch, SetStateAction } from "react";
import { createContext, useContext, useMemo, useState } from "react";

import {
  numericPercentages,
  numericPixels,
  spacing,
  zIndices,
} from "../../../global/stylex/vars.stylex";

export type ToastProviderProps = ComponentProps<typeof Provider>;

const styles = stylex.create({
  viewport: {
    bottom: spacing.m,
    display: "flex",
    flexDirection: "column",
    height: "auto",
    left: numericPercentages[50],
    maxWidth: "400px",
    placeContent: "center",
    placeItems: "center",
    position: "fixed",
    rowGap: spacing.m,
    transform: `translateX(-50%)`,
    transition: "bottom 250ms ease-out",
    width: `calc(100vh - ${spacing.m})`,
    zIndex: zIndices.toast,
  },
  withTabBar: {
    bottom: `calc(${spacing.xxl} + ${spacing.m})`,
  },
  withPlayer: {
    bottom: `calc(${numericPixels[96]} + ${spacing.m})`,
  },
  withBoth: {
    bottom: `calc(${spacing.xxl} + ${numericPixels[64]} + ${spacing.m})`,
  },
});

const toastContext = createContext<{
  setHasPlayer: Dispatch<SetStateAction<boolean>>;
  setHasTabBar: Dispatch<SetStateAction<boolean>>;
}>({ setHasPlayer: () => {}, setHasTabBar: () => {} });

export const useToastPositioning = () => useContext(toastContext);

export const ToastProvider = ({ children }: ToastProviderProps) => {
  const t = useTranslations();
  const [hasPlayer, setHasPlayer] = useState<boolean>(false);
  const [hasTabBar, setHasTabBar] = useState<boolean>(false);

  const styleXArray = useMemo(() => {
    const styleXArray: stylex.StyleXStyles[] = [styles.viewport];
    if (hasPlayer && hasTabBar) styleXArray.push(styles.withBoth);
    else if (hasPlayer) styleXArray.push(styles.withPlayer);
    else if (hasTabBar) styleXArray.push(styles.withTabBar);

    return styleXArray;
  }, [hasPlayer, hasTabBar]);

  return (
    <toastContext.Provider value={{ setHasPlayer, setHasTabBar }}>
      <Provider label={t("web_toast_section_description")}>
        {children}

        <Viewport label={t("web_toast_section_description")} asChild>
          <ol {...stylex.props(styleXArray)} />
        </Viewport>
      </Provider>
    </toastContext.Provider>
  );
};
