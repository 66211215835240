import * as stylex from "@stylexjs/stylex";
import { forwardRef } from "react";

import {
  fontFamilies,
  fontSizes,
  fontWeights,
  lineHeights,
} from "../../../../../global/stylex/vars.stylex";
import type {
  HallowElement,
  HallowElementProps,
  WithAs,
  WithAsChild,
  WithStylexArray,
} from "../../../../types";
import {
  consoleWarnPackagesUi,
  determineElementFromAsChild,
} from "../../../../utils";

const Element: HallowElement = "span";

const styles = stylex.create({
  base: {
    "overflow-wrap": "break-word",
  },
  bodyS: {
    fontFamily: fontFamilies.sansSerif,
    fontSize: fontSizes.bodyS,
    fontWeight: fontWeights.bodyS,
    lineHeight: lineHeights.bodyS,
  },
  bodyM: {
    fontFamily: fontFamilies.sansSerif,
    fontSize: fontSizes.bodyM,
    fontWeight: fontWeights.bodyM,
    lineHeight: lineHeights.bodyM,
  },
  bodyL: {
    fontFamily: fontFamilies.sansSerif,
    fontSize: fontSizes.bodyL,
    fontWeight: fontWeights.bodyL,
    lineHeight: lineHeights.bodyL,
  },
  bodyXL: {
    fontFamily: fontFamilies.sansSerif,
    fontSize: fontSizes.bodyXL,
    fontWeight: fontWeights.bodyXL,
    lineHeight: lineHeights.bodyXL,
  },
  buttonXS: {
    fontFamily: fontFamilies.sansSerif,
    fontSize: fontSizes.buttonXS,
    fontWeight: fontWeights.buttonXS,
    lineHeight: lineHeights.buttonXS,
  },
  buttonS: {
    fontFamily: fontFamilies.sansSerif,
    fontSize: fontSizes.buttonS,
    fontWeight: fontWeights.buttonS,
    lineHeight: lineHeights.buttonS,
  },
  buttonM: {
    fontFamily: fontFamilies.sansSerif,
    fontSize: fontSizes.buttonM,
    fontWeight: fontWeights.buttonM,
    lineHeight: lineHeights.buttonM,
  },
  buttonL: {
    fontFamily: fontFamilies.sansSerif,
    fontSize: fontSizes.buttonL,
    fontWeight: fontWeights.buttonL,
    lineHeight: lineHeights.buttonL,
  },
  detailS: {
    fontFamily: fontFamilies.sansSerif,
    fontSize: fontSizes.detailS,
    fontWeight: fontWeights.detailS,
    lineHeight: lineHeights.detailS,
  },
  detailM: {
    fontFamily: fontFamilies.sansSerif,
    fontSize: fontSizes.detailM,
    fontWeight: fontWeights.detailM,
    lineHeight: lineHeights.detailM,
  },
  detailL: {
    fontFamily: fontFamilies.sansSerif,
    fontSize: fontSizes.detailL,
    fontWeight: fontWeights.detailL,
    lineHeight: lineHeights.detailL,
  },
  detailXL: {
    fontFamily: fontFamilies.sansSerif,
    fontSize: fontSizes.detailXL,
    fontWeight: fontWeights.detailXL,
    lineHeight: lineHeights.detailXL,
  },
  displayS: {
    fontFamily: fontFamilies.sansSerif,
    fontSize: fontSizes.displayS,
    fontWeight: fontWeights.displayS,
    lineHeight: lineHeights.displayS,
  },
  displayM: {
    fontFamily: fontFamilies.sansSerif,
    fontSize: fontSizes.displayM,
    fontWeight: fontWeights.displayM,
    lineHeight: lineHeights.displayM,
  },
  displayL: {
    fontFamily: fontFamilies.sansSerif,
    fontSize: fontSizes.displayL,
    fontWeight: fontWeights.displayL,
    lineHeight: lineHeights.displayL,
  },
  headlineS: {
    fontFamily: fontFamilies.sansSerif,
    fontSize: fontSizes.headlineS,
    fontWeight: fontWeights.headlineS,
    lineHeight: lineHeights.headlineS,
  },
  headlineM: {
    fontFamily: fontFamilies.sansSerif,
    fontSize: fontSizes.headlineM,
    fontWeight: fontWeights.headlineM,
    lineHeight: lineHeights.headlineM,
  },
  headlineL: {
    fontFamily: fontFamilies.sansSerif,
    fontSize: fontSizes.headlineL,
    fontWeight: fontWeights.headlineL,
    lineHeight: lineHeights.headlineL,
  },
  titleXS: {
    fontFamily: fontFamilies.sansSerif,
    fontSize: fontSizes.titleXS,
    fontWeight: fontWeights.titleXS,
    lineHeight: lineHeights.titleXS,
  },
  titleS: {
    fontFamily: fontFamilies.sansSerif,
    fontSize: fontSizes.titleS,
    fontWeight: fontWeights.titleS,
    lineHeight: lineHeights.titleS,
  },
  titleM: {
    fontFamily: fontFamilies.sansSerif,
    fontSize: fontSizes.titleM,
    fontWeight: fontWeights.titleM,
    lineHeight: lineHeights.titleM,
  },
  titleL: {
    fontFamily: fontFamilies.sansSerif,
    fontSize: fontSizes.titleL,
    fontWeight: fontWeights.titleL,
    lineHeight: lineHeights.titleL,
  },
  titleXL: {
    fontFamily: fontFamilies.sansSerif,
    fontSize: fontSizes.titleXL,
    fontWeight: fontWeights.titleXL,
    lineHeight: lineHeights.titleXL,
  },
  quoteL: {
    fontFamily: fontFamilies.serif,
    fontSize: fontSizes.quoteL,
    fontWeight: fontWeights.quoteL,
    lineHeight: lineHeights.quoteL,
  },
  quoteXL: {
    fontFamily: fontFamilies.serif,
    fontSize: fontSizes.quoteXL,
    fontWeight: fontWeights.quoteXL,
    lineHeight: lineHeights.quoteXL,
  },
  lineClamp: (props: { lineClamp: number }) => ({
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: props.lineClamp,
    display: "-webkit-box",
    overflow: "hidden",
  }),
  overflowEllipsis: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
});

export type TextProps = WithAsChild<
  WithStylexArray<
    WithAs<
      HallowElementProps<typeof Element>,
      | "div"
      | "h1"
      | "h2"
      | "h3"
      | "h4"
      | "h5"
      | "h6"
      | "p"
      | "span"
      | "blockquote"
    >
  >
> &
  (
    | {
        size?: "xs" | "s" | "m" | "l" | "xl";
        type?: "title";
      }
    | {
        size?: "xs" | "s" | "m" | "l";
        type?: "button";
      }
    | {
        size?: "s" | "m" | "l" | "xl";
        type?: "body" | "detail";
      }
    | {
        size?: "s" | "m" | "l";
        type?: "display" | "headline" | "title";
      }
    | {
        size?: "l" | "xl";
        type?: "quote";
      }
  ) & {
    lineClamp?: number;
    overflow?: "ellipsis";
  };

export const Text = forwardRef<any, TextProps>(
  (
    {
      as = Element,
      asChild = false,
      children,
      lineClamp,
      overflow,
      styleXArray = [],
      size = "m",
      type = "body",
      ...props
    },
    ref,
  ) => {
    const DeterminedElement = determineElementFromAsChild({
      asChild,
      hallowElement: as,
    });

    const determinedStyleXStyles = styles[`${type}${size.toUpperCase()}`];
    if (!determinedStyleXStyles)
      consoleWarnPackagesUi({
        heading: "Text.tsx component",
        warning: `No style for size "${size}" and type "${type}" exists.`,
      });

    return (
      <DeterminedElement
        ref={ref}
        {...props}
        {...stylex.props(
          styles.base,
          determinedStyleXStyles,
          lineClamp ? styles.lineClamp({ lineClamp }) : null,
          overflow === "ellipsis" ? styles.overflowEllipsis : null,
          ...styleXArray,
        )}
      >
        {children}
      </DeterminedElement>
    );
  },
);

Text.displayName = "Text";
