import type { IP } from "@packages/sdk";

export type GetIPStrictConsentPermissionsProps = {
  ip: IP;
};

export const getIpIsStrictConsentPermissions = ({
  ip,
}: GetIPStrictConsentPermissionsProps) => {
  if (ip.continent?.toLowerCase() === "europe") return true;

  if (ip.continent_code?.toLowerCase() === "eu") return true;

  if (["brazil", "south africa"].includes(ip.country?.toLowerCase()))
    return true;

  if (
    ip.country?.toLowerCase() === "united states" ||
    ip.country_code === "us"
  ) {
    if (ip.region?.toLowerCase() === "california") return true;
  }

  return false;
};
