"use client";

import { LDProvider } from "launchdarkly-react-client-sdk";
import type { ComponentProps, ReactNode } from "react";

import { ABContextProvider } from "./ABContextProvider";

export type ABProviderProps = Partial<ComponentProps<typeof LDProvider>> & {
  children: ReactNode;
};

export const ABProvider = ({ children, ...props }: ABProviderProps) => (
  <LDProvider
    clientSideID={process.env.NEXT_PUBLIC_LAUNCHDARKLY_KEY}
    reactOptions={{ useCamelCaseFlagKeys: false }}
    timeout={3}
    {...props}
  >
    <ABContextProvider>{children}</ABContextProvider>
  </LDProvider>
);
