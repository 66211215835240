"use client";

import * as stylex from "@stylexjs/stylex";
import type { ForwardRefExoticComponent, RefAttributes } from "react";
import { forwardRef, useState } from "react";
import { useHover } from "react-aria";

import {
  colors,
  fontFamilies,
  fontSizes,
  lineHeights,
  numericPercentages,
  numericPixels,
  radius,
  semanticColors,
  spacing,
  stroke,
} from "../../../../../global/stylex/vars.stylex";
import type {
  HallowElement,
  HallowElementProps,
  WithStylexArray,
} from "../../../../types";
import { Text } from "../../../_base";

const hallowElement: HallowElement = "textarea";

const styles = stylex.create({
  input: {
    background: "none",
    border: "none",
    boxShadow: "none",
    color: semanticColors.primary,
    fontFamily: fontFamilies.sansSerif,
    fontSize: fontSizes.bodyM,
    lineHeight: lineHeights.bodyM,
    marginTop: numericPixels[28],
    outline: "none",
    paddingBottom: spacing.ms,
    paddingHorizontal: numericPixels[18],
    resize: "none",
    width: numericPercentages[100],
  },
  label: {
    backgroundColor: colors.transparent,
    borderColor: semanticColors.neutralsLowest,
    borderRadius: radius.ms,
    borderStyle: "solid",
    borderWidth: stroke.regular,
    display: "block",
    position: "relative",
    transition: "border 200ms ease-in",
    width: numericPercentages[100],
  },
  labelFocused: {
    borderColor: semanticColors.primary,
  },
  labelHovered: {
    borderColor: semanticColors.neutralsVeryLow,
  },
  placeholder: {
    color: semanticColors.neutralsHigh,
    left: numericPixels[18],
    pointerEvents: "none",
    position: "absolute",
    top: numericPixels[22],
    transformOrigin: "0% 50%",
    transition: "all 250ms",
  },
  populated: {
    top: numericPixels[10],
    transform: "scale(.75)",
  },
  wordCount: {
    color: semanticColors.neutralsHigh,
    pointerEvents: "none",
    position: "absolute",
    right: numericPixels[18],
    top: numericPixels[10],
  },
});

export type TextAreaProps = WithStylexArray<
  HallowElementProps<typeof hallowElement>
>;

export const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(
  ({ name, value, placeholder, styleXArray = [], ...props }, ref) => {
    const [focused, setFocused] = useState<boolean>(false);
    const { hoverProps, isHovered: hovered } = useHover({});
    return (
      <label
        onBlur={() => setFocused(false)}
        onFocus={() => setFocused(true)}
        {...stylex.props(
          styles.label,
          focused && styles.labelFocused,
          hovered && !focused && styles.labelHovered,
          styleXArray,
        )}
        {...hoverProps}
      >
        <Text
          size="m"
          type="body"
          styleXArray={[
            styles.placeholder,
            (focused || !!value) && styles.populated,
          ]}
        >
          {placeholder}
        </Text>
        {props.maxLength && (focused || !!value) && (
          <Text size="l" type="detail" styleXArray={[styles.wordCount]}>
            {value?.toString().length}/{props.maxLength}
          </Text>
        )}
        <textarea
          {...props}
          value={value}
          {...stylex.props(styles.input, styleXArray)}
          ref={ref}
        />
      </label>
    );
  },
) as ForwardRefExoticComponent<
  Omit<TextAreaProps, "ref"> & RefAttributes<HTMLTextAreaElement>
>;

TextArea.displayName = "TextArea";
