import type { BasicIconProps as IconProps } from "./Icon";
import { IconWrapper } from "./Icon";

export const PlayIcon = {
  Small: (props: IconProps) => (
    <IconWrapper {...props}>
      <svg>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.894 6.211A2 2 0 006 8v8a2 2 0 002.894 1.789l8-4a2 2 0 000-3.578l-8-4z"
          fill={props.fill}
        />
      </svg>
    </IconWrapper>
  ),
  Medium: (props: IconProps) => (
    <IconWrapper {...props}>
      <svg>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.894 4.211A2 2 0 005 6v12a2 2 0 002.894 1.789l12-6a2 2 0 000-3.578l-12-6z"
          fill={props.fill}
        />
      </svg>
    </IconWrapper>
  ),
  Large: (props: IconProps) => (
    <IconWrapper {...props}>
      <svg>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.971 21.715A2 2 0 013 20V4a2 2 0 012.941-1.765l15 8a2 2 0 010 3.53l-15 8a2 2 0 01-1.97-.05z"
          fill={props.fill}
        />
      </svg>
    </IconWrapper>
  ),
  XL: (props: IconProps) => (
    <IconWrapper size={80} viewBox="0 0 80 80" {...props}>
      <svg>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M24.897 23.597a4 4 0 013.892-.175l26 13a4 4 0 010 7.156l-26 13A4 4 0 0123 53V27a4 4 0 011.897-3.403z"
          fill={props.fill}
        />
      </svg>
    </IconWrapper>
  ),
};

export const PauseIcon = {
  Medium: (props: IconProps) => (
    <IconWrapper {...props}>
      <svg>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.19 4.955C4 5.33 4 5.82 4 6.8v10.4c0 .98 0 1.47.19 1.845.169.329.436.596.766.764C5.33 20 5.82 20 6.8 20h1.356c.98 0 1.47 0 1.844-.19a1.75 1.75 0 00.765-.765c.19-.375.19-.865.19-1.845V6.8c0-.98 0-1.47-.19-1.845A1.75 1.75 0 0010 4.191C9.626 4 9.136 4 8.156 4H6.8c-.98 0-1.47 0-1.844.19a1.75 1.75 0 00-.765.765zm9 0C13 5.33 13 5.82 13 6.8v10.4c0 .98 0 1.47.19 1.844.168.33.436.597.766.765.374.19.864.19 1.844.19l1.4.001c.98 0 1.47 0 1.845-.19a1.75 1.75 0 00.764-.765C20 18.67 20 18.18 20 17.2V6.8c0-.98 0-1.47-.19-1.845a1.75 1.75 0 00-.765-.764C18.67 4 18.18 4 17.2 4h-1.4c-.98 0-1.47 0-1.845.19a1.75 1.75 0 00-.764.765z"
          fill={props.fill}
        />
      </svg>
    </IconWrapper>
  ),
  Large: (props: IconProps) => (
    <IconWrapper {...props}>
      <svg>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.218 3.092C2 3.52 2 4.08 2 5.2v13.6c0 1.12 0 1.68.218 2.108a2 2 0 00.874.874C3.52 22 4.08 22 5.2 22h1.6c1.12 0 1.68 0 2.108-.218a2 2 0 00.874-.874C10 20.48 10 19.92 10 18.8V5.2c0-1.12 0-1.68-.218-2.108a2 2 0 00-.874-.874C8.48 2 7.92 2 6.8 2H5.2c-1.12 0-1.68 0-2.108.218a2 2 0 00-.874.874zm12 0C14 3.52 14 4.08 14 5.2v13.6c0 1.12 0 1.68.218 2.108a2 2 0 00.874.874C15.52 22 16.08 22 17.2 22h1.6c1.12 0 1.68 0 2.108-.218a2 2 0 00.874-.874C22 20.48 22 19.92 22 18.8V5.2c0-1.12 0-1.68-.218-2.108a2 2 0 00-.874-.874C20.48 2 19.92 2 18.8 2h-1.6c-1.12 0-1.68 0-2.108.218a2 2 0 00-.874.874z"
          fill={props.fill}
        />
      </svg>
    </IconWrapper>
  ),
  XL: (props: IconProps) => (
    <IconWrapper size={80} viewBox="0 0 80 80" {...props}>
      <svg>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.436 25.184C23 26.04 23 27.16 23 29.4v21.2c0 2.24 0 3.36.436 4.216a4 4 0 001.748 1.748C26.04 57 27.16 57 29.4 57h1.2c2.24 0 3.36 0 4.216-.436a4 4 0 001.748-1.748C37 53.96 37 52.84 37 50.6V29.4c0-2.24 0-3.36-.436-4.216a4 4 0 00-1.748-1.748C33.96 23 32.84 23 30.6 23h-1.2c-2.24 0-3.36 0-4.216.436a4 4 0 00-1.748 1.748zm20 0C43 26.04 43 27.16 43 29.4v21.2c0 2.24 0 3.36.436 4.216a4 4 0 001.748 1.748C46.04 57 47.16 57 49.4 57h1.2c2.24 0 3.36 0 4.216-.436a4 4 0 001.748-1.748C57 53.96 57 52.84 57 50.6V29.4c0-2.24 0-3.36-.436-4.216a4 4 0 00-1.748-1.748C53.96 23 52.84 23 50.6 23h-1.2c-2.24 0-3.36 0-4.216.436a4 4 0 00-1.748 1.748z"
          fill={props.fill}
        />
      </svg>
    </IconWrapper>
  ),
};

export const StopIcon = {
  Medium: (props: IconProps) => (
    <IconWrapper {...props}>
      <svg>
        <rect x={4} y={4} width={16} height={16} rx={4} fill={props.fill} />
      </svg>
    </IconWrapper>
  ),
  XL: (props: IconProps) => (
    <IconWrapper size={80} viewBox="0 0 80 80" {...props}>
      <svg>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23 35.8c0-4.48 0-6.72.872-8.432a8 8 0 013.496-3.496C29.08 23 31.32 23 35.8 23h8.4c4.48 0 6.72 0 8.432.872a8 8 0 013.496 3.496C57 29.08 57 31.32 57 35.8v8.4c0 4.48 0 6.72-.872 8.432a8 8 0 01-3.496 3.496C50.92 57 48.68 57 44.2 57h-8.4c-4.48 0-6.72 0-8.432-.872a8 8 0 01-3.496-3.496C23 50.92 23 48.68 23 44.2v-8.4z"
          fill={props.fill}
        />
      </svg>
    </IconWrapper>
  ),
};

export const RewindIcon = {
  Small: (props: IconProps) => (
    <IconWrapper {...props}>
      <svg>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.106 6.211A2 2 0 0113 8v1.764l7.106-3.553A2 2 0 0123 8v8a2 2 0 01-2.894 1.789L13 14.236V16a2 2 0 01-2.894 1.789l-8-4a2 2 0 010-3.578l8-4z"
          fill={props.fill}
        />
      </svg>
    </IconWrapper>
  ),
  XL: (props: IconProps) => (
    <IconWrapper size={80} viewBox="0 0 80 80" {...props}>
      <svg>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M57.051 30.299a2 2 0 00-1.945-.088L42 36.764V32a2 2 0 00-2.894-1.789l-16 8a2 2 0 000 3.578l16 8A2 2 0 0042 48v-4.764l13.106 6.553A2 2 0 0058 48V32a2 2 0 00-.949-1.701z"
          fill={props.fill}
        />
      </svg>
    </IconWrapper>
  ),
};

export const FastForwardIcon = {
  Small: (props: IconProps) => (
    <IconWrapper {...props}>
      <svg>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.894 6.211A2 2 0 001 8v8a2 2 0 002.894 1.789L11 14.236V16a2 2 0 002.894 1.789l8-4a2 2 0 000-3.578l-8-4A2 2 0 0011 8v1.764L3.894 6.21z"
          fill={props.fill}
        />
      </svg>
    </IconWrapper>
  ),
  XL: (props: IconProps) => (
    <IconWrapper size={80} viewBox="0 0 80 80" {...props}>
      <svg>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22.948 30.299a2 2 0 011.946-.088L38 36.764V32a2 2 0 012.894-1.789l16 8a2 2 0 010 3.578l-16 8A2 2 0 0138 48v-4.764L24.894 49.79A2 2 0 0122 48V32a2 2 0 01.948-1.701z"
          fill={props.fill}
        />
      </svg>
    </IconWrapper>
  ),
};

export const PreviousIcon = {
  Small: (props: IconProps) => (
    <IconWrapper {...props}>
      <svg>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7 6a1 1 0 011 1v2.764l7.106-3.553A2 2 0 0118 8v8a2 2 0 01-2.894 1.789L8 14.236V17a1 1 0 11-2 0V7a1 1 0 011-1z"
          fill={props.fill}
        />
      </svg>
    </IconWrapper>
  ),
  XL: (props: IconProps) => (
    <IconWrapper size={80} viewBox="0 0 80 80" {...props}>
      <svg>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M47.106 30.211A2 2 0 0150 32v16a2 2 0 01-2.894 1.789L34 43.236V48a2 2 0 11-4 0V32a2 2 0 114 0v4.764l13.106-6.553z"
          fill={props.fill}
        />
      </svg>
    </IconWrapper>
  ),
};

export const SkipIcon = {
  Small: (props: IconProps) => (
    <IconWrapper {...props}>
      <svg>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17 6a1 1 0 00-1 1v2.764L8.894 6.21A2 2 0 006 8v8a2 2 0 002.894 1.789L16 14.236V17a1 1 0 102 0V7a1 1 0 00-1-1z"
          fill={props.fill}
        />
      </svg>
    </IconWrapper>
  ),
  XL: (props: IconProps) => (
    <IconWrapper size={80} viewBox="0 0 80 80" {...props}>
      <svg>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M32.894 30.211A2 2 0 0030 32v16a2 2 0 002.894 1.789L46 43.236V48a2 2 0 104 0V32a2 2 0 10-4 0v4.764L32.894 30.21z"
          fill={props.fill}
        />
      </svg>
    </IconWrapper>
  ),
};

export const Minus10Icon = {
  Medium: (props: IconProps) => (
    <IconWrapper {...props}>
      <svg>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.565 1.097c.269.133.435.383.435.653v1.305A9.001 9.001 0 0112 21 9 9 0 013.75 8.397a1 1 0 111.833.801A7 7 0 1013 5.071V6.25c0 .27-.166.52-.435.653a.964.964 0 01-.863-.01l-4.286-2.25C7.158 4.508 7 4.263 7 4s.158-.508.416-.643l4.286-2.25a.964.964 0 01.863-.01zM15 12a1 1 0 00-1-1h-4a1 1 0 100 2h4a1 1 0 001-1z"
          fill={props.fill}
        />
      </svg>
    </IconWrapper>
  ),
  XL: (props: IconProps) => (
    <IconWrapper size={80} viewBox="0 0 80 80" {...props}>
      <svg>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M41 22a1 1 0 00-1.514-.858l-5 3a1 1 0 000 1.716l5 3A1 1 0 0041 28v-1.965c7.265.513 13 6.57 13 13.965 0 7.732-6.268 14-14 14s-14-6.268-14-14c0-3.1 1.006-5.962 2.71-8.28a1 1 0 10-1.611-1.185A15.933 15.933 0 0024 40c0 8.837 7.163 16 16 16s16-7.163 16-16c0-8.5-6.63-15.453-15-15.97V22zm-6 18c0-.552.373-1 .833-1h8.334c.46 0 .833.448.833 1s-.373 1-.833 1h-8.334c-.46 0-.833-.448-.833-1z"
          fill={props.fill}
        />
      </svg>
    </IconWrapper>
  ),
};

export const Plus10Icon = {
  Medium: (props: IconProps) => (
    <IconWrapper {...props}>
      <svg>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.435 1.097c-.269.133-.435.383-.435.653v1.305A9.001 9.001 0 0012 21a9 9 0 008.25-12.603 1 1 0 00-1.833.801A7 7 0 1111 5.071V6.25c0 .27.166.52.435.653s.598.13.863-.01l4.286-2.25c.258-.135.416-.38.416-.643s-.158-.508-.416-.643l-4.286-2.25a.964.964 0 00-.863-.01zM11 10a1 1 0 112 0v1h1a1 1 0 110 2h-1v1a1 1 0 11-2 0v-1h-1a1 1 0 110-2h1v-1z"
          fill={props.fill}
        />
      </svg>
    </IconWrapper>
  ),
  XL: (props: IconProps) => (
    <IconWrapper size={80} viewBox="0 0 80 80" {...props}>
      <svg>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M39 22a1 1 0 011.514-.858l5 3a1 1 0 010 1.716l-5 3A1 1 0 0139 28v-1.965c-7.265.513-13 6.57-13 13.965 0 7.732 6.268 14 14 14s14-6.268 14-14c0-3.1-1.006-5.962-2.71-8.28a1 1 0 111.611-1.185A15.933 15.933 0 0156 40c0 8.837-7.163 16-16 16s-16-7.163-16-16c0-8.5 6.63-15.453 15-15.97V22zm0 14a1 1 0 112 0v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3h-3a1 1 0 110-2h3v-3z"
          fill={props.fill}
        />
      </svg>
    </IconWrapper>
  ),
};

export const VolumeIcon = {
  Min: (props: IconProps) => (
    <IconWrapper {...props}>
      <svg>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.472 7.118A1 1 0 0111 8v8a1 1 0 01-1.555.832L6.697 15H4a1 1 0 01-1-1v-4a1 1 0 011-1h2.697l2.748-1.832a1 1 0 011.027-.05zM13 10a1 1 0 011-1 3 3 0 110 6 1 1 0 110-2 1 1 0 100-2 1 1 0 01-1-1z"
          fill={props.fill}
        />
      </svg>
    </IconWrapper>
  ),
  Max: (props: IconProps) => (
    <IconWrapper {...props}>
      <svg>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14 5a1 1 0 100 2 5 5 0 010 10 1 1 0 100 2 7 7 0 100-14zm-3 3a1 1 0 00-1.555-.832L6.697 9H4a1 1 0 00-1 1v4a1 1 0 001 1h2.697l2.748 1.832A1 1 0 0011 16V8zm2 2a1 1 0 011-1 3 3 0 110 6 1 1 0 110-2 1 1 0 100-2 1 1 0 01-1-1z"
          fill={props.fill}
        />
      </svg>
    </IconWrapper>
  ),
  Mute: (props: IconProps) => (
    <IconWrapper {...props}>
      <svg>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.472 7.118A1 1 0 0111 8v8a1 1 0 01-1.555.832L6.697 15H4a1 1 0 01-1-1v-4a1 1 0 011-1h2.697l2.748-1.832a1 1 0 011.027-.05zm4.235 2.175a1 1 0 10-1.414 1.414L14.586 12l-1.293 1.293a1 1 0 001.414 1.414L16 13.414l1.293 1.293a1 1 0 001.414-1.414L17.414 12l1.293-1.293a1 1 0 00-1.414-1.414L16 10.586l-1.293-1.293z"
          fill={props.fill}
        />
      </svg>
    </IconWrapper>
  ),
};

export const ShuffleIcon = (props: IconProps) => (
  <IconWrapper size={80} viewBox="0 0 80 80" {...props}>
    <svg>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M47.507 29.13a1 1 0 011.008.012l5 3a1 1 0 010 1.715l-5 3A1 1 0 0147 36v-2h-1.412a5 5 0 00-4.069 2.094l-1.561 2.185-1.23-1.72 1.164-1.628A7 7 0 0145.588 32H47v-2a1 1 0 01.507-.87zM45.587 48a7 7 0 01-5.695-2.931L37.5 41.72 36.271 40l-2.79-3.906A5 5 0 0029.412 34H27a1 1 0 110-2h2.412a7 7 0 015.696 2.931L37.5 38.28 38.729 40l2.79 3.906A5 5 0 0045.588 46H47v-2a1 1 0 011.514-.858l5 3a1 1 0 010 1.715l-5 3A1 1 0 0147 50v-2h-1.412zm-10.545-6.28l1.23 1.72-1.164 1.629A7 7 0 0129.412 48H27a1 1 0 110-2h2.412a5 5 0 004.069-2.094l1.561-2.185z"
        fill={props.fill}
      />
    </svg>
  </IconWrapper>
);

export const RepeatIcon = (props: IconProps) => (
  <IconWrapper size={80} viewBox="0 0 80 80" {...props}>
    <svg>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26 41a1 1 0 01-1-1 9 9 0 019-9h7v-2a1 1 0 011.514-.858l5 3a1 1 0 010 1.716l-5 3A1 1 0 0141 35v-2h-7a7 7 0 00-7 7 1 1 0 01-1 1zm28-2a1 1 0 011 1 9 9 0 01-9 9h-7v2a1 1 0 01-1.514.858l-5-3a1 1 0 010-1.715l5-3A1 1 0 0139 45v2h7a7 7 0 007-7 1 1 0 011-1z"
        fill={props.fill}
      />
    </svg>
  </IconWrapper>
);

export const RepeatOneIcon = (props: IconProps) => (
  <IconWrapper size={80} viewBox="0 0 80 80" {...props}>
    <svg>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25 40a1 1 0 102 0 7 7 0 017-7h7v2a1 1 0 001.514.858l5-3a1 1 0 000-1.715l-5-3A1 1 0 0041 29v2h-7a9 9 0 00-9 9zm30 0a1 1 0 10-2 0 7 7 0 01-7 7h-7v-2a1 1 0 00-1.514-.858l-5 3a1 1 0 000 1.715l5 3A1 1 0 0039 51v-2h7a9 9 0 009-9zm-2-12h2v8h-2v-4.5h-2v-2h.5A1.5 1.5 0 0053 28z"
        fill={props.fill}
      />
    </svg>
  </IconWrapper>
);

export type ProgressIconProps = IconProps & {
  largeArcFlag?: 0 | 1;
  arcX?: number;
  arcY?: number;
};

export const LineItemControlsIcon = {
  Play: (props: IconProps) => (
    <IconWrapper {...props}>
      <svg>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10zM9.49 8.14a1 1 0 01.989-.018l5.5 3a1 1 0 010 1.756l-5.5 3A1 1 0 019 15V9a1 1 0 01.49-.86z"
          fill={props.fill}
        />
      </svg>
    </IconWrapper>
  ),
  /**
   *
   * @param {number} [props.largeArcFlag=0] should be 0 when <= 50%, 1 when > 50%
   * @param {number} [props.arcX=12] x-coordinate of the edge of the circle indicating current progress, defaults to top of circle
   * @param {number} [props.arcY=3] y-coordinate of the edge of the circle indicating current progress, defaults to top of circle
   * @constructor
   */
  Playing: ({
    largeArcFlag = 0,
    arcX = 12,
    arcY = 3,
    ...props
  }: ProgressIconProps) => (
    <IconWrapper {...props}>
      <svg>
        <circle
          stroke={props.fill ?? "currentColor"}
          r="9"
          strokeWidth="2"
          cx="12"
          cy="12"
          fill="none"
          opacity={0.2}
        />
        <path
          stroke={props.fill ?? "currentColor"}
          fill="none"
          strokeWidth="2"
          d={`M12 3A9 9 0 ${largeArcFlag} 1 ${arcX} ${arcY}`}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9 8a1 1 0 00-1 1v6a1 1 0 001 1h1.5a1 1 0 001-1V9a1 1 0 00-1-1H9zm4.5 0a1 1 0 00-1 1v6a1 1 0 001 1H15a1 1 0 001-1V9a1 1 0 00-1-1h-1.5z"
          fill={props.fill}
        />
      </svg>
    </IconWrapper>
  ),
  Paused: ({ largeArcFlag, arcX, arcY, ...props }: ProgressIconProps) => (
    <IconWrapper {...props}>
      <svg>
        <circle
          stroke={props.fill ?? "currentColor"}
          r="9"
          strokeWidth="2"
          cx="12"
          cy="12"
          fill="none"
          opacity={0.2}
        />
        <path
          stroke={props.fill ?? "currentColor"}
          fill="none"
          strokeWidth="2"
          d={`M12 3A9 9 0 ${largeArcFlag} 1 ${arcX} ${arcY}`}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.49 8.14a1 1 0 01.989-.018l5.5 3a1 1 0 010 1.756l-5.5 3A1 1 0 019 15V9a1 1 0 01.49-.86z"
          fill={props.fill}
        />
      </svg>
    </IconWrapper>
  ),
  Replay: (props: IconProps) => (
    <IconWrapper {...props}>
      <svg>
        <g clipPath="url(#clip0_1187_181)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.447.106A1 1 0 0010 1v1.2c-4.564.927-8 4.962-8 9.8 0 5.523 4.477 10 10 10s10-4.477 10-10a9.96 9.96 0 00-1.93-5.907 1 1 0 10-1.613 1.182A8 8 0 1110 4.252V5a1 1 0 001.447.894l4-2a1 1 0 000-1.788l-4-2zm5.26 10.601a1 1 0 00-1.414-1.414L11 13.586l-2.293-2.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l5-5z"
            fill={props.fill}
          />
        </g>
        <defs>
          <clipPath id="clip0_1187_181">
            <path fill="#fff" d="M0 0H24V24H0z" />
          </clipPath>
        </defs>
      </svg>
    </IconWrapper>
  ),
};

export const LengthIcon = (props: IconProps) => (
  <IconWrapper {...props}>
    <svg>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 2c-1.99 0-3.848.583-5.408 1.587a1 1 0 001.083 1.682A7.958 7.958 0 0112 4c1.595 0 3.078.466 4.325 1.269a1 1 0 001.083-1.682A9.958 9.958 0 0012 2zm8.413 4.592a1 1 0 00-1.681 1.083A7.958 7.958 0 0120 12a7.958 7.958 0 01-1.268 4.325 1 1 0 001.681 1.083A9.957 9.957 0 0022 12c0-1.99-.583-3.848-1.587-5.408zM5.269 7.675a1 1 0 10-1.682-1.083A9.958 9.958 0 002 12c0 1.99.583 3.848 1.587 5.408a1 1 0 101.682-1.083A7.958 7.958 0 014 12c0-1.595.466-3.078 1.269-4.325zm2.406 11.056a1 1 0 00-1.083 1.682A9.958 9.958 0 0012 22c1.99 0 3.848-.583 5.408-1.587a1 1 0 10-1.083-1.681A7.958 7.958 0 0112 20a7.958 7.958 0 01-4.325-1.268zM12 7a1 1 0 011 1v3.586l1.707 1.707a1 1 0 01-1.414 1.414L11 12.414V8a1 1 0 011-1z"
        fill={props.fill}
      />
    </svg>
  </IconWrapper>
);

export const Length2Icon = (props: IconProps) => (
  <IconWrapper {...props}>
    <svg>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.003 11.776a8 8 0 018.417-7.765 1 1 0 10.103-1.998A10.145 10.145 0 0012 2h-.035C6.469 2.02 2.018 6.471 2 11.967v.068c.019 5.387 4.296 9.77 9.64 9.959a10.01 10.01 0 00.883-.007 1 1 0 00-.103-1.998 8.103 8.103 0 01-.728.005 8 8 0 01-7.689-8.218zm11.193-8.251a1 1 0 011.345-.436c.313.159.615.334.907.524a1 1 0 11-1.091 1.676 7.983 7.983 0 00-.725-.42 1 1 0 01-.436-1.344zm3.807 2.735a1 1 0 011.384.292c.19.292.365.595.524.906a1 1 0 11-1.78.91 7.977 7.977 0 00-.42-.725 1 1 0 01.293-1.383zm1.933 4.27a1 1 0 011.05.947 10.179 10.179 0 010 1.046 1 1 0 01-1.997-.103 8.103 8.103 0 000-.84 1 1 0 01.947-1.05zm-.46 4.666a1 1 0 01.435 1.345c-.159.313-.334.615-.524.907a1 1 0 11-1.676-1.091c.152-.234.292-.475.42-.725a1 1 0 011.345-.436zm-2.736 3.807a1 1 0 01-.292 1.384c-.292.19-.595.365-.907.524a1 1 0 11-.909-1.78c.25-.128.491-.268.725-.42a1 1 0 011.383.293z"
        fill={props.fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 7a1 1 0 011 1v3.586l1.707 1.707a1 1 0 01-1.414 1.414L11 12.414V8a1 1 0 011-1z"
        fill={props.fill}
      />
    </svg>
  </IconWrapper>
);

export const PlaybackSpeedIcon = (props: IconProps) => (
  <IconWrapper {...props}>
    <svg>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 2c-1.99 0-3.848.583-5.408 1.587a1 1 0 001.083 1.682A7.958 7.958 0 0112 4c1.595 0 3.078.466 4.325 1.269a1 1 0 001.083-1.682A9.958 9.958 0 0012 2zm8.413 4.592a1 1 0 00-1.681 1.083A7.958 7.958 0 0120 12a7.958 7.958 0 01-1.268 4.325 1 1 0 001.681 1.083A9.957 9.957 0 0022 12c0-1.99-.583-3.848-1.587-5.408zM5.269 7.675a1 1 0 10-1.682-1.083A9.958 9.958 0 002 12c0 1.99.583 3.848 1.587 5.408a1 1 0 101.682-1.083A7.958 7.958 0 014 12c0-1.595.466-3.078 1.269-4.325zm2.406 11.056a1 1 0 00-1.083 1.682A9.958 9.958 0 0012 22c1.99 0 3.848-.583 5.408-1.587a1 1 0 10-1.083-1.681A7.958 7.958 0 0112 20a7.958 7.958 0 01-4.325-1.268zm1.8-3.88A1 1 0 019 14v-4a1 1 0 011.447-.894l4 2a1 1 0 010 1.788l-4 2a1 1 0 01-.973-.043z"
        fill={props.fill}
      />
    </svg>
  </IconWrapper>
);

export const PlayAllIcon = (props: IconProps) => (
  <IconWrapper {...props}>
    <svg>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 4a1 1 0 000 2h14a1 1 0 100-2H5zm-.526 5.15a1 1 0 01.973-.044l4 2a1 1 0 010 1.788l-4 2A1 1 0 014 14v-4a1 1 0 01.474-.85zM12 12a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1zm-7 6a1 1 0 100 2h14a1 1 0 100-2H5z"
        fill={props.fill}
      />
    </svg>
  </IconWrapper>
);

export const PlayNextIcon = (props: IconProps) => (
  <IconWrapper {...props}>
    <svg>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.57 7.983L5.516 9.372C4.848 9.822 4 9.282 4 8.407V5.629c0-.875.848-1.415 1.515-.965L7.57 6.053c.647.437.647 1.493 0 1.93zM11 5.628c-.552 0-1 .503-1 1.125s.448 1.126 1 1.126h8c.552 0 1-.504 1-1.126 0-.622-.448-1.125-1-1.125h-8zm-7 8.44c0-.31.224-.562.5-.562h15c.276 0 .5.252.5.563 0 .31-.224.563-.5.563h-15c-.276 0-.5-.252-.5-.563zm.5 6.191c-.276 0-.5.252-.5.563 0 .31.224.563.5.563h15c.276 0 .5-.252.5-.563 0-.311-.224-.563-.5-.563h-15z"
        fill={props.fill}
      />
    </svg>
  </IconWrapper>
);

export const PlayLastIcon = (props: IconProps) => (
  <IconWrapper {...props}>
    <svg>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.5 5.628c-.276 0-.5.251-.5.562 0 .311.224.563.5.563h15c.276 0 .5-.252.5-.563 0-.31-.224-.563-.5-.563h-15zM4 12.943c0-.31.224-.563.5-.563h15c.276 0 .5.252.5.563 0 .311-.224.563-.5.563h-15c-.276 0-.5-.252-.5-.563zm3.57 8.546l-2.055 1.389c-.667.45-1.515-.09-1.515-.965v-2.778c0-.875.848-1.415 1.515-.965L7.57 19.56c.647.437.647 1.493 0 1.93zM11 19.134c-.552 0-1 .503-1 1.125s.448 1.125 1 1.125h8c.552 0 1-.503 1-1.125s-.448-1.125-1-1.125h-8z"
        fill={props.fill}
      />
    </svg>
  </IconWrapper>
);

export const QueueIcon = (props: IconProps) => (
  <IconWrapper {...props}>
    <svg>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.5 7.5a1.5 1.5 0 100-3 1.5 1.5 0 000 3zM9 6a1 1 0 011-1h9a1 1 0 110 2h-9a1 1 0 01-1-1zm1 5a1 1 0 100 2h9a1 1 0 100-2h-9zm-1 7a1 1 0 011-1h9a1 1 0 110 2h-9a1 1 0 01-1-1zm-2-6a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0zm-1.5 7.5a1.5 1.5 0 100-3 1.5 1.5 0 000 3z"
        fill={props.fill}
      />
    </svg>
  </IconWrapper>
);

export const TextOnScreenIcon = (props: IconProps) => (
  <IconWrapper {...props}>
    <svg>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.375 4H5a1 1 0 00-1 1v12.25a1 1 0 001 1h4.375c.261 0 .674.208 1.14.622a5.835 5.835 0 01.68.72l.005.008.001.002a.999.999 0 001.598 0V19.6l.007-.007.033-.042a5.835 5.835 0 01.646-.679c.466-.414.879-.622 1.14-.622h4.374a1 1 0 001.001-1V5a1 1 0 00-1-1h-4.375c-1.004 0-1.938.246-2.625.9-.687-.654-1.62-.9-2.625-.9zM11 7.625c0-.72-.18-1.078-.363-1.262C10.453 6.18 10.096 6 9.375 6H6v10.25h3.375c.607 0 1.163.222 1.625.499V7.625zm2 9.124c.462-.277 1.018-.499 1.625-.499H18V6h-3.375c-.72 0-1.078.18-1.262.363-.183.184-.363.541-.363 1.262v9.124z"
        fill={props.fill}
      />
    </svg>
  </IconWrapper>
);

export const CastIcon = (props: IconProps) => (
  <IconWrapper {...props}>
    <svg>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.864 4c.505 0 .996 0 1.412.034.458.037.997.125 1.54.402a4 4 0 011.748 1.748c.277.543.365 1.082.402 1.54C22 8.14 22 8.63 22 9.136v5.728c0 .504 0 .996-.034 1.412-.037.458-.125.997-.402 1.54a4 4 0 01-1.748 1.748c-.543.277-1.082.365-1.54.402-.416.034-.907.034-1.412.034H13c0-.683-.062-1.352-.181-2H16.8c1.12 0 1.68 0 2.108-.218a2 2 0 00.874-.874C20 16.48 20 15.92 20 14.8V9.2c0-1.12 0-1.68-.218-2.108a2 2 0 00-.874-.874C18.48 6 17.92 6 16.8 6H7.2c-1.12 0-1.68 0-2.108.218a2 2 0 00-.874.874C4.001 7.517 4 8.074 4 9.182A11.063 11.063 0 002 9c0-.457.003-.898.034-1.276.037-.458.125-.997.402-1.54a4 4 0 011.748-1.748c.543-.277 1.082-.365 1.54-.402C6.14 4 6.63 4 7.136 4h9.728zM12 20h-2a8 8 0 00-8-8v-2c5.523 0 10 4.477 10 10zM2 13a7 7 0 017 7H7a5 5 0 00-5-5v-2zm0 3a4 4 0 014 4H2v-4z"
        fill={props.fill}
      />
    </svg>
  </IconWrapper>
);

export const ReplayIcon = (props: IconProps) => (
  <IconWrapper {...props}>
    <svg>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.435 1.097c-.269.133-.435.383-.435.653v1.305A9.001 9.001 0 0012 21a9 9 0 008.25-12.603 1 1 0 00-1.833.801A7 7 0 1111 5.071V6.25c0 .27.166.52.435.653s.598.13.863-.01l4.286-2.25c.258-.135.416-.38.416-.643s-.158-.508-.416-.643l-4.286-2.25a.964.964 0 00-.863-.01z"
        fill={props.fill}
      />
    </svg>
  </IconWrapper>
);
