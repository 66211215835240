"use client";

import { useAnalytics } from "@packages/analytics";
import type { GetDateByRangeProps } from "@packages/sdk";
import { getDateByRange, useRequestCommunitiesAdminGraph } from "@packages/sdk";

import { useErrorNotification } from "../../../../../hooks/useErrorNotification";
import { LineChart } from "../../../../_base";

export type LineChartPrayersCompletedProps = {
  id: number;
  range?: GetDateByRangeProps["range"] | "all";
};

export const LineChartPrayersCompleted = ({
  id,
  range = "all",
}: LineChartPrayersCompletedProps) => {
  const analytics = useAnalytics();

  const { query: queryCommunitiesAdminGraphWeekly } =
    useRequestCommunitiesAdminGraph({
      id,
      startDate: getDateByRange({ range: "weekly" }),
    });

  const { query: queryCommunitiesAdminGraphMonthly } =
    useRequestCommunitiesAdminGraph({
      id,
      startDate: getDateByRange({ range: "monthly" }),
    });

  const { query: queryCommunitiesAdminGraphQuarterly } =
    useRequestCommunitiesAdminGraph({
      id,
      startDate: getDateByRange({ range: "quarterly" }),
    });

  const { query: queryCommunitiesAdminGraphYearly } =
    useRequestCommunitiesAdminGraph({
      id,
      startDate: getDateByRange({ range: "yearly" }),
    });

  const { query: queryCommunitiesAdminGraphAll } =
    useRequestCommunitiesAdminGraph({ id });

  const getQueryCommunitiesAdminGraph = () => {
    switch (range) {
      case "weekly":
        return queryCommunitiesAdminGraphWeekly;
      case "monthly":
        return queryCommunitiesAdminGraphMonthly;
      case "quarterly":
        return queryCommunitiesAdminGraphQuarterly;
      case "yearly":
        return queryCommunitiesAdminGraphYearly;
      default:
        return queryCommunitiesAdminGraphAll;
    }
  };

  const queryCommunitiesAdminGraph = getQueryCommunitiesAdminGraph();

  useErrorNotification({
    isError: queryCommunitiesAdminGraph.isError,
  });

  return (
    <LineChart
      data={queryCommunitiesAdminGraph.data?.data}
      loading={!queryCommunitiesAdminGraph.data}
    />
  );
};

LineChartPrayersCompleted.displayName = "LineChartPrayersCompleted";
