"use client";

import type { Images } from "@packages/sdk";
import * as stylex from "@stylexjs/stylex";
import { forwardRef, useState } from "react";
import { useFocus, useHover, usePress } from "react-aria";

import {
  stylesOpacity,
  stylesOutline,
  stylesScale,
} from "../../../../../../global/stylex/styles";
import {
  dropShadow,
  radius,
  semanticColors,
  shades,
  spacing,
} from "../../../../../../global/stylex/vars.stylex";
import type {
  HallowElement,
  HallowElementProps,
  WithAsChild,
  WithStylexArray,
} from "../../../../../types";
import {
  determineChildrenInject,
  determineElementFromAsChild,
} from "../../../../../utils";
import { HallowImage } from "../../../../blocks";
import { Skeleton, Text } from "../../../_core";
import type { IconElement } from "../../../icons";
import { InlineIcon, SubscriptionIcon } from "../../../icons";

const hallowElement: HallowElement = "div";

const styles = stylex.create({
  descriptionContainer: {
    alignItems: "center",
    display: "flex",
    gap: spacing.xxs,
  },
  determinedElement: {
    display: "flex",
    flexDirection: "column",
    gap: spacing.s,
    minWidth: spacing.none,
    position: "relative",
  },
  infoContainer: {
    display: "flex",
    flexDirection: "column",
    gap: spacing.xs,
  },
  img: {
    borderRadius: radius.s,
    height: spacing.full,
    objectFit: "cover",
    objectPosition: "center",
    objectRepeat: "no-repeat",
    position: "absolute",
    top: 0,
    width: spacing.full,
  },
  imgContainer: {
    paddingBottom: "70%",
    position: "relative",
    width: spacing.full,
  },
  metadataContainer: {
    alignItems: "center",
    color: semanticColors.neutralsMedium,
    display: "flex",
    gap: spacing.xs,
  },
  rank: {
    alignItems: "center",
    backgroundColor: semanticColors.onColorHighest,
    borderRadius: radius.xs,
    boxShadow: `${dropShadow.none} ${dropShadow.s} ${dropShadow.ms} ${dropShadow.none} ${shades.shade20}`,
    color: semanticColors.onColorLowest,
    display: "flex",
    height: spacing.xl,
    justifyContent: "center",
    left: spacing.s,
    position: "absolute",
    top: spacing.s,
    width: spacing.xl,
  },
  textContainer: {
    display: "flex",
    flexDirection: "column",
    gap: spacing.xxs,
  },
  titleContainer: {
    alignItems: "center",
    display: "flex",
    gap: spacing.xxs,
  },
  subscriptionIcon: {
    display: "flex",
    flexShrink: 0,
  },
});

const stylesLoading = stylex.create({
  container: {
    display: "flex",
    flexDirection: "column",
    gap: spacing.s,
  },
  description: {
    height: spacing.m,
    width: spacing.full,
  },
  image: {
    borderRadius: radius.s,
    paddingBottom: "70%",
  },
  textContainer: {
    display: "flex",
    flexDirection: "column",
    gap: spacing.xxs,
    paddingLeft: spacing.xxs,
    paddingRight: spacing.xxs,
  },
  title: {
    height: spacing.l,
    width: "75%",
  },
});

export type ContentMediumProps = WithAsChild<
  WithStylexArray<HallowElementProps<typeof hallowElement>>
> & {
  activeState?: boolean;
  description?: string;
  focusState?: boolean;
  hoverState?: boolean;
  imgSrc?: Images;
  loading?: boolean;
  metadata?: string;
  metadataInlineIcon?: IconElement;
  rank?: number;
  subscription?: boolean;
  title?: string;
};

export const ContentMedium = forwardRef<any, ContentMediumProps>(
  (
    {
      activeState = true,
      asChild = false,
      children,
      description,
      focusState = true,
      hoverState = true,
      imgSrc,
      loading = false,
      metadata,
      metadataInlineIcon,
      rank,
      subscription,
      styleXArray,
      title,
      ...props
    },
    ref,
  ) => {
    const [focus, setFocus] = useState(false);

    const { isPressed: active, pressProps: activeProps } = usePress({});
    const { focusProps } = useFocus({
      onFocusChange: (focus) => setFocus(focus),
    });
    const { isHovered: hover, hoverProps } = useHover({});

    const DeterminedElement = determineElementFromAsChild({
      asChild,
      hallowElement,
    });

    if (loading)
      return (
        <div {...stylex.props(stylesLoading.container)}>
          <Skeleton styleXArray={[stylesLoading.image]} />
          <div {...stylex.props(stylesLoading.textContainer)}>
            <Skeleton styleXArray={[stylesLoading.title]} />
            <Skeleton styleXArray={[stylesLoading.description]} />
          </div>
        </div>
      );

    const DeterminedChildren = determineChildrenInject({
      afterChildrenInject: [
        <div key="imgContainer" {...stylex.props(styles.imgContainer)}>
          <HallowImage
            alt={title}
            src={imgSrc}
            size={"m"}
            {...stylex.props(
              styles.img,
              stylesOutline.base,
              focus && focusState ? stylesOutline.focusControlled : null,
              hover && hoverState ? stylesOutline.hoverControlled : null,
            )}
          />
        </div>,
        <div key="infoContainer" {...stylex.props(styles.infoContainer)}>
          <div {...stylex.props(styles.textContainer)}>
            <div {...stylex.props(styles.titleContainer)}>
              {subscription && (
                <InlineIcon
                  icon={
                    <SubscriptionIcon.Small
                      {...stylex.props(styles.subscriptionIcon)}
                    />
                  }
                />
              )}
              <Text overflow="ellipsis" size="l" type="title">
                {title}
              </Text>
            </div>
            <div {...stylex.props(styles.descriptionContainer)}>
              <Text overflow="ellipsis" size="s" type="body">
                {description}
              </Text>
            </div>
          </div>
          {metadata && (
            <div {...stylex.props(styles.metadataContainer)}>
              {metadataInlineIcon && <InlineIcon icon={metadataInlineIcon} />}
              <Text overflow="ellipsis" size="l" type="detail">
                {metadata}
              </Text>
            </div>
          )}
        </div>,
        rank ? (
          <span key="rank" {...stylex.props(styles.rank)}>
            <Text size="m" type="title">
              {rank}
            </Text>
          </span>
        ) : null,
      ],
      children: children as JSX.Element,
    });

    return (
      <DeterminedElement
        ref={ref}
        {...activeProps}
        {...focusProps}
        {...hoverProps}
        {...props}
        {...stylex.props(
          styles.determinedElement,
          activeState ? stylesOpacity.active : null,
          activeState ? stylesOpacity.base : null,
          activeState && hoverState ? stylesScale.base : null,
          activeState && hoverState ? stylesScale.m : null,
          styleXArray,
        )}
      >
        {DeterminedChildren}
      </DeterminedElement>
    );
  },
);

ContentMedium.displayName = "ContentMedium";
