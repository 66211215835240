"use client";
import * as stylex from "@stylexjs/stylex";

import {
  colors,
  colorsRaw,
  radius,
  semanticColors,
  spacing,
} from "../../../../../global/stylex/vars.stylex";
import type { CSSColorStrict } from "../../../../utils";
import { Avatar, type AvatarProps } from "../Avatar";
import { Skeleton } from "../Skeleton";
import { Text } from "../Text";

const styles = stylex.create({
  avatarFull: {
    height: spacing.full,
    width: spacing.full,
  },
  avatarRelative: {
    position: "relative",
  },
  avatarResponsive: (props: { imgSrc: string }) => ({
    backgroundImage: `url(${props.imgSrc})`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    borderRadius: radius.full,
    display: "flex",
    left: "50%",
    paddingTop: "80%",
    position: "absolute",
    transform: "translateX(-50%)",
    width: "80%",
  }),
  base: {
    display: "flex",
    justifyContent: "center",
  },
  baseColor: (props: { color: CSSColorStrict }) => ({
    backgroundColor: props.color,
  }),
  bottomRightAvatar: {
    bottom: 0,
    right: 0,
  },
  sideAvatar: (props: { color: CSSColorStrict }) => ({
    outline: `2px solid ${props.color}`,
    position: "absolute",
  }),
  topLeftAvatar: {
    left: 0,
    top: 0,
  },
  topLeftAvatarResponsive: (props: { imgSrc: string }) => ({
    backgroundImage: `url(${props.imgSrc})`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    borderRadius: "50%",
    height: "32px",
    left: "12%",
    position: "absolute",
    width: "32px",
  }),
  bottomRightAvatarResponsive: (props: { imgSrc: string }) => ({
    backgroundImage: `url(${props.imgSrc})`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    borderRadius: "50%",
    height: "32px",
    marginTop: "60%",
    position: "absolute",
    right: "12%",
    width: "32px",
  }),
  infoContainer: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    flexShrink: 0,
    gap: spacing.s,
    overflowX: "hidden",
    textAlign: "center",
    width: spacing.full,
  },
  infoContainerResponsive: {
    height: spacing.full,
    paddingTop: { default: "72%", ["@media (min-width: 830px)"]: "80%" },
  },
  labelText: {
    color: colors.white,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    width: spacing.full,
  },
  subLabelText: {
    color: semanticColors.onColorVeryHigh,
  },
  responsiveContainer: {
    alignItems: "center",
    borderRadius: radius.m,
    display: "flex",
    flexDirection: "column",
    gap: spacing.m,
    height: spacing.full,
    padding: `${spacing.l} ${spacing.m}`,
    position: "absolute",
    width: spacing.full,
  },
  responsiveAvatarContainer: {
    display: "flex",
    height: "200px",
    position: "relative",
    width: "100%",
  },
});

const stylesSize = stylex.create({
  xs: {
    borderRadius: 6,
    height: spacing.l,
    padding: spacing.t,
    width: spacing.l,
  },
  m: {
    borderRadius: radius.ms,
    height: spacing.xxxl,
    padding: spacing.xs,
    width: spacing.xxxl,
  },
  xl: {
    borderRadius: "20px",
    height: "116px",
    padding: spacing.ms,
    width: "116px",
  },
  xxl: {
    alignItems: "center",
    borderRadius: radius.m,
    flexDirection: "column",
    gap: spacing.m,
    height: "200px",
    padding: `${spacing.l} ${spacing.m}`,
    width: "160px",
  },
  responsive: {
    borderRadius: radius.m,
    paddingBottom: "125%",
    position: "relative",
    width: spacing.full,
  },
});

export type AvatarCommunityProps = Omit<AvatarProps, "size"> & {
  color?: CSSColorStrict;
  size?: "xs" | "m" | "xl" | "xxl" | "responsive";
  topLeftImgSrc?: string;
  bottomRightImgSrc?: string;
  sublabel?: string;
};

export const AvatarCommunity = ({
  color,
  size = "m",
  topLeftImgSrc,
  bottomRightImgSrc,
  styleXArray = [],
  ...props
}: AvatarCommunityProps) => {
  const communityColor = color ?? (colorsRaw.black as CSSColorStrict);

  const showCommunityInfo = size === "xxl" || size === "responsive";

  const AvatarContent = (
    <>
      {size === "responsive" ? (
        <div {...stylex.props(styles.responsiveAvatarContainer)}>
          <div
            {...stylex.props(styles.avatarResponsive({ imgSrc: props.imgSrc }))}
          />
          <div
            {...stylex.props(
              styles.topLeftAvatarResponsive({ imgSrc: topLeftImgSrc }),
            )}
          />

          <div
            {...stylex.props(
              styles.bottomRightAvatarResponsive({ imgSrc: bottomRightImgSrc }),
            )}
          />
        </div>
      ) : (
        <Avatar
          size={size === "xxl" ? 96 : "full"}
          styleXArray={[
            size === "xxl" ? styles.avatarRelative : styles.avatarFull,
          ]}
          {...props}
        >
          {size === "xxl" && (
            <>
              {topLeftImgSrc && (
                <Avatar
                  size={32}
                  imgSrc={topLeftImgSrc}
                  styleXArray={[
                    styles.sideAvatar({ color: communityColor }),
                    styles.topLeftAvatar,
                  ]}
                />
              )}
              {bottomRightImgSrc && (
                <Avatar
                  size={32}
                  imgSrc={bottomRightImgSrc}
                  styleXArray={[
                    styles.sideAvatar({ color: communityColor }),
                    styles.bottomRightAvatar,
                  ]}
                />
              )}
            </>
          )}
        </Avatar>
      )}
      {showCommunityInfo && (
        <div
          {...stylex.props(
            styles.infoContainer,
            size === "responsive" && styles.infoContainerResponsive,
          )}
        >
          {props.name && (
            <Text type="title" size="m" styleXArray={[styles.labelText]}>
              {props.name}
            </Text>
          )}
          {props.sublabel && (
            <Text type="detail" size="l" styleXArray={[styles.subLabelText]}>
              {props.sublabel}
            </Text>
          )}
        </div>
      )}
    </>
  );

  if (size === "responsive" && props.loading) {
    return (
      <Skeleton
        styleXArray={[styles.base, stylesSize.responsive, styleXArray]}
      />
    );
  }

  return (
    <div
      {...stylex.props(
        styles.baseColor({ color: communityColor }),
        stylesSize[size],
        styleXArray,
      )}
    >
      {size === "responsive" ? (
        <div {...stylex.props(styles.responsiveContainer)}>{AvatarContent}</div>
      ) : (
        AvatarContent
      )}
    </div>
  );
};
