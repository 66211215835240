"use client";

import * as SelectPrimitive from "@radix-ui/react-select";
import * as React from "react";

type SelectLabelProps = React.ComponentPropsWithoutRef<
  typeof SelectPrimitive.Label
>;

export const SelectLabel = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Label>,
  SelectLabelProps
>((props, ref) => <SelectPrimitive.Label ref={ref} {...props} />);

SelectLabel.displayName = "SelectLabel";
