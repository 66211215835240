"use client";

import { DEFAULT_CONSENT_OPTIONS, useConsent } from "@packages/consent";
import { useTranslations } from "@packages/i18n";
import { URL_COOKIE_POLICY } from "@packages/sdk";
import stylex from "@stylexjs/stylex";
import { useRouter } from "next/navigation";

import {
  numericPercentages,
  numericPixels,
  spacing,
} from "../../../../global/stylex/vars.stylex";
import {
  Button,
  CloseIcon,
  IconButton,
  Modal,
  ModalHeader,
  Text,
} from "../../_base";

const styles = stylex.create({
  buttonColumn: {
    alignItems: "flex-end",
    display: "flex",
    flex: "1 1",
    flexDirection: "column",
    paddingRight: spacing.xxxl,
  },
  buttonAcceptAll: {
    display: "flex",
    justifyContent: "center",
    maxWidth: numericPixels[160],
    width: numericPercentages[100],
  },
  buttonManage: {
    display: "flex",
    justifyContent: "center",
    marginBottom: spacing.ms,
    maxWidth: numericPixels[160],
    width: numericPercentages[100],
  },
  closeButton: {
    alignSelf: "flex-end",
  },
  contentContainer: {
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
  },
  modalContent: {
    bottom: spacing.xl,
    display: "flex",
    flexDirection: "column",
    maxWidth: numericPixels[777],
    padding: `${spacing.l} ${spacing.l} ${spacing.xl} ${spacing.xl} `,
    top: "unset",
    transform: "translateX(-50%)",
  },
  descriptionColumn: {
    display: "flex",
    flex: "1 1",
    flexDirection: "column",
    marginBottom: spacing.ml,
    marginLeft: spacing.ml,
  },
  link: {
    textDecoration: "underline",
  },
  title: {
    marginBottom: spacing.ml,
  },
});

export const ConsentModal = () => {
  const router = useRouter();
  const t = useTranslations();

  const {
    consent,
    isConsentModalVisible,
    saveConsent,
    setIsConsentModalVisible,
  } = useConsent();

  const handleOnClose = () => {
    saveConsent({ consent: consent ?? [] });
    setIsConsentModalVisible(false);
  };

  const onClickAcceptAll = () => {
    saveConsent({ consent: DEFAULT_CONSENT_OPTIONS });
    setIsConsentModalVisible(false);
  };

  const onClickManage = () => {
    const newUrl = `${window.location.href.split("#")[0]}#cookie-manager`;
    window.location.href = newUrl;
    router.push(`${window.location.href.split("#")[0]}#cookie-manager`);
  };

  return (
    <Modal
      contentStyleXArray={[styles.modalContent]}
      onOpenChange={setIsConsentModalVisible}
      open={isConsentModalVisible}
    >
      <IconButton
        icon={<CloseIcon />}
        onClick={handleOnClose}
        styleXArray={[styles.closeButton]}
        variant={"white"}
      />
      <div {...stylex.props(styles.contentContainer)}>
        <div {...stylex.props(styles.descriptionColumn)}>
          <ModalHeader>
            <Text size="s" type="headline" styleXArray={[styles.title]}>
              {t("cookie_manager_title")}
            </Text>
          </ModalHeader>
          <Text size="m" type="body">
            {t("cookie_manager_popup_description_no_var")}{" "}
            <a
              href={URL_COOKIE_POLICY}
              rel="noreferrer"
              target="_blank"
              {...stylex.props(styles.link)}
            >
              {t("cookie_manager_policy_link_uppercase")}
            </a>
          </Text>
        </div>
        <div {...stylex.props(styles.buttonColumn)}>
          <Button
            onClick={() => onClickManage()}
            size="m"
            styleXArray={[styles.buttonManage]}
            type="button"
            variant="neutral"
          >
            {t("button_manage")}
          </Button>
          <Button
            onClick={() => onClickAcceptAll()}
            size="m"
            styleXArray={[styles.buttonAcceptAll]}
            type="button"
            variant="color"
          >
            {t("button_accept_all")}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

ConsentModal.displayName = "ConsentModal";
