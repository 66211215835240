"use client";

import type { LSFontFamily } from "@packages/sdk";
import {
  LOCAL_STORAGE_KEY_HLW_FONT,
  LOCAL_STORAGE_KEY_HLW_FONT_STYLE,
  LOCAL_STORAGE_KEY_HLW_LETTER_SPACING,
  LOCAL_STORAGE_KEY_HLW_WORD_SPACING,
  LOCAL_STORAGE_KEY_PREFERRED_THEME,
  useLocalStorageState,
} from "@packages/sdk";
import * as stylex from "@stylexjs/stylex";
import type { ComponentPropsWithoutRef, RefObject } from "react";
import { useEffect, useRef } from "react";

import { fontThemes } from "../../global/stylex/fonts.stylex";
import { Theme } from "../types";
import { ThemeContainer } from "./ThemeContainer";
import { themeContext } from "./themeContext";

const overrides = stylex.create({
  letterSpacing: {
    letterSpacing: "0.2em",
  },
  wordSpacing: {
    wordSpacing: "1em",
  },
});

export type ThemeProviderProps = Omit<
  ComponentPropsWithoutRef<typeof themeContext.Provider>,
  "value"
> & {
  containerProps?: Omit<
    ComponentPropsWithoutRef<typeof ThemeContainer>,
    "theme"
  >;
};

export const ThemeProvider = ({
  children,
  containerProps,
  ...props
}: ThemeProviderProps) => {
  const [theme, setTheme] = useLocalStorageState<Theme>({
    defaultValue: Theme.MATCH,
    key: LOCAL_STORAGE_KEY_PREFERRED_THEME,
  });
  const [fontStyle, setFontStyle] = useLocalStorageState<LSFontFamily>({
    defaultValue: "normal",
    key: LOCAL_STORAGE_KEY_HLW_FONT_STYLE,
  });
  const [letterSpacing, setLetterSpacing] = useLocalStorageState<boolean>({
    defaultValue: false,
    key: LOCAL_STORAGE_KEY_HLW_LETTER_SPACING,
  });
  const [wordSpacing, setWordSpacing] = useLocalStorageState<boolean>({
    defaultValue: false,
    key: LOCAL_STORAGE_KEY_HLW_WORD_SPACING,
  });

  useEffect(() => {
    if (typeof localStorage !== "undefined") {
      const legacyV1Value = localStorage.getItem(LOCAL_STORAGE_KEY_HLW_FONT);
      const currentValue = localStorage.getItem(
        LOCAL_STORAGE_KEY_HLW_FONT_STYLE,
      );

      if (legacyV1Value !== null && currentValue === null) {
        setFontStyle(
          legacyV1Value === "0"
            ? "normal"
            : legacyV1Value === "1"
              ? "serif"
              : "sans_serif",
        );
      }
    }
  }, []);

  const themeContainerRef = useRef() as RefObject<HTMLDivElement>;

  return (
    <themeContext.Provider
      value={{
        setTheme,
        theme,
        themeContainerRef,
        fontStyle,
        setFontStyle,
        letterSpacing,
        setLetterSpacing,
        wordSpacing,
        setWordSpacing,
      }}
      {...props}
    >
      <ThemeContainer
        theme={theme}
        ref={themeContainerRef}
        {...containerProps}
        dynamic={true}
        styleXArray={[
          fontThemes[fontStyle],
          letterSpacing ? overrides.letterSpacing : null,
          wordSpacing ? overrides.wordSpacing : null,
        ]}
      >
        {children}
      </ThemeContainer>
    </themeContext.Provider>
  );
};
