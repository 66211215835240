"use client";

import { useRequestCommunitiesAdminFlaggable } from "@packages/sdk";
import * as stylex from "@stylexjs/stylex";

import {
  colors,
  radius,
  semanticColors,
  spacing,
} from "../../../../../global/stylex/vars.stylex";
import type {
  HallowElement,
  HallowElementProps,
  WithAsChild,
  WithStylexArray,
} from "../../../../types";
import { determineElementFromAsChild } from "../../../../utils";
import { Skeleton } from "../Skeleton";
import { Text } from "../Text";

const hallowElement: HallowElement = "div";

const styles = stylex.create({
  base: {
    borderRadius: radius.full,
  },
  counter: {
    alignItems: "center",
    backgroundColor: semanticColors.accentMedium,
    color: colors.white,
    display: "flex",
    justifyContent: "center",
  },
});

const stylesSize = stylex.create({
  m: {
    height: spacing.l,
    width: spacing.l,
  },
  l: {
    height: 40,
    width: 40,
  },
});

export type ReportedCounterProps = WithAsChild<
  WithStylexArray<Omit<HallowElementProps<typeof hallowElement>, "children">>
> & {
  communityId: number;
  loading?: boolean;
  size?: "m" | "l";
};

export const ReportedCounter = ({
  asChild = false,
  communityId,
  loading,
  size = "m",
  styleXArray,
  ...props
}: ReportedCounterProps) => {
  const DeterminedElement = determineElementFromAsChild({
    asChild,
    hallowElement,
  });

  const { query: communitiesAdminFlaggableQuery } =
    useRequestCommunitiesAdminFlaggable({
      id: communityId,
    });

  if (loading || !communitiesAdminFlaggableQuery.data)
    return <Skeleton styleXArray={[styles.base, stylesSize[size]]} />;

  if (communitiesAdminFlaggableQuery.data?.total === 0) return null;

  return (
    <DeterminedElement
      {...props}
      {...stylex.props(
        styles.base,
        styles.counter,
        stylesSize[size],
        styleXArray,
      )}
    >
      <Text size={size === "m" ? "xs" : "l"} type="title">
        {communitiesAdminFlaggableQuery.data?.total}
      </Text>
    </DeterminedElement>
  );
};

ReportedCounter.displayName = "ReportedCounter";
