"use client";

import * as SelectPrimitive from "@radix-ui/react-select";
import stylex from "@stylexjs/stylex";
import * as React from "react";

import {
  semanticColors,
  spacing,
  stroke,
} from "../../../../../global/stylex/vars.stylex";

const styles = stylex.create({
  separator: {
    backgroundColor: semanticColors.neutralsLow,
    height: stroke.light,
    marginBottom: spacing.xs,
    marginTop: spacing.xs,
  },
});

type SelectSeparatorProps = React.ComponentPropsWithoutRef<
  typeof SelectPrimitive.Separator
>;

export const SelectSeparator = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Separator>,
  SelectSeparatorProps
>((props, ref) => (
  <SelectPrimitive.Separator
    ref={ref}
    {...stylex.props(styles.separator)}
    {...props}
  />
));

SelectSeparator.displayName = "SelectSeparator";
