"use client";

import { useFlags, useLDClient } from "launchdarkly-react-client-sdk";
import { useEffect, useState } from "react";

import { abCache } from "../lib";
import type { HallowABFlag } from "../types";

export type UseFlagProps = { isTargetedValue?: any; key: HallowABFlag };

export const useFlag = ({
  isTargetedValue = "treatment",
  key,
}: UseFlagProps) => {
  const [isLoading, setIsLoading] = useState(true);
  const [value, setValue] = useState(abCache.get(key));

  const flags = useFlags();
  const ldClient = useLDClient();

  const isTargeted = isTargetedValue === value;

  /**
   * useFlags() returns a proxy
   * Analytic events are emitted on GET for the proxy
   * To limit duplicate analytic events, use cache if possible
   */
  useEffect(() => {
    if (!abCache.has(key) && ldClient) {
      const flagLookupValueWithAnalyticsEvent = flags[key];
      abCache.set(key, flagLookupValueWithAnalyticsEvent);
      setValue(flagLookupValueWithAnalyticsEvent);

      /** Guarantee we send any analytic events before potential redirects */
      ldClient.flush(() => {
        setIsLoading(false);
      });
    }
  }, [flags, ldClient]);

  return { isLoading, isTargeted, value };
};
