"use client";

import { MediaElementContext } from "@packages/media";
import { type CloudfrontCookie, signCFUrl } from "@packages/sdk";
import type HLS from "hls.js";
import type { Events } from "hls.js";
import { forwardRef, useCallback, useContext, useEffect, useRef } from "react";

import { useLocalRef } from "../../../../hooks";
import type { HallowElementProps } from "../../../../types";

declare global {
  interface Window {
    Hls: typeof HLS;
  }
}

export type AudioProps = HallowElementProps<"audio"> & {
  hlsUrl: string | null;
  policyCookie: CloudfrontCookie["CloudFront-Policy"];
  keyPairIdCookie: CloudfrontCookie["CloudFront-Key-Pair-Id"];
  signatureCookie: CloudfrontCookie["CloudFront-Signature"];
  regularUrl: string | null;
  type: "background" | "primary";
};

const hlsHasLoaded = () => typeof window !== "undefined" && !!window.Hls;

export const Audio = forwardRef<HTMLAudioElement, AudioProps>(
  (
    {
      children,
      type,
      hlsUrl,
      policyCookie,
      keyPairIdCookie,
      signatureCookie,
      regularUrl,
      ...props
    },
    ref,
  ) => {
    const player = useContext(MediaElementContext);
    const signUrl = useCallback(
      (url) => {
        return signCFUrl(url, [
          {
            "CloudFront-Policy": policyCookie,
            "CloudFront-Key-Pair-Id": keyPairIdCookie,
            "CloudFront-Signature": signatureCookie,
          },
        ]);
      },
      [policyCookie, keyPairIdCookie, signatureCookie],
    );

    const hls = useRef<HLS | null>(
      hlsHasLoaded()
        ? new window.Hls({
            xhrSetup: (xhr, url) => {
              xhr.open("GET", signUrl(url));
            },
          })
        : null,
    );
    const mediaRef = useLocalRef<HTMLAudioElement>(ref);

    useEffect(() => {
      loadMedia();
    }, [hlsUrl, policyCookie, keyPairIdCookie, signatureCookie, regularUrl]);

    const loadMedia = useCallback(() => {
      if (mediaRef.current && (hlsUrl || regularUrl)) {
        if (
          (hlsUrl && mediaRef.current.src.startsWith(hlsUrl)) ||
          (regularUrl && regularUrl === mediaRef.current.src)
        )
          return;

        if (hls.current) {
          hls.current.on<Events.ERROR>(
            "hlsError" as Events.ERROR,
            (_, data) => {
              if (data.fatal) {
                player.onError(data.error);
                hls.current.detachMedia();
                mediaRef.current.setAttribute("src", regularUrl);
                mediaRef.current.load();
              }
            },
          );
        }

        if (hlsUrl && window.Hls?.isSupported()) {
          hls.current?.loadSource(hlsUrl);
          hls.current?.attachMedia(mediaRef.current);
          hls.current?.startLoad();
        } else if (
          hlsUrl &&
          mediaRef.current.canPlayType("application/x-mpegURL") !== ""
        ) {
          // native HLS support
          mediaRef.current.setAttribute("src", signUrl(hlsUrl));
          mediaRef.current.load();
        } else if (regularUrl) {
          // no HLS support
          hls.current?.detachMedia();
          mediaRef.current.setAttribute("src", regularUrl);
          mediaRef.current.load();
        } else {
          hls.current?.detachMedia();
          mediaRef.current.removeAttribute("src");
        }
      }
    }, [hlsUrl, signUrl, regularUrl, mediaRef?.current?.src]);

    return (
      <audio
        key={`hallowAudioElement${type.toUpperCase()}`}
        id={`hallowAudioElement${type.toUpperCase()}`}
        ref={mediaRef}
        {...props}
      >
        {children}
      </audio>
    );
  },
);

Audio.displayName = "Audio";
