"use client";

import { HallowAnalyticsEvent, useAnalytics } from "@packages/analytics";
import type { FlagRecord } from "@packages/sdk";
import * as RadioGroup from "@radix-ui/react-radio-group";
import stylex from "@stylexjs/stylex";

import {
  numericPixels,
  radius,
  semanticColors,
  spacing,
} from "../../../../../global/stylex/vars.stylex";
import { Text } from "../../../_base";

const styles = stylex.create({
  radioGroupItem: {
    backgroundColor: {
      default: "inherit",
      ":is([data-state='checked'])": semanticColors.accentLowest,
    },
    border: {
      default: `${numericPixels[2]} ${semanticColors.onOverlayLow} solid`,
      ":is([data-state='checked'])": `${numericPixels[2]} ${semanticColors.accentLower} solid`,
    },
    borderRadius: radius.ms,
    cursor: "pointer",
    display: "inline-flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    padding: spacing.m,
    textAlign: "left",
    width: spacing.full,
  },
});

const labelStyles = {
  unchecked: {
    color: semanticColors.primary,
  },
  checked: { color: semanticColors.accentMedHigh },
};

const sublabelStyles = {
  unchecked: {
    color: semanticColors.onOverlayMedium,
  },
  checked: { color: semanticColors.accentHigher },
};

export type FlagRecordReasonRadioInputItemProps = {
  label: string;
  sublabel: string;
  value: FlagRecord["reason"];
  checked: boolean;
};

export const FlagRecordReasonRadioInputItem = ({
  label,
  sublabel,
  value,
  checked,
}: FlagRecordReasonRadioInputItemProps) => {
  const analytics = useAnalytics();

  return (
    <RadioGroup.Item
      {...stylex.props(styles.radioGroupItem)}
      value={value}
      onClick={() => {
        analytics.track({
          event: HallowAnalyticsEvent.TappedReportReason,
          properties: {
            reason: value,
            screen_name: "report_reason",
            type: "campaign",
          },
        });
      }}
    >
      <Text
        type="title"
        size="s"
        styleXArray={[checked ? labelStyles.checked : labelStyles.unchecked]}
      >
        {label}
      </Text>

      <Text
        type="detail"
        size="xl"
        styleXArray={[
          checked ? sublabelStyles.checked : sublabelStyles.unchecked,
        ]}
      >
        {sublabel}
      </Text>
    </RadioGroup.Item>
  );
};
