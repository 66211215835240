import * as stylex from "@stylexjs/stylex";
import { defineVars } from "@stylexjs/stylex";

/**
 * Constants
 * styleX requires these to be statically defined in this file
 */

const cssMediaPrefersColorSchemeDark = "@media (prefers-color-scheme: dark)";

/* Colors */

export const colorsRaw = {
  black: "rgba(0,0,0,1.00)",
  blackOLED: "rgba(23,23,23,1.00)",
  green10: "rgba(2,44,19,1.00)",
  green20: "rgba(3,63,27,1.00)",
  green30: "rgba(5,81,35,1.00)",
  green40: "rgba(6,100,44,1.00)",
  green50: "rgba(8,118,52,1.00)",
  green60: "rgba(9,137,60,1.00)",
  green70: "rgba(54,161,96,1.00)",
  green80: "rgba(99,184,133,1.00)",
  green90: "rgba(143,208,169,1.00)",
  green95: "rgba(188,231,206,1.00)",
  green99: "rgba(224,249,234,1.00)",
  grey10: "rgba(39,34,43,1.00)",
  grey20: "rgba(56,50,60,1.00)",
  grey30: "rgba(73,66,78,1.00)",
  grey40: "rgba(89,82,95,1.00)",
  grey50: "rgba(106,98,113,1.00)",
  grey60: "rgba(123,114,130,1.00)",
  grey70: "rgba(147,140,153,1.00)",
  grey80: "rgba(171,164,176,1.00)",
  grey90: "rgba(197,189,201,1.00)",
  grey95: "rgba(220,216,223,1.00)",
  grey99: "rgba(244,242,245,1.00)",
  purple10: "rgba(54,8,94,1.00)",
  purple20: "rgba(74,21,121,1.00)",
  purple30: "rgba(95,35,147,1.00)",
  purple40: "rgba(115,48,174,1.00)",
  purple50: "rgba(136,62,200,1.00)",
  purple60: "rgba(156,75,227,1.00)",
  purple70: "rgba(174,108,233,1.00)",
  purple80: "rgba(193,141,238,1.00)",
  purple90: "rgba(211,174,244,1.00)",
  purple95: "rgba(230,207,249,1.00)",
  purple99: "rgba(248,240,255,1.00)",
  red10: "rgba(77,13,10,1.00)",
  red20: "rgba(106,21,17,1.00)",
  red30: "rgba(134,30,24,1.00)",
  red40: "rgba(163,38,31,1.00)",
  red50: "rgba(191,58,38,1.00)",
  red60: "rgba(220,55,45,1.00)",
  red70: "rgba(228,102,93,1.00)",
  red80: "rgba(234,129,122,1.00)",
  red90: "rgba(241,165,161,1.00)",
  red95: "rgba(248,202,199,1.00)",
  red99: "rgba(255,240,239,1.00)",
  transparent: "transparent",
  white: "rgba(255,255,255,1.00)",
};
export const colors = defineVars(colorsRaw);

export const shadesRaw = {
  shade5: "rgba(0,0,0,0.05)",
  shade10: "rgba(0,0,0,0.10)",
  shade15: "rgba(0,0,0,0.15)",
  shade20: "rgba(0,0,0,0.20)",
  shade25: "rgba(0,0,0,0.25)",
  shade30: "rgba(0,0,0,0.30)",
  shade40: "rgba(0,0,0,0.40)",
  shade50: "rgba(0,0,0,0.50)",
  shade60: "rgba(0,0,0,0.60)",
  shade70: "rgba(0,0,0,0.70)",
  shade80: "rgba(0,0,0,0.80)",
  shade90: "rgba(0,0,0,0.90)",
};
export const shades = defineVars(shadesRaw);

export const tintsRaw = {
  tint5: "rgba(255,255,255,0.05)",
  tint10: "rgba(255,255,255,0.10)",
  tint15: "rgba(255,255,255,0.15)",
  tint20: "rgba(255,255,255,0.20)",
  tint30: "rgba(255,255,255,0.30)",
  tint40: "rgba(255,255,255,0.40)",
  tint50: "rgba(255,255,255,0.50)",
  tint60: "rgba(255,255,255,0.60)",
  tint70: "rgba(255,255,255,0.70)",
  tint80: "rgba(255,255,255,0.80)",
  tint90: "rgba(255,255,255,0.90)",
};
export const tints = defineVars(tintsRaw);

/* Numerics */

export const numericPercentagesRaw = {
  30: "30%",
  40: "40%",
  50: "50%",
  100: "100%",
};
export const numericPercentages = defineVars(numericPercentagesRaw);

export const numericMillisecondsRaw = {
  400: "400ms",
  500: "500ms",
};
export const numericMilliseconds = defineVars(numericMillisecondsRaw);

export const numericPixelsRaw = {
  [-20]: "-20px",
  [-10]: "-10px",
  [-8]: "-8px",
  [-5]: "-5px",
  0: "0px",
  1: "1px",
  2: "2px",
  3: "3px",
  4: "4px",
  5: "5px",
  6: "6px",
  8: "8px",
  10: "10px",
  12: "12px",
  14: "14px",
  15: "15px",
  16: "16px",
  18: "18px",
  20: "20px",
  22: "22px",
  24: "24px",
  25: "25px",
  26: "26px",
  28: "28px",
  30: "30px",
  32: "32px",
  36: "36px",
  40: "40px",
  46: "46px",
  48: "48px",
  56: "56px",
  64: "64px",
  68: "68px",
  72: "72px",
  80: "80px",
  96: "96px",
  100: "100px",
  104: "104px",
  138: "138px",
  160: "160px",
  200: "200px",
  218: "218px",
  240: "240px",
  300: "300px",
  320: "320px",
  360: "360px",
  400: "400px",
  480: "480px",
  560: "560px",
  600: "600px",
  640: "640px",
  720: "720px",
  777: "777px",
  888: "888px",
  984: "984px",
  999: "999px",
};
export const numericPixels = defineVars(numericPixelsRaw);

/* Values */

export const numericValuesRaw = {
  "0.5": "0.5",
  "0.6": "0.6",
  0: "0",
  400: "400",
  600: "600",
  700: "700",
  800: "800",
};
export const numericValues = defineVars(numericValuesRaw);

/* Primitives */

export const dropShadowRaw = {
  none: numericValuesRaw[0],
  xs: numericPixelsRaw[2],
  s: numericPixelsRaw[4],
  ms: numericPixelsRaw[8],
  m: numericPixelsRaw[16],
  ml: numericPixelsRaw[24],
  l: numericPixelsRaw[48],
};
export const dropShadow = defineVars(dropShadowRaw);

export const spacingMasksRaw = {
  xs: numericPixelsRaw[32],
  s: numericPixelsRaw[56],
  m: numericPixelsRaw[80],
  l: numericPixelsRaw[160],
  xl: numericPixelsRaw[240],
};
export const spacingMasks = defineVars(spacingMasksRaw);

export const radiusRaw = {
  none: numericValuesRaw[0],
  xxs: numericPixelsRaw[2],
  xs: numericPixelsRaw[4],
  s: numericPixelsRaw[8],
  ms: numericPixelsRaw[12],
  m: numericPixelsRaw[16],
  l: numericPixelsRaw[24],
  xl: numericPixelsRaw[32],
  full: numericPixelsRaw[999],
};
export const radius = defineVars(radiusRaw);

export const spacingRaw = {
  negativeS: numericPixelsRaw[-8],
  none: numericValuesRaw[0],
  /** 2px of space */
  t: numericPixelsRaw[2],
  /** 4px of space */
  xxs: numericPixelsRaw[4],
  /** 6px of space */
  xs: numericPixelsRaw[6],
  /** 8px of space */
  s: numericPixelsRaw[8],
  /** 12px of space */
  ms: numericPixelsRaw[12],
  /** 16px of space */
  m: numericPixelsRaw[16],
  /** 20px of space */
  ml: numericPixelsRaw[20],
  /** 24px of space */
  l: numericPixelsRaw[24],
  /** 32px of space */
  xl: numericPixelsRaw[32],
  /** 48px of space */
  xxl: numericPixelsRaw[48],
  /** 64px of space */
  xxxl: numericPixelsRaw[64],
  fit: "fit-content",
  full: numericPercentagesRaw[100],
  /** 90 height + 24 spacing at bottom + 24 more at top for good measure */
  mediaPlayerDesktop: numericPixelsRaw[138],
  mediaPlayerMobile: numericPixelsRaw[218], // + bottom menu bar
  sidebar: numericPixelsRaw[320],
  maxReaderWidth: numericPixelsRaw[720],
  maxWidth: numericPixelsRaw[888],
  maxWidthWithPaddingXS: numericPixelsRaw[560],
  maxWidthWithPaddingS: numericPixelsRaw[640],
  maxWidthWithPaddingM: numericPixelsRaw[984],
  maxWidthWithPaddingL: numericPixelsRaw[1070],
  footer: numericPixelsRaw[80],
};
export const spacing = defineVars(spacingRaw);

export const strokeRaw = {
  none: numericPixelsRaw[0],
  light: numericPixelsRaw[1],
  regular: numericPixelsRaw[2],
  heavy: numericPixelsRaw[4],
};
export const stroke = defineVars(strokeRaw);

/* Semantics */

export const constantSemanticColorsRaw = {
  onOverlayDarkBackground: colorsRaw.grey20,
  onOverlayDarkHighest: colorsRaw.white,
  onOverlayDarkMedHigh: colorsRaw.grey99,
  onOverlayDarkMed: colorsRaw.grey80,
  onOverlayDarkMedLow: colorsRaw.grey50,
  onOverlayDarkLow: colorsRaw.grey30,
  onOverlayDarkLowest: colorsRaw.grey20,
  onColorHighest: colorsRaw.white,
  onColorVeryHigh: tintsRaw.tint60,
  onColorHigh: tintsRaw.tint30,
  onColorMedHigh: tintsRaw.tint10,
  onColorMedLow: shadesRaw.shade10,
  onColorLow: shadesRaw.shade30,
  onColorVeryLow: shadesRaw.shade60,
  onColorLowest: colorsRaw.black,
  fixedLightenHighest: tintsRaw.tint90,
  fixedLightenVeryHigh: tintsRaw.tint80,
  fixedLightenHigher: tintsRaw.tint70,
  fixedLightenHigh: tintsRaw.tint60,
  fixedLightenMedHigh: tintsRaw.tint50,
  fixedLightenMed: tintsRaw.tint40,
  fixedLightenMedLow: tintsRaw.tint30,
  fixedLightenLow: tintsRaw.tint20,
  fixedLightenLower: tintsRaw.tint15,
  fixedLightenVeryLow: tintsRaw.tint10,
  fixedLightenLowest: tintsRaw.tint5,
  fixedDarkenHighest: shadesRaw.shade90,
  fixedDarkenVeryHigh: shadesRaw.shade80,
  fixedDarkenHigher: shadesRaw.shade70,
  fixedDarkenHigh: shadesRaw.shade60,
  fixedDarkenMedHigh: shadesRaw.shade50,
  fixedDarkenMed: shadesRaw.shade40,
  fixedDarkenMedLow: shadesRaw.shade30,
  fixedDarkenLow: shadesRaw.shade20,
  fixedDarkenLower: shadesRaw.shade15,
  fixedDarkenVeryLow: shadesRaw.shade10,
  fixedDarkenLowest: shadesRaw.shade5,
};

export const semanticColorsRaw = {
  background: {
    default: colorsRaw.white,
    [cssMediaPrefersColorSchemeDark]: colorsRaw.blackOLED,
  },
  primary: {
    default: colorsRaw.black,
    [cssMediaPrefersColorSchemeDark]: colorsRaw.white,
  },
  secondary: {
    default: colorsRaw.white,
    [cssMediaPrefersColorSchemeDark]: colorsRaw.black,
  },
  neutralsHighest: {
    default: colorsRaw.grey10,
    [cssMediaPrefersColorSchemeDark]: colorsRaw.grey99,
  },
  neutralsVeryHigh: {
    default: colorsRaw.grey20,
    [cssMediaPrefersColorSchemeDark]: colorsRaw.grey95,
  },
  neutralsHigher: {
    default: colorsRaw.grey30,
    [cssMediaPrefersColorSchemeDark]: colorsRaw.grey90,
  },
  neutralsHigh: {
    default: colorsRaw.grey40,
    [cssMediaPrefersColorSchemeDark]: colorsRaw.grey80,
  },
  neutralsMedHigh: {
    default: colorsRaw.grey50,
    [cssMediaPrefersColorSchemeDark]: colorsRaw.grey70,
  },
  neutralsMedium: {
    default: colorsRaw.grey60,
    [cssMediaPrefersColorSchemeDark]: colorsRaw.grey60,
  },
  neutralsMedLow: {
    default: colorsRaw.grey70,
    [cssMediaPrefersColorSchemeDark]: colorsRaw.grey50,
  },
  neutralsLow: {
    default: colorsRaw.grey80,
    [cssMediaPrefersColorSchemeDark]: colorsRaw.grey40,
  },
  neutralsLower: {
    default: colorsRaw.grey90,
    [cssMediaPrefersColorSchemeDark]: colorsRaw.grey30,
  },
  neutralsVeryLow: {
    default: colorsRaw.grey95,
    [cssMediaPrefersColorSchemeDark]: colorsRaw.grey20,
  },
  neutralsLowest: {
    default: colorsRaw.grey99,
    [cssMediaPrefersColorSchemeDark]: colorsRaw.grey10,
  },
  onOverlayBackground: {
    default: colorsRaw.white,
    [cssMediaPrefersColorSchemeDark]: colorsRaw.grey10,
  },
  onOverlayHighest: {
    default: colorsRaw.black,
    [cssMediaPrefersColorSchemeDark]: colorsRaw.white,
  },
  onOverlayMedHigh: {
    default: colorsRaw.grey30,
    [cssMediaPrefersColorSchemeDark]: colorsRaw.grey99,
  },
  onOverlayMedium: {
    default: colorsRaw.grey60,
    [cssMediaPrefersColorSchemeDark]: colorsRaw.grey80,
  },
  onOverlayMedLow: {
    default: colorsRaw.grey90,
    [cssMediaPrefersColorSchemeDark]: colorsRaw.grey50,
  },
  onOverlayLow: {
    default: colorsRaw.grey99,
    [cssMediaPrefersColorSchemeDark]: colorsRaw.grey30,
  },
  onOverlayLowest: {
    default: colorsRaw.white,
    [cssMediaPrefersColorSchemeDark]: colorsRaw.grey20,
  },
  accentHighest: {
    default: colorsRaw.purple10,
    [cssMediaPrefersColorSchemeDark]: colorsRaw.purple99,
  },
  accentVeryHigh: {
    default: colorsRaw.purple20,
    [cssMediaPrefersColorSchemeDark]: colorsRaw.purple95,
  },
  accentHigher: {
    default: colorsRaw.purple30,
    [cssMediaPrefersColorSchemeDark]: colorsRaw.purple90,
  },
  accentHigh: {
    default: colorsRaw.purple40,
    [cssMediaPrefersColorSchemeDark]: colorsRaw.purple80,
  },
  accentMedHigh: {
    default: colorsRaw.purple50,
    [cssMediaPrefersColorSchemeDark]: colorsRaw.purple70,
  },
  accentMedium: {
    default: colorsRaw.purple60,
    [cssMediaPrefersColorSchemeDark]: colorsRaw.purple60,
  },
  accentMedLow: {
    default: colorsRaw.purple70,
    [cssMediaPrefersColorSchemeDark]: colorsRaw.purple50,
  },
  accentLow: {
    default: colorsRaw.purple80,
    [cssMediaPrefersColorSchemeDark]: colorsRaw.purple40,
  },
  accentLower: {
    default: colorsRaw.purple90,
    [cssMediaPrefersColorSchemeDark]: colorsRaw.purple30,
  },
  accentVeryLow: {
    default: colorsRaw.purple95,
    [cssMediaPrefersColorSchemeDark]: colorsRaw.purple20,
  },
  accentLowest: {
    default: colorsRaw.purple99,
    [cssMediaPrefersColorSchemeDark]: colorsRaw.purple10,
  },
  successSuccess: {
    default: colorsRaw.green60,
    [cssMediaPrefersColorSchemeDark]: colorsRaw.green60,
  },
  successOnSuccess: {
    default: colorsRaw.white,
    [cssMediaPrefersColorSchemeDark]: colorsRaw.black,
  },
  successSuccessContainer: {
    default: colorsRaw.green95,
    [cssMediaPrefersColorSchemeDark]: colorsRaw.green20,
  },
  successOnSuccessContainer: {
    default: colorsRaw.green40,
    [cssMediaPrefersColorSchemeDark]: colorsRaw.green80,
  },
  lowerContrastHighest: {
    default: tintsRaw.tint90,
    [cssMediaPrefersColorSchemeDark]: shadesRaw.shade90,
  },
  lowerContrastVeryHigh: {
    default: tintsRaw.tint80,
    [cssMediaPrefersColorSchemeDark]: shadesRaw.shade80,
  },
  lowerContrastHigher: {
    default: tintsRaw.tint70,
    [cssMediaPrefersColorSchemeDark]: shadesRaw.shade70,
  },
  lowerContrastHigh: {
    default: tintsRaw.tint60,
    [cssMediaPrefersColorSchemeDark]: shadesRaw.shade60,
  },
  lowerContrastMedHigh: {
    default: tintsRaw.tint50,
    [cssMediaPrefersColorSchemeDark]: shadesRaw.shade50,
  },
  lowerContrastMed: {
    default: tintsRaw.tint40,
    [cssMediaPrefersColorSchemeDark]: shadesRaw.shade40,
  },
  lowerContrastMedLow: {
    default: tintsRaw.tint30,
    [cssMediaPrefersColorSchemeDark]: shadesRaw.shade30,
  },
  lowerContrastLow: {
    default: tintsRaw.tint20,
    [cssMediaPrefersColorSchemeDark]: shadesRaw.shade20,
  },
  lowerContrastLower: {
    default: tintsRaw.tint15,
    [cssMediaPrefersColorSchemeDark]: shadesRaw.shade15,
  },
  lowerContrastVeryLow: {
    default: tintsRaw.tint10,
    [cssMediaPrefersColorSchemeDark]: shadesRaw.shade10,
  },
  lowerContrastLowest: {
    default: tintsRaw.tint5,
    [cssMediaPrefersColorSchemeDark]: shadesRaw.shade5,
  },
  errorHighest: {
    default: colorsRaw.red10,
    [cssMediaPrefersColorSchemeDark]: colorsRaw.red99,
  },
  errorVeryHigh: {
    default: colorsRaw.red20,
    [cssMediaPrefersColorSchemeDark]: colorsRaw.red95,
  },
  errorHigher: {
    default: colorsRaw.red30,
    [cssMediaPrefersColorSchemeDark]: colorsRaw.red90,
  },
  errorHigh: {
    default: colorsRaw.red40,
    [cssMediaPrefersColorSchemeDark]: colorsRaw.red80,
  },
  errorMedHigh: {
    default: colorsRaw.red50,
    [cssMediaPrefersColorSchemeDark]: colorsRaw.red70,
  },
  errorMedium: {
    default: colorsRaw.red60,
    [cssMediaPrefersColorSchemeDark]: colorsRaw.red60,
  },
  errorMedLow: {
    default: colorsRaw.red70,
    [cssMediaPrefersColorSchemeDark]: colorsRaw.red50,
  },
  errorLow: {
    default: colorsRaw.red80,
    [cssMediaPrefersColorSchemeDark]: colorsRaw.red40,
  },
  errorLower: {
    default: colorsRaw.red90,
    [cssMediaPrefersColorSchemeDark]: colorsRaw.red30,
  },
  errorVeryLow: {
    default: colorsRaw.red95,
    [cssMediaPrefersColorSchemeDark]: colorsRaw.red20,
  },
  errorLowest: {
    default: colorsRaw.red99,
    [cssMediaPrefersColorSchemeDark]: colorsRaw.red10,
  },
  errorError: {
    default: colorsRaw.red60,
    [cssMediaPrefersColorSchemeDark]: colorsRaw.red60,
  },
  errorOnError: {
    default: colorsRaw.white,
    [cssMediaPrefersColorSchemeDark]: colorsRaw.black,
  },
  errorErrorContainer: {
    default: colorsRaw.red95,
    [cssMediaPrefersColorSchemeDark]: colorsRaw.red20,
  },
  errorOnErrorContainer: {
    default: colorsRaw.red40,
    [cssMediaPrefersColorSchemeDark]: colorsRaw.red80,
  },
  higherContrastHighest: {
    default: shadesRaw.shade90,
    [cssMediaPrefersColorSchemeDark]: tintsRaw.tint90,
  },
  higherContrastVeryHigh: {
    default: shadesRaw.shade80,
    [cssMediaPrefersColorSchemeDark]: tintsRaw.tint80,
  },
  higherContrastHigher: {
    default: shadesRaw.shade70,
    [cssMediaPrefersColorSchemeDark]: tintsRaw.tint70,
  },
  higherContrastHigh: {
    default: shadesRaw.shade60,
    [cssMediaPrefersColorSchemeDark]: tintsRaw.tint60,
  },
  higherContrastMedHigh: {
    default: shadesRaw.shade50,
    [cssMediaPrefersColorSchemeDark]: tintsRaw.tint50,
  },
  higherContrastMed: {
    default: shadesRaw.shade40,
    [cssMediaPrefersColorSchemeDark]: tintsRaw.tint40,
  },
  higherContrastMedLow: {
    default: shadesRaw.shade30,
    [cssMediaPrefersColorSchemeDark]: tintsRaw.tint30,
  },
  higherContrastLow: {
    default: shadesRaw.shade20,
    [cssMediaPrefersColorSchemeDark]: tintsRaw.tint20,
  },
  higherContrastLower: {
    default: shadesRaw.shade15,
    [cssMediaPrefersColorSchemeDark]: tintsRaw.tint15,
  },
  higherContrastVeryLow: {
    default: shadesRaw.shade10,
    [cssMediaPrefersColorSchemeDark]: tintsRaw.tint10,
  },
  higherContrastLowest: {
    default: shadesRaw.shade5,
    [cssMediaPrefersColorSchemeDark]: tintsRaw.tint5,
  },
  ...constantSemanticColorsRaw,
};
export const semanticColors = defineVars(semanticColorsRaw);

/* Text */

export const fontFamiliesRaw = {
  sansSerif: "Hallow Moderat, sans-serif",
  serif: "Hallow Moderat Serif, serif",
};
export const fontFamilies = defineVars(fontFamiliesRaw);

export const fontSizesRaw = {
  bodyS: numericPixelsRaw[14],
  bodyM: numericPixelsRaw[16],
  bodyL: numericPixelsRaw[18],
  bodyXL: numericPixelsRaw[24],
  bodyBible: numericPixelsRaw[18],
  buttonXS: numericPixelsRaw[12],
  buttonS: numericPixelsRaw[14],
  buttonM: numericPixelsRaw[16],
  buttonL: numericPixelsRaw[18],
  detailS: numericPixelsRaw[8],
  detailM: numericPixelsRaw[10],
  detailL: numericPixelsRaw[12],
  detailXL: numericPixelsRaw[14],
  displayS: numericPixelsRaw[40],
  displayM: numericPixelsRaw[48],
  displayL: numericPixelsRaw[56],
  headlineS: numericPixelsRaw[24],
  headlineM: numericPixelsRaw[28],
  headlineL: numericPixelsRaw[32],
  quoteL: numericPixelsRaw[18],
  quoteXL: numericPixelsRaw[24],
  titleXS: numericPixelsRaw[12],
  titleS: numericPixelsRaw[14],
  titleM: numericPixelsRaw[16],
  titleL: numericPixelsRaw[20],
  titleXL: numericPixelsRaw[22],
  tosS: numericPixelsRaw[16],
  tosM: numericPixelsRaw[20],
  tosL: numericPixelsRaw[28],
};
export const fontSizes = defineVars(fontSizesRaw);

export const fontWeightsRaw = {
  bodyS: numericValuesRaw[400],
  bodyM: numericValuesRaw[400],
  bodyL: numericValuesRaw[400],
  bodyXL: numericValuesRaw[400],
  bodyBible: numericValuesRaw[400],
  buttonXS: numericValuesRaw[600],
  buttonS: numericValuesRaw[700],
  buttonM: numericValuesRaw[700],
  buttonL: numericValuesRaw[700],
  detailS: numericValuesRaw[400],
  detailM: numericValuesRaw[400],
  detailL: numericValuesRaw[400],
  detailXL: numericValuesRaw[400],
  displayS: numericValuesRaw[800],
  displayM: numericValuesRaw[800],
  displayL: numericValuesRaw[800],
  headlineS: numericValuesRaw[800],
  headlineM: numericValuesRaw[800],
  headlineL: numericValuesRaw[800],
  quoteL: numericValuesRaw[400],
  quoteXL: numericValuesRaw[400],
  titleXS: numericValuesRaw[600],
  titleS: numericValuesRaw[700],
  titleM: numericValuesRaw[700],
  titleL: numericValuesRaw[700],
  titleXL: numericValuesRaw[700],
};
export const fontWeights = defineVars(fontWeightsRaw);

export const lineHeightsRaw = {
  bodyS: numericPixelsRaw[18],
  bodyM: numericPixelsRaw[20],
  bodyL: numericPixelsRaw[22],
  bodyXL: numericPixelsRaw[28],
  bodyBible: numericPixelsRaw[32],
  buttonXS: numericPixelsRaw[16],
  buttonS: numericPixelsRaw[18],
  buttonM: numericPixelsRaw[20],
  buttonL: numericPixelsRaw[22],
  detailS: numericPixelsRaw[10],
  detailM: numericPixelsRaw[14],
  detailL: numericPixelsRaw[16],
  detailXL: numericPixelsRaw[18],
  displayS: numericPixelsRaw[48],
  displayM: numericPixelsRaw[56],
  displayL: numericPixelsRaw[64],
  headlineS: numericPixelsRaw[32],
  headlineM: numericPixelsRaw[32],
  headlineL: numericPixelsRaw[40],
  quoteL: numericPixelsRaw[22],
  quoteXL: numericPixelsRaw[32],
  titleXS: numericPixelsRaw[14],
  titleS: numericPixelsRaw[18],
  titleM: numericPixelsRaw[20],
  titleL: numericPixelsRaw[24],
  titleXL: numericPixelsRaw[26],
};
export const lineHeights = defineVars(lineHeightsRaw);

const dotSizes = {
  2: {
    height: "2px",
    transform: "translateY(0)",
  },
  3: {
    height: "3px",
    transform: "translateY(-0.5px)",
  },
  4: {
    height: "4px",
    transform: "translateY(-1px)",
  },
  5: {
    height: "5px",
    transform: "translateY(-1.5px)",
  },
  6: {
    height: "6px",
    transform: "translateY(-2px)",
  },
  10: {
    height: "10px",
    transform: "translateY(-4px)",
  },
};

const voxDot1 = stylex.keyframes({
  "0%": dotSizes[2],
  "3%": dotSizes[2],
  "8%": dotSizes[5],
  "9%": dotSizes[5],
  "12.4%": dotSizes[3],
  "13.5%": dotSizes[4],
  "16%": dotSizes[2],
  "19%": dotSizes[2],
  "19.5%": dotSizes[4],
  "22%": dotSizes[4],
  "26.5%": dotSizes[2],
  "31.5%": dotSizes[3],
  "34.3%": dotSizes[2],
  "42%": dotSizes[5],
  "46.7%": dotSizes[3],
  "47.3%": dotSizes[4],
  "51%": dotSizes[4],
  "56.25%": dotSizes[2],
  "57.3%": dotSizes[2],
  "64%": dotSizes[5],
  "65.3%": dotSizes[5],
  "68.7%": dotSizes[3],
  "70%": dotSizes[4],
  "72.5%": dotSizes[2],
  "75.3%": dotSizes[2],
  "76%": dotSizes[4],
  "78%": dotSizes[4],
  "82.7%": dotSizes[2],
  "87.7": dotSizes[3],
  "90.5%": dotSizes[5],
  "95.4%": dotSizes[3],
  "97%": dotSizes[4],
  "100%": dotSizes[2],
});

const voxDot2 = stylex.keyframes({
  "0%": dotSizes[2],
  "1.5%": dotSizes[5],
  "3%": dotSizes[5],
  "4%": dotSizes[6],
  "6.8%": dotSizes[4],
  "7.2%": dotSizes[4],
  "8%": dotSizes[5],
  "8.5%": dotSizes[5],
  "10.5%": dotSizes[6],
  "15%": dotSizes[6],
  "20%": dotSizes[3],
  "20.5%": dotSizes[3],
  "21.5%": dotSizes[2],
  "25%": dotSizes[6],
  "26.5%": dotSizes[6],
  "29.8%": dotSizes[4],
  "32%": dotSizes[6],
  "37.8%": dotSizes[6],
  "45.3%": dotSizes[2],
  "48.5%": dotSizes[6],
  "50%": dotSizes[6],
  "53%": dotSizes[4],
  "57.8%": dotSizes[6],
  "61.3%": dotSizes[6],
  "67.8%": dotSizes[2],
  "71%": dotSizes[6],
  "72.5%": dotSizes[6],
  "79.3%": dotSizes[2],
  "83.5%": dotSizes[4],
  "86.3%": dotSizes[6],
  "87%": dotSizes[6],
  "90%": dotSizes[4],
  "92.5%": dotSizes[2],
  "95.3%": dotSizes[6],
  "95.8%": dotSizes[6],
  "100%": dotSizes[2],
});

const voxDot3 = stylex.keyframes({
  "0%": dotSizes[2],
  "1.5%": dotSizes[3],
  "3%": dotSizes[3],
  "7.3%": dotSizes[10],
  "8%": dotSizes[10],
  "12.3%": dotSizes[4],
  "15.5%": dotSizes[3],
  "20%": dotSizes[6],
  "21.5%": dotSizes[4],
  "23.8%": dotSizes[2],
  "31.8%": dotSizes[10],
  "34%": dotSizes[5],
  "35%": dotSizes[5],
  "38.3%": dotSizes[3],
  "39.3%": dotSizes[3],
  "42.3%": dotSizes[6],
  "43%": dotSizes[6],
  "46.8%": dotSizes[2],
  "47.8%": dotSizes[2],
  "50%": dotSizes[3],
  "54.5%": dotSizes[10],
  "60%": dotSizes[3],
  "62%": dotSizes[3],
  "65%": dotSizes[6],
  "68.8%": dotSizes[2],
  "69.5%": dotSizes[2],
  "71.8%": dotSizes[4],
  "72.5%": dotSizes[4],
  "77%": dotSizes[10],
  "81.5%": dotSizes[6],
  "88.8%": dotSizes[3],
  "93%": dotSizes[6],
  "96.8%": dotSizes[2],
  "98%": dotSizes[3],
  "100%": dotSizes[2],
});

const voxDot4 = stylex.keyframes({
  "0%": dotSizes[2],
  "1.5%": dotSizes[5],
  "3%": dotSizes[5],
  "4.5%": dotSizes[6],
  "7.3%": dotSizes[5],
  "9.8%": dotSizes[5],
  "11.3%": dotSizes[6],
  "13.3%": dotSizes[6],
  "17%": dotSizes[5],
  "19.3%": dotSizes[4],
  "20.8%": dotSizes[3],
  "21.5%": dotSizes[3],
  "23%": dotSizes[2],
  "26.8%": dotSizes[6],
  "30%": dotSizes[5],
  "32.3%": dotSizes[6],
  "34.8%": dotSizes[6],
  "37%": dotSizes[5],
  "38%": dotSizes[5],
  "45.5%": dotSizes[2],
  "50.3%": dotSizes[6],
  "52.5%": dotSizes[5],
  "53.8%": dotSizes[5],
  "56.5%": dotSizes[6],
  "58%": dotSizes[6],
  "59.3%": dotSizes[5],
  "62.5%": dotSizes[5],
  "64.8%": dotSizes[4],
  "66.3%": dotSizes[4],
  "68.5%": dotSizes[2],
  "69.3%": dotSizes[2],
  "72.3%": dotSizes[6],
  "73.8%": dotSizes[6],
  "76.8%": dotSizes[4],
  "79.8%": dotSizes[3],
  "84%": dotSizes[10],
  "87%": dotSizes[5],
  "89.8%": dotSizes[4],
  "93%": dotSizes[3],
  "94.5%": dotSizes[2],
  "97%": dotSizes[6],
  "100%": dotSizes[2],
});

const voxDot5 = stylex.keyframes({
  "0%": dotSizes[2],
  "1.5%": dotSizes[3],
  "6%": dotSizes[4],
  "8%": dotSizes[2],
  "10.5%": dotSizes[2],
  "13%": dotSizes[4],
  "16.3%": dotSizes[2],
  "20.8%": dotSizes[6],
  "22.3%": dotSizes[4],
  "23%": dotSizes[4],
  "24.5%": dotSizes[2],
  "26.5%": dotSizes[2],
  "27.8%": dotSizes[3],
  "32.5%": dotSizes[2],
  "34%": dotSizes[2],
  "34.8%": dotSizes[3],
  "37.8%": dotSizes[3],
  "40%": dotSizes[2],
  "41%": dotSizes[2],
  "44%": dotSizes[6],
  "44.8%": dotSizes[6],
  "48.5%": dotSizes[2],
  "53.3%": dotSizes[3],
  "54.8%": dotSizes[2],
  "57.8%": dotSizes[2],
  "59.5%": dotSizes[3],
  "61%": dotSizes[3],
  "62%": dotSizes[2],
  "63.5%": dotSizes[2],
  "66.3%": dotSizes[6],
  "70%": dotSizes[2],
  "73%": dotSizes[3],
  "73.8%": dotSizes[3],
  "77.3%": dotSizes[6],
  "81%": dotSizes[5],
  "85.3%": dotSizes[5],
  "88.3%": dotSizes[2],
  "92.5%": dotSizes[6],
  "96.5%": dotSizes[2],
  "98%": dotSizes[3],
  "100%": dotSizes[2],
});

export const voxAnimation = stylex.defineVars({
  voxDot1,
  voxDot2,
  voxDot3,
  voxDot4,
  voxDot5,
});

export const zIndicesRaw = {
  padTitle: 1,
  padReportedOverlay: 1,
  padReportedToast: 2,
  absolute: 5,
  mask: 10,
  maskOver: 11,
  menuBar: 50,
  trivia: 60,
  closeOverTrivia: 61,
  transcript: 69,
  modalOverTranscript: 70,
  mediaPlayer: 70,
  overlayOverMediaPlayer: 71,
  modalOverMediaPlayer: 72,
  queueContent: 73,
  modalOverQueue: 74,
  modalOverlay: 90,
  modalContent: 91,
  overlayOverModal: 92,
  modalOverModal: 92,
  dropdownOverModal: 95,
  scrollbar: 998,
  toast: 999,
  loading: 1000,
};

export const zIndices = defineVars(zIndicesRaw);

export const gradientsColorsStartRaw = {
  0: "#807584",
  1: "#C64700",
  2: "#8B7939",
  3: "#27752A",
  4: "#097C59",
  5: "#0E658A",
  6: "#664AB5",
  7: "#A5256A",
  8: "#13827C",
  9: "#008171",
  10: "#362774",
  11: "rgba(0, 0, 0, 0.20)",
};

export const gradientsColorsStart = defineVars(gradientsColorsStartRaw);

export const gradientsColorsEndRaw = {
  0: "#1A1A1A",
  1: "#B00",
  2: "#404A17",
  3: "#044C32",
  4: "#001856",
  5: "#300390",
  6: "#7C0390",
  7: "#D90068",
  8: "#AA2626",
  9: "#7400BB",
  10: "#D90068",
  11: "#871506",
};

export const gradientsColorsEnd = defineVars(gradientsColorsEndRaw);

export const gradientsRaw = {
  0: "linear-gradient(179deg, #807584 0.44%, #1A1A1A 82.51%)",
  1: "linear-gradient(180deg, #C64700 0%, #B00 81.16%)",
  2: "linear-gradient(180deg, #8B7939 2.46%, #404A17 75.92%)",
  3: "linear-gradient(180deg, #27752A 0%, #044C32 75%)",
  4: "linear-gradient(180deg, #097C59 0%, #001856 78.33%)",
  5: "linear-gradient(180deg, #0E658A 0%, #300390 76.91%)",
  6: "linear-gradient(180deg, #664AB5 0%, #7C0390 76.91%)",
  7: "linear-gradient(180deg, #A5256A 0%, #D90068 77.4%)",
  8: "linear-gradient(180deg, #13827C 0%, #AA2626 81.59%)",
  9: "linear-gradient(180deg, #008171 10.53%, #7400BB 89.53%)",
  10: "linear-gradient(180deg, #362774 0%, #D90068 83.07%)",
  11: "linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(180deg, #330093 0%, #871506 82.14%)",
};

export const gradients = defineVars(gradientsRaw);
