"use client";

import { HallowAnalyticsEvent, useAnalytics } from "@packages/analytics";
import { type QueueContextShape } from "@packages/sdk";
import { snakeCase } from "@packages/sdk";
import type { MutableRefObject } from "react";
import { useCallback, useEffect, useRef } from "react";

import { getContentId } from "../lib";
import type { MediaElementsRef } from "../types";
import { useCurrentQueueItem } from "./useCurrentQueueItem";

export type UseMediaAnalyticsProps = {
  mediaRef?: MutableRefObject<MediaElementsRef>;
  backgroundTitle?: string;
};

type SimpleAnalyticsFunction = () => Promise<void>;

type MediaAnalytics = {
  audioStartDelayed: SimpleAnalyticsFunction;
  audioStartedRef: MutableRefObject<SimpleAnalyticsFunction>;
  draggedTimeSlider: SimpleAnalyticsFunction;
  loopChanged: (loop: "none" | "one" | "all") => Promise<void>;
  maximizedPlayer: SimpleAnalyticsFunction;
  minimizedPlayer: SimpleAnalyticsFunction;
  playerBeganTrackRef: MutableRefObject<SimpleAnalyticsFunction>;
  playerDestroyed: SimpleAnalyticsFunction;
  playerEndedTrackRef: MutableRefObject<
    (cause: "complete" | "error" | "cancel" | "timer") => Promise<void>
  >;
  playerInitialized: SimpleAnalyticsFunction;
  tappedFFWRef: MutableRefObject<SimpleAnalyticsFunction>;
  tappedPause: (player_state: "maximized" | "minimized") => Promise<void>;
  tappedPlay: (player_state: "maximized" | "minimized") => Promise<void>;
  tappedRWRef: MutableRefObject<SimpleAnalyticsFunction>;
  tappedResumeSessionRef: MutableRefObject<SimpleAnalyticsFunction>;
  tappedSkipBackRef: MutableRefObject<SimpleAnalyticsFunction>;
  tappedSkipForwardRef: MutableRefObject<SimpleAnalyticsFunction>;
  tappedSkipIntroRef: MutableRefObject<SimpleAnalyticsFunction>;
  timerEndedRef: MutableRefObject<(timerLength: string) => Promise<void>>;
};

export const useMediaAnalytics = ({
  mediaRef,
  backgroundTitle,
}: UseMediaAnalyticsProps = {}): MediaAnalytics => {
  const analytics = useAnalytics();
  const { currentItem, queue } = useCurrentQueueItem();
  const playerBeganTrackRef = useRef<SimpleAnalyticsFunction>(() =>
    Promise.resolve(),
  );
  const playerEndedTrackRef = useRef<
    (cause: "complete" | "error" | "cancel" | "timer") => Promise<void>
  >(() => Promise.resolve());
  const audioStartedRef = useRef<SimpleAnalyticsFunction>(() =>
    Promise.resolve(),
  );
  const tappedFFWRef = useRef<SimpleAnalyticsFunction>(() => Promise.resolve());
  const tappedRWRef = useRef<SimpleAnalyticsFunction>(() => Promise.resolve());
  const tappedSkipForwardRef = useRef<SimpleAnalyticsFunction>(() =>
    Promise.resolve(),
  );
  const tappedSkipBackRef = useRef<SimpleAnalyticsFunction>(() =>
    Promise.resolve(),
  );
  const tappedSkipIntroRef = useRef<SimpleAnalyticsFunction>(() =>
    Promise.resolve(),
  );
  const tappedResumeSessionRef = useRef<SimpleAnalyticsFunction>(() =>
    Promise.resolve(),
  );
  const timerEndedRef = useRef<(timeLength: string) => Promise<void>>(() =>
    Promise.resolve(),
  );

  const playerEndedTrack = useCallback(
    (cause: "complete" | "error" | "cancel" | "timer") =>
      currentItem
        ? analytics.track({
            event: HallowAnalyticsEvent.PlayerEndedTrack,
            properties: {
              cause,
              audio: currentItem.selected_audio.id,
              guide: currentItem.selected_audio.guide_id,
              length: snakeCase(currentItem.selected_audio.length),
              loop: queue.repeating,
              online: true,
              percent_complete: mediaRef?.current
                ? mediaRef.current.currentTime / mediaRef.current.duration
                : undefined,
              shuffle: queue.shuffled,
              speed: mediaRef?.current ? mediaRef.current.playbackRate : 1,
              content_id: getContentId(currentItem),
              content_type: currentItem.type,
            },
          })
        : null,
    [currentItem, analytics],
  );

  useEffect(() => {
    playerEndedTrackRef.current = playerEndedTrack;
  }, [playerEndedTrack]);

  const playerInitialized = () =>
    analytics.track({
      event: HallowAnalyticsEvent.PlayerInitialized,
    });

  const playerDestroyed = () =>
    analytics.track({
      event: HallowAnalyticsEvent.PlayerDestroyed,
    });

  const audioStarted = useCallback(
    () =>
      currentItem
        ? analytics.track({
            event: HallowAnalyticsEvent.AudioStarted,
            properties: {
              content_id: getContentId(currentItem),
              content_type: currentItem.type,
            },
          })
        : null,
    [currentItem],
  );

  useEffect(() => {
    audioStartedRef.current = audioStarted;
  }, [audioStarted]);

  const audioStartDelayed = () =>
    analytics.track({
      event: HallowAnalyticsEvent.AudioStartDelayed,
    });

  const draggedTimeSlider = () =>
    analytics.track({
      event: HallowAnalyticsEvent.DraggedTimeSlider,
    });

  const tappedFastForward = useCallback(
    () =>
      currentItem
        ? analytics.track({
            event: HallowAnalyticsEvent.TappedFastForward,
            properties: { id: currentItem.selected_audio.id },
          })
        : null,
    [currentItem],
  );

  useEffect(() => {
    tappedFFWRef.current = tappedFastForward;
  }, [tappedFastForward]);

  const tappedRewind = useCallback(
    () =>
      currentItem
        ? analytics.track({
            event: HallowAnalyticsEvent.TappedRewind,
            properties: { id: currentItem.selected_audio.id },
          })
        : null,
    [currentItem],
  );

  useEffect(() => {
    tappedRWRef.current = tappedRewind;
  }, [tappedRewind]);

  const tappedSkipForward = useCallback(
    () =>
      currentItem
        ? analytics.track({
            event: HallowAnalyticsEvent.TappedSkipForward,
            properties: {
              id: currentItem.selected_audio.id,
            },
          })
        : null,
    [currentItem],
  );

  useEffect(() => {
    tappedSkipForwardRef.current = tappedSkipForward;
  }, [tappedSkipForward]);

  const tappedSkipBack = useCallback(
    () =>
      currentItem
        ? analytics.track({
            event: HallowAnalyticsEvent.TappedSkipBack,
            properties: {
              id: currentItem.selected_audio.id,
            },
          })
        : null,
    [currentItem],
  );

  useEffect(() => {
    tappedSkipBackRef.current = tappedSkipBack;
  }, [tappedSkipBack]);

  const loopChanged = (loop: QueueContextShape["repeating"]) =>
    analytics.track({
      event: HallowAnalyticsEvent.LoopChanged,
      properties: {
        loop,
      },
    });

  const tappedSkipIntro = useCallback(
    () =>
      currentItem
        ? analytics.track({
            event: HallowAnalyticsEvent.TappedSkipIntro,
            properties: {
              audio: currentItem.selected_audio.id,
              collection:
                currentItem?.type === "prayer"
                  ? currentItem.collection.id
                  : null,
              length: snakeCase(currentItem.selected_audio.length),
              content_id: getContentId(currentItem),
              content_type: currentItem.type,
            },
          })
        : null,
    [currentItem],
  );

  useEffect(() => {
    tappedSkipIntroRef.current = tappedSkipIntro;
  }, [tappedSkipIntro]);

  const tappedResumeSession = useCallback(
    () =>
      currentItem
        ? analytics.track({
            event: HallowAnalyticsEvent.TappedResumeSession,
            properties: {
              audio: currentItem.selected_audio.id,
              resumed_at: currentItem.selected_audio.resumes_at,
              content_id: getContentId(currentItem),
              content_type: currentItem.type,
            },
          })
        : null,
    [currentItem],
  );

  useEffect(() => {
    tappedResumeSessionRef.current = tappedResumeSession;
  }, [tappedResumeSession]);

  const playerBeganTrack = useCallback(
    () =>
      currentItem && queue
        ? analytics.track({
            event: HallowAnalyticsEvent.PlayerBeganTrack,
            properties: {
              audio: currentItem.selected_audio.id,
              background_music: backgroundTitle
                ? snakeCase(backgroundTitle)
                : "none",
              collection:
                currentItem.type === "prayer"
                  ? currentItem.collection.id
                  : null,
              downloaded: false,
              guide: currentItem.selected_audio.guide_id,
              length: snakeCase(currentItem.selected_audio.length),
              loop: queue.repeating,
              online: true,
              shuffle: queue.shuffled,
              speed: mediaRef?.current ? mediaRef.current.playbackRate : 1,
              content_id: getContentId(currentItem),
              content_type: currentItem.type,
            },
          })
        : null,
    [currentItem, backgroundTitle, queue],
  );

  useEffect(() => {
    playerBeganTrackRef.current = playerBeganTrack;
  }, [playerBeganTrack]);

  const maximizedPlayer = () =>
    analytics.track({
      event: HallowAnalyticsEvent.MaximizedPlayer,
    });

  const minimizedPlayer = () =>
    analytics.track({
      event: HallowAnalyticsEvent.MinimizedPlayer,
    });

  const tappedPause = (player_state: "maximized" | "minimized") =>
    analytics.track({
      event: HallowAnalyticsEvent.TappedPause,
      properties: { player_state },
    });

  const tappedPlay = (player_state: "maximized" | "minimized") =>
    analytics.track({
      event: HallowAnalyticsEvent.TappedPlay,
      properties: { player_state },
    });

  const timerEnded = useCallback(
    (timerLength: string) =>
      currentItem && queue
        ? analytics.track({
            event: HallowAnalyticsEvent.TimerEnded,
            properties: {
              speed: mediaRef?.current ? mediaRef.current.playbackRate : 1,
              content_id: getContentId(currentItem),
              content_type: currentItem.type,
              loop: queue.repeating,
              length: snakeCase(timerLength),
            },
          })
        : null,
    [currentItem, queue],
  );

  useEffect(() => {
    timerEndedRef.current = timerEnded;
  }, [timerEnded]);

  return {
    audioStartDelayed,
    audioStartedRef,
    draggedTimeSlider,
    loopChanged,
    maximizedPlayer,
    minimizedPlayer,
    playerBeganTrackRef,
    playerDestroyed,
    playerEndedTrackRef,
    playerInitialized,
    tappedFFWRef,
    tappedPause,
    tappedPlay,
    tappedRWRef,
    tappedResumeSessionRef,
    tappedSkipBackRef,
    tappedSkipForwardRef,
    tappedSkipIntroRef,
    timerEndedRef,
  };
};
