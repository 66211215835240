"use client";

import * as stylex from "@stylexjs/stylex";
import type { RefObject } from "react";
import { useRef, useState } from "react";
import type {
  DragEndEvent,
  DragOptions,
  DragPreviewRenderer,
} from "react-aria";
import { useDrag } from "react-aria";

export type UseQueueItemOptions = Pick<
  DragOptions,
  "onDragMove" | "onDragEnd" | "onDragStart" | "getItems"
>;

const dragButton = stylex.create({
  default: {
    background: "transparent",
    border: 0,
    cursor: "grab",
    padding: 0,
  },
  dragging: {
    cursor: "grabbing",
  },
});

export type UseQueueItemResult = {
  containerProps: any;
  childProps: any;
  handleProps: any;
  previewRef: RefObject<DragPreviewRenderer>;
};

export const useQueueItem = ({
  onDragMove,
  onDragEnd,
  onDragStart,
  getItems,
}: UseQueueItemOptions): UseQueueItemResult => {
  const previewRef = useRef<DragPreviewRenderer>(null);
  const [enableDrag, setEnableDrag] = useState<boolean>(false);

  const handleDragEnd = (e: DragEndEvent) => {
    setEnableDrag(false);
    if (onDragEnd) onDragEnd(e);
  };

  const { dragProps, dragButtonProps, isDragging } = useDrag({
    onDragStart,
    onDragEnd: handleDragEnd,
    onDragMove,
    hasDragButton: true,
    getAllowedDropOperations: () => ["move"],
    getItems,
    preview: previewRef,
  });

  return {
    containerProps: { ...dragProps, draggable: enableDrag },
    childProps: { draggable: false },
    handleProps: {
      ...stylex.props(
        dragButton.default,
        isDragging ? dragButton.dragging : null,
      ),
      ...dragButtonProps,
      onMouseDown: () => setEnableDrag(true),
      onMouseUp: () => setEnableDrag(false),
    },
    previewRef,
  };
};
