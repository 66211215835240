"use client";

import * as SelectPrimitive from "@radix-ui/react-select";
import stylex from "@stylexjs/stylex";
import * as React from "react";

import { spacing } from "../../../../../global/stylex/vars.stylex";
import { ChevronUpIcon } from "../../../_base/icons";

const styles = stylex.create({
  scrollButton: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    paddingBottom: spacing.s,
    paddingTop: spacing.s,
  },
});

type SelectScrollUpButtonProps = React.ComponentPropsWithoutRef<
  typeof SelectPrimitive.ScrollUpButton
>;

export const SelectScrollUpButton = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.ScrollUpButton>,
  SelectScrollUpButtonProps
>((props, ref) => (
  <SelectPrimitive.ScrollUpButton
    ref={ref}
    {...stylex.props(styles.scrollButton)}
    {...props}
  >
    <ChevronUpIcon />
  </SelectPrimitive.ScrollUpButton>
));

SelectScrollUpButton.displayName = "SelectScrollUpButton";
