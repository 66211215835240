"use client";

import { useTranslations } from "@packages/i18n";
import type { CampaignMessage } from "@packages/sdk";
import { useRequestCampaignMessage } from "@packages/sdk";
import { Close as RadixUIDialogClose } from "@radix-ui/react-dialog";
import stylex from "@stylexjs/stylex";
import { useState } from "react";
import { useForm } from "react-hook-form";

import {
  semanticColors,
  spacing,
} from "../../../../../../global/stylex/vars.stylex";
import { useIsDesktopViewport } from "../../../../../hooks";
import { TextArea } from "../../../../blocks";
import { Form, FormField } from "../../../../blocks/forms";
import { ButtonLink } from "../../../../refactor";
import { Button, IconButton, Text } from "../../../_core";
import { CheckmarkIcon, CloseIcon, ExportIcon } from "../../../icons";

const styles = stylex.create({
  form: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    gap: spacing.m,
    width: spacing.full,
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    width: spacing.full,
  },
  footer: {
    display: "flex",
    gap: spacing.s,
    justifyContent: "end",
  },
  footerMobile: {
    background: semanticColors.background,
    bottom: 0,
    display: "flex",
    flexDirection: "column",
    gap: spacing.s,
    justifyContent: "center",
    left: 0,
    paddingBottom: spacing.ms,
    paddingHorizontal: spacing.ml,
    position: "fixed",
    width: spacing.full,
  },
  success: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    gap: spacing.m,
    textAlign: "center",
  },
  successButton: {
    justifyContent: "center",
    textAlign: "center",
    width: "100%",
  },
});

export type CampaignSupportFormProps = {
  campaignId: number;
};

enum CampaignSupportFormStep {
  Note,
  Success,
}

export const CampaignSupportForm = ({
  campaignId,
}: CampaignSupportFormProps) => {
  const t = useTranslations();
  const isDesktop = useIsDesktopViewport();
  const [step, setStep] = useState<CampaignSupportFormStep>(
    CampaignSupportFormStep.Note,
  );

  const { mutationPost: campaignSupportMutation } = useRequestCampaignMessage({
    id: campaignId,
    onSuccess: () => setStep(CampaignSupportFormStep.Success),
  });

  const form = useForm<CampaignMessage>();

  const onSubmit = (data: CampaignMessage) => {
    campaignSupportMutation.mutate({
      text: data.text,
      type: "note_of_support",
    });
  };

  return (
    <Form
      formProps={{
        onSubmit: form.handleSubmit(onSubmit),
        styleXArray: [styles.form],
      }}
      {...form}
    >
      {step === CampaignSupportFormStep.Note && (
        <>
          <div {...stylex.props(styles.header)}>
            <Text size="m" type="headline">
              {t("note_of_support_input_title")}
            </Text>
            {isDesktop && (
              <RadixUIDialogClose asChild>
                <IconButton icon={<CloseIcon />} variant="transparentPrimary" />
              </RadixUIDialogClose>
            )}
          </div>
          <Text size="m" type="body">
            {t("campaign_notes_of_support_prompt_web")}
          </Text>
          <FormField
            control={form.control}
            name="text"
            description={
              <ButtonLink
                size="s"
                linkProps={{
                  href: "https://hallow.com/community-guidelines/",
                  target: "_blank",
                }}
                buttonProps={{ endIcon: <ExportIcon /> }}
              >
                {t("dialog_report_guidelines_button")}
              </ButtonLink>
            }
            render={({ field }) => (
              <TextArea
                maxLength={280}
                placeholder={t("note_of_support_input_placeholder")}
                rows={4}
                {...field}
              />
            )}
          />
          <div
            {...stylex.props(isDesktop ? styles.footer : styles.footerMobile)}
          >
            <Button
              type="submit"
              disabled={campaignSupportMutation.isPending}
              size="l"
              isFullWidth={!isDesktop}
            >
              {t("general_word_post_verb")}
            </Button>
          </div>
        </>
      )}
      {step === CampaignSupportFormStep.Success && (
        <div {...stylex.props(styles.success)}>
          <CheckmarkIcon.FilledOn
            size={60}
            type="filled"
            fill={semanticColors.successSuccess}
          />
          <Text size="m" type="headline">
            {t("campaign_notes_of_support_thanks_title")}
          </Text>
          <Text size="m" type="body">
            {t("campaign_notes_of_support_thanks_subtitle")}
          </Text>
          <RadixUIDialogClose asChild>
            <Button size="l" styleXArray={[styles.successButton]}>
              {t("general_word_done")}
            </Button>
          </RadixUIDialogClose>
        </div>
      )}
    </Form>
  );
};
