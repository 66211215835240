"use client";

import type { Images } from "@packages/sdk";
import type { ComponentProps } from "react";
import { useCallback, useEffect, useState } from "react";

export type HallowImageProps = Omit<
  ComponentProps<"img">,
  "src" | "size" | "alt"
> & {
  alt: string;
  size: "xs" | "s" | "m" | "l";
  src: Images;
};

export const HallowImage = ({
  size,
  src,
  onError,
  alt,
  ...props
}: HallowImageProps) => {
  const [loadedImageSize, setLoadedImageSize] = useState<typeof size>(size);
  const [loadedSrc, setLoadedSrc] = useState<string>(null);

  useEffect(() => {
    if (src) {
      setLoadedSrc(
        size === "l" && src.large
          ? src.large
          : size === "m" && src.medium
            ? src.medium
            : (src.small ?? src.medium ?? src.large ?? src.fallback),
      );
    }
  }, [src, size]);

  const handleError = useCallback(
    (e) => {
      if (
        src &&
        (size === "xs" ||
          size === "s" ||
          (size === "l" && loadedImageSize === size))
      ) {
        setLoadedImageSize("m");
        setLoadedSrc(src.medium);
      } else if (size !== "l" && loadedImageSize === "m") {
        setLoadedImageSize("l");
        setLoadedSrc(src.large);
      } else if (size === "l" && loadedImageSize === "m") {
        setLoadedImageSize("s");
        setLoadedSrc(src.small);
      } else {
        setLoadedSrc(src.fallback);
      }

      if (onError) onError(e);
    },
    [loadedImageSize, loadedSrc, size],
  );

  return (
    <img
      src={loadedSrc}
      onError={handleError}
      loading={"lazy"}
      alt={alt}
      {...props}
    />
  );
};
