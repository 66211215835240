"use client";

import { useTranslations } from "@packages/i18n";
import { Close as RadixUIDialogClose } from "@radix-ui/react-dialog";
import stylex from "@stylexjs/stylex";

import {
  semanticColors,
  spacing,
} from "../../../../../global/stylex/vars.stylex";
import { useIsDesktopViewport } from "../../../../hooks";
import { Button, CheckmarkIcon, Text } from "../../../_base";

/**
 * Constants
 * styleX requires these to be statically defined in this file
 */
const cssMediaMinWidthDesktop = "@media (min-width: 768px)";

const styles = stylex.create({
  root: {
    display: "flex",
    flexDirection: "column",
    gap: spacing.xl,
    height: spacing.full,
    width: { [cssMediaMinWidthDesktop]: "336px" },
  },
  contentContainer: {
    alignItems: "center",
    alignText: "center",
    display: "flex",
    flexDirection: "column",
    gap: spacing.m,
    textAlign: "center",
  },
  icon: {
    color: semanticColors.successSuccess,
  },
  footerMobile: {
    background: semanticColors.background,
    bottom: 0,
    justifyContent: "center",
    left: 0,
    paddingBottom: spacing.ms,
    paddingHorizontal: spacing.ml,
    position: "fixed",
    width: spacing.full,
  },
});

export const FlagRecordSuccess = () => {
  const isDesktop = useIsDesktopViewport();
  const t = useTranslations();
  return (
    <div {...stylex.props(styles.root)}>
      <div {...stylex.props(styles.contentContainer)}>
        <CheckmarkIcon.FilledOn size={72} {...stylex.props(styles.icon)} />
        <Text size="xl" type="title">
          {t("community_flag_appreciate_you")}
        </Text>
        <Text>{t("community_flag_report_submitted_successfully")}</Text>
      </div>
      <div {...stylex.props(!isDesktop && styles.footerMobile)}>
        <RadixUIDialogClose asChild>
          <Button size="l" isFullWidth={true}>
            {t("campaign_report_done")}
          </Button>
        </RadixUIDialogClose>
      </div>
    </div>
  );
};
