"use client";

import { useTranslations } from "@packages/i18n";
import * as stylex from "@stylexjs/stylex";
import { differenceInDays, differenceInHours } from "date-fns";
import { Fragment } from "react";

import { spacing } from "../../../../../../global/stylex/vars.stylex";
import { useCountdown } from "../../../../../hooks";
import type { BlockProps } from "../../../../refactor";
import { Block } from "../../../../refactor";
import type { ChallengeMediumProps } from "../ChallengeMedium";
import { ChallengeMedium } from "../ChallengeMedium";

/**
 * Constants
 * styleX requires these to be statically defined in this file
 */
const cssMediaMinWidthTablet = "@media (min-width: 768px)";

const styles = stylex.create({
  actions: {
    marginTop: spacing.m,
  },
  block: {
    display: {
      [cssMediaMinWidthTablet]: "flex",
      default: "none",
    },
  },
  description: {
    paddingTop: spacing.s,
  },
  challengeMedium: {
    display: {
      [cssMediaMinWidthTablet]: "none",
      default: "block",
    },
  },
});

export type ChallengeLargeProps = Pick<
  ChallengeMediumProps,
  | "asChild"
  | "activeState"
  | "availableAt"
  | "children"
  | "color"
  | "description"
  | "endsAt"
  | "focusState"
  | "hoverState"
  | "imgBlur"
  | "imgSrc"
  | "rightActions"
  | "subscription"
  | "tags"
  | "title"
  | "centerActions"
> &
  Pick<BlockProps, "actions" | "descriptionProps">;

export const ChallengeLarge = ({
  asChild,
  actions,
  activeState = false,
  availableAt,
  children,
  color = "black",
  description,
  descriptionProps,
  endsAt,
  focusState = true,
  hoverState = true,
  imgBlur,
  imgSrc,
  rightActions,
  subscription,
  tags = [],
  title,
  centerActions,
}: ChallengeLargeProps) => {
  const t = useTranslations();

  const countdown = useCountdown({
    targetDate: availableAt,
    format: "default",
  });
  let dateTag: null | string = null;

  if (availableAt && endsAt) {
    const date = new Date();
    const ended = date > new Date(endsAt);
    const started = date > new Date(availableAt);
    const dayDiff = differenceInDays(new Date(endsAt), date);
    const hourDiff = differenceInHours(new Date(endsAt), date);

    dateTag = ended
      ? t("challenge_tile_event_over")
      : started
        ? dayDiff <= 0
          ? t("challenge_tile_hours_left_label", {
              challenge_tile_hours_left_label: hourDiff,
            })
          : t("challenge_tile_days_left_label", {
              challenge_tile_days_left_label: dayDiff,
            })
        : t("challenge_tile_starts_in", {
            0: countdown,
          });
  }

  return (
    <Fragment>
      <Block
        actions={actions}
        actionsStyleXArray={[styles.actions]}
        activeState={activeState}
        color={color}
        description={description}
        descriptionProps={{
          ...descriptionProps,
          styleXArray: [styles.description, descriptionProps?.styleXArray],
        }}
        focusState={focusState}
        hoverState={hoverState}
        imgSrc={imgSrc}
        styleXArray={[styles.block]}
        subscription={subscription}
        tags={dateTag ? [...tags, dateTag] : tags}
        title={title}
      />
      <ChallengeMedium
        activeState={activeState}
        asChild={asChild}
        children={children}
        color={color}
        description={description}
        focusState={focusState}
        hoverState={hoverState}
        imgBlur={imgBlur}
        imgSrc={imgSrc}
        rightActions={rightActions}
        styleXArray={[styles.challengeMedium]}
        subscription={subscription}
        tags={tags}
        title={title}
        centerActions={centerActions}
      />
    </Fragment>
  );
};
