"use client";

import type { QueueContextShape } from "@packages/sdk";
import { createContext } from "react";

export const QueueContext = createContext<QueueContextShape>({
  addToQueue: () => new Promise(() => {}),
  back: () => null,
  clearQueue: () => {},
  currentItem: null,
  dailySkipsRemaining: 10,
  delete: () => {},
  forward: () => null,
  fullQueue: [],
  hourlySkipsRemaining: 10,
  loading: false,
  localReorder: () => null,
  newQueue: () => new Promise(() => {}),
  pastQueue: [],
  position: null,
  refresh: () => Promise.resolve(),
  repeat: () => "none",
  repeating: "none",
  shuffle: () => false,
  shuffled: false,
  skipTo: () => null,
  swap: () => Promise.resolve(),
  upcomingQueue: [],
});
