"use client";

import { HallowAnalyticsEvent, useAnalytics } from "@packages/analytics";
import { getContentId, useCurrentQueueItem, usePlayer } from "@packages/media";
import { snakeCase } from "@packages/sdk";

import { ModalTab, TimerIcon } from "../../../../../components";

export type SleepTimerProps = {
  countdownSeconds: number;
  timeMinStr: string;
  label?: string;
  value: string;
};
export const SleepTimerTab = ({
  countdownSeconds,
  timeMinStr,
  label = "",
  value,
}: SleepTimerProps) => {
  const player = usePlayer();
  const analytics = useAnalytics();
  const { currentItem, queue } = useCurrentQueueItem();
  const handleTrigger = () => {
    if (
      player &&
      (!player.sleepTimerTarget || player.sleepTimerTarget < Date.now())
    ) {
      analytics.track({
        event: HallowAnalyticsEvent.TimerChanged,
        properties: {
          length: snakeCase(timeMinStr),
          loop: queue.repeating,
          content_id: getContentId(currentItem),
          content_type: currentItem.type,
        },
      });
      player.setSleepTimer(countdownSeconds, timeMinStr);
    } else {
      analytics.track({
        event: HallowAnalyticsEvent.TappedSleepTimer,
        properties: {
          content_id: getContentId(currentItem),
          content_type: currentItem.type,
        },
      });
    }
  };

  return (
    <ModalTab
      label={label.length ? label : undefined}
      startIcon={label.length ? <TimerIcon.Outline /> : undefined}
      value={value}
      onClick={handleTrigger}
    >
      {timeMinStr}
    </ModalTab>
  );
};
