import { useTranslations } from "@packages/i18n";
import {
  type Menu,
  URL_COPYRIGHT,
  URL_PRIVACY_POLICY,
  URL_TERMS_OF_SERVICE,
} from "@packages/sdk";
import * as stylex from "@stylexjs/stylex";
import Link from "next/link";
import { Suspense } from "react";

import {
  colors,
  numericPercentages,
  numericPixels,
  semanticColors,
  spacing,
} from "../../../../../global/stylex/vars.stylex";
import { Container } from "../../../blocks";
import {
  FacebookMinimalIcon,
  HallowLogoText,
  InstagramMinimalIcon,
  XMinimalIcon,
} from "../../icons";
import { Text } from "../Text";
import { AppStoreIcon, PlayStoreIcon } from "./AppStoreIcons";
import { FooterCookies } from "./FooterCookies";

/**
 * Constants
 * styleX requires these to be statically defined in this file
 */
const cssMediaMinWidthDesktop = "@media (min-width: 768px)";

const links = [
  { href: URL_PRIVACY_POLICY, message: "web_privacy_policy" },
  {
    href: URL_TERMS_OF_SERVICE,
    message: "web_terms_of_service",
  },
  {
    href: URL_COPYRIGHT,
    message: "web_copyright",
  },
];

const styles = stylex.create({
  base: {
    backgroundColor: colors.black,
    color: colors.white,
  },
  main: {
    display: "flex",
    flexDirection: {
      [cssMediaMinWidthDesktop]: "row",
      default: "column",
    },
    gap: spacing.l,
    paddingBottom: spacing.xxl,
    paddingTop: spacing.xxxl,
  },
  brand: {
    padding: {
      [cssMediaMinWidthDesktop]: spacing.none,
      default: `${spacing.none} ${spacing.l}`,
    },
  },
  nav: {
    flexGrow: 1,
  },
  navList: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
  },
  navLink: {
    display: "block",
    padding: `${spacing.s} ${spacing.l}`,
    textDecoration: "none",
    ":hover": {
      textDecoration: "underline",
    },
  },
  links: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    flexGrow: 0,
    flexShrink: 0,
    gap: spacing.s,
    padding: {
      [cssMediaMinWidthDesktop]: spacing.none,
      default: `${spacing.s} ${spacing.l}`,
    },
  },
  social: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingBottom: spacing.s,
    paddingLeft: spacing.m,
    paddingRight: spacing.m,
    paddingTop: spacing.none,
    width: {
      [cssMediaMinWidthDesktop]: numericPercentages[100],
      default: numericPixels[200],
    },
  },
  trailer: {
    alignItems: "center",
    color: semanticColors.neutralsMedHigh,
    display: "flex",
    flexDirection: {
      [cssMediaMinWidthDesktop]: "row",
      default: "column",
    },
    gap: {
      [cssMediaMinWidthDesktop]: spacing.none,
      default: spacing.m,
    },
    justifyContent: {
      [cssMediaMinWidthDesktop]: "space-between",
    },
    paddingBottom: spacing.xl,
    paddingTop: {
      [cssMediaMinWidthDesktop]: spacing.xl,
      default: spacing.m,
    },
  },
  ul: {
    alignItems: {
      [cssMediaMinWidthDesktop]: "center",
      default: "flex-start",
    },
    display: "flex",
    flexWrap: {
      [cssMediaMinWidthDesktop]: "nowrap",
      default: "wrap",
    },
    gap: {
      [cssMediaMinWidthDesktop]: spacing.l,
      default: spacing.m,
    },
    justifyContent: {
      [cssMediaMinWidthDesktop]: "space-between",
      default: "center",
    },
    padding: {
      [cssMediaMinWidthDesktop]: spacing.none,
      default: `${spacing.none} ${spacing.l}`,
    },
  },
  legalLink: {
    flexShrink: 0,
  },
});

type FooterProps = {
  menu: Menu;
};

export const Footer = ({ menu }: FooterProps) => {
  const t = useTranslations();

  return (
    <footer {...stylex.props(styles.base)}>
      <Container maxWidth={true}>
        <div {...stylex.props(styles.main)}>
          <div {...stylex.props(styles.brand)}>
            <HallowLogoText />
          </div>
          <nav {...stylex.props(styles.nav)}>
            <ul {...stylex.props(styles.navList)}>
              {menu.map((menu) => (
                <li key={menu.id}>
                  <Link
                    href={menu.url}
                    target={menu.target}
                    {...stylex.props(styles.navLink)}
                  >
                    <Text size="l" type="body">
                      {menu.title}
                    </Text>
                  </Link>
                </li>
              ))}
            </ul>
          </nav>
          <div {...stylex.props(styles.links)}>
            <div {...stylex.props(styles.social)}>
              <Link href="https://facebook.com/hallowapp" target="_blank">
                <FacebookMinimalIcon />
              </Link>
              <Link href="https://x.com/HallowApp" target="_blank">
                <XMinimalIcon />
              </Link>
              <Link href="https://instagram.com/hallowapp" target="_blank">
                <InstagramMinimalIcon />
              </Link>
            </div>
            <Link
              href="https://itunes.apple.com/app/id1405323394"
              target="_blank"
            >
              <AppStoreIcon />
            </Link>
            <Link
              href="https://play.google.com/store/apps/details?id=app.hallow.android"
              target="_blank"
            >
              <PlayStoreIcon />
            </Link>
          </div>
        </div>
        <div {...stylex.props(styles.trailer)}>
          <Text size="xl" type="detail">
            © {new Date().getFullYear()} Hallow, Inc.
          </Text>
          <ul {...stylex.props(styles.ul)}>
            <li key={"cookie-manager"}>
              <Suspense>
                <FooterCookies />
              </Suspense>
            </li>
            {links.map((link) => (
              <li key={link.message} {...stylex.props(styles.legalLink)}>
                <Link href={link.href} target="_blank">
                  <Text size="xl" type="detail">
                    {t(link.message)}
                  </Text>
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </Container>
    </footer>
  );
};

Footer.displayName = "Footer";
