"use client";

import { useTranslations } from "@packages/i18n";
import { useCurrentQueueItem, usePlayer } from "@packages/media";
import * as stylex from "@stylexjs/stylex";
import { useCallback, useEffect, useState } from "react";

import {
  numericPercentages,
  numericPixels,
  numericValues,
} from "../../../../../../global/stylex/vars.stylex";
import {
  Button,
  FastForwardIcon,
  Plus10Icon,
  Toast,
  Transition,
} from "../../../../../components";
import { TransitionRoot } from "../../../../../providers";
import type { StyleXArray, WithButtonWrapper } from "../../../../../types";
import { MediaButton } from "../MediaButton";

export type ForwardButtonProps = WithButtonWrapper<{
  canSkipIntro: boolean;
  skipStyleXArray?: StyleXArray;
  forwardStyleXArray?: StyleXArray;
}>;

const styles = stylex.create({
  container: {
    display: "flex",
    placeItems: "center",
    width: numericPixels[40],
  },
  containerEXPANDED: {
    width: numericPixels[80],
  },
  skipExiting: {
    opacity: 0,
    position: "absolute",
    top: numericPercentages[50],
    transform: "translateY(-50%)",
  },
  disabled: {
    cursor: "not-allowed",
    opacity: numericValues["0.5"],
  },
});

export const ForwardButton = ({
  canSkipIntro,
  Wrapper = ({ children }) => <>{children}</>,
  forwardStyleXArray = [],
  skipStyleXArray = [],
}: ForwardButtonProps) => {
  const [value, setValue] = useState<"skipIntro" | "forward">(
    canSkipIntro ? "skipIntro" : "forward",
  );
  const [showNotice, setShowNotice] = useState<
    number | "none" | "hour" | "day"
  >("none");
  const t = useTranslations();
  const player = usePlayer();
  const { currentItem, queue } = useCurrentQueueItem();

  const notify = (notice: typeof showNotice) => {
    setShowNotice(notice);
    setTimeout(() => setShowNotice("none"), 3000);
  };

  useEffect(() => {
    setValue(canSkipIntro ? "skipIntro" : "forward");
  }, [canSkipIntro]);

  const handleForward = useCallback(
    async (e) => {
      e.stopPropagation();

      try {
        await player?.skipForward();
      } catch (e) {
        if (e.code === 11001) {
          notify("day");
          return;
        } else if (e.code === 11000) {
          notify("hour");
          return;
        }
      }

      if (currentItem?.type === "radio_song") {
        if (queue.hourlySkipsRemaining > 0) {
          notify(queue.hourlySkipsRemaining);
        } else {
          notify("hour");
        }
      }
    },
    [player, queue?.hourlySkipsRemaining],
  );

  const handleSkipIntro = useCallback(
    (e) => {
      e.stopPropagation();
      player?.skipIntro();
    },
    [player],
  );

  return (
    <>
      <TransitionRoot
        value={value}
        defaultValue={canSkipIntro ? "skipIntro" : "forward"}
        styleXArray={[
          styles.container,
          styles[`container${player.display.toUpperCase()}`],
        ]}
      >
        <Transition
          value={"skipIntro"}
          exitingStyleXArray={[styles.skipExiting]}
          exitedStyleXArray={[styles.skipExiting]}
        >
          <Wrapper asChild>
            <Button
              variant={"lighten"}
              onClick={handleSkipIntro}
              styleXArray={[skipStyleXArray]}
              size={"s"}
            >
              {t("audio_player_skip_intro")}
            </Button>
          </Wrapper>
        </Transition>
        <Transition value={"forward"}>
          <Wrapper asChild>
            <MediaButton
              largeIcon={
                currentItem?.selected_audio?.show_music_controls ||
                currentItem?.type === "radio_song" ? (
                  <FastForwardIcon.XL />
                ) : (
                  <Plus10Icon.XL />
                )
              }
              smallIcon={
                currentItem?.selected_audio?.show_music_controls ||
                currentItem?.type === "radio_song" ? (
                  <FastForwardIcon.Small />
                ) : (
                  <Plus10Icon.Medium />
                )
              }
              styleXArray={[
                forwardStyleXArray,
                queue.hourlySkipsRemaining <= 0 ||
                queue.dailySkipsRemaining <= 0
                  ? styles.disabled
                  : null,
              ]}
              onClick={handleForward}
              tabIndex={0}
            />
          </Wrapper>
        </Transition>
      </TransitionRoot>
      {showNotice !== "none" ? (
        <Toast>
          {t(
            showNotice === "day"
              ? "music_radio_used_all_skips_day"
              : showNotice === "hour"
                ? "music_radio_used_all_skips_hour"
                : "music_radio_skips_remaining",
            {
              music_radio_skips_remaining:
                typeof showNotice === "number" ? showNotice : undefined,
            },
          )}
        </Toast>
      ) : null}
    </>
  );
};
