import * as stylex from "@stylexjs/stylex";

import {
  colors,
  radius,
  semanticColors,
  spacing,
  stroke,
} from "../../../../../global/stylex/vars.stylex";
import type {
  HallowElement,
  HallowElementProps,
  WithAsChild,
  WithStylexArray,
} from "../../../../types";
import { determineElementFromAsChild } from "../../../../utils";
import { Text } from "../Text";

const Element: HallowElement = "div";

export type TagProps = WithAsChild<
  WithStylexArray<HallowElementProps<typeof Element>>
> & {
  variant?:
    | "secondary"
    | "accent"
    | "neutral"
    | "lighten"
    | "outline"
    | "darkOnLight"
    | "darken"
    | "floating";
};

const styles = stylex.create({
  base: {
    alignItems: "center",
    borderRadius: radius.xs,
    boxSizing: "border-box",
    fontFeatureSettings: "tnum",
    fontVariantNumeric: "tabular-nums",
    height: spacing.l,
    maxWidth: spacing.full,
    paddingBottom: spacing.xxs,
    paddingLeft: spacing.s,
    paddingRight: spacing.s,
    paddingTop: spacing.xxs,
    width: "fit-content",
  },
});

const stylesVariant = stylex.create({
  secondary: {
    backgroundColor: semanticColors.secondary,
    color: semanticColors.primary,
  },
  accent: {
    backgroundColor: semanticColors.accentLowest,
    color: semanticColors.accentMedHigh,
  },
  neutral: {
    backgroundColor: semanticColors.neutralsLowest,
    color: semanticColors.primary,
  },
  lighten: {
    backgroundColor: semanticColors.fixedLightenLow,
    color: semanticColors.onColorHighest,
  },
  outline: {
    backgroundColor: colors.transparent,
    borderColor: semanticColors.onColorHigh,
    borderStyle: "solid",
    borderWidth: stroke.light,
    color: semanticColors.onColorHighest,
  },
  darkOnLight: {
    backgroundColor: semanticColors.onColorHigh,
    color: semanticColors.onColorLowest,
  },
  darken: {
    backgroundColor: semanticColors.onColorLow,
    color: semanticColors.onColorHighest,
  },
  floating: {
    backgroundColor: colors.transparent,
    color: semanticColors.onColorHighest,
  },
});

export const Tag = ({
  asChild = false,
  children,
  styleXArray = [],
  variant = "secondary",
  ...props
}: TagProps) => {
  const DeterminedElement = determineElementFromAsChild({
    asChild,
    hallowElement: Element,
  });

  return (
    <Text asChild overflow="ellipsis" size="l" type="detail">
      <DeterminedElement
        {...stylex.props(styles.base, stylesVariant[variant], styleXArray)}
        {...props}
      >
        {children}
      </DeterminedElement>
    </Text>
  );
};
