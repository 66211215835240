"use client";

import {
  COOKIE_KEY_AJS_ANONYMOUS_ID,
  COOKIE_KEY_HLW_CONSENT,
  useCookie,
  useRequestIp,
  useRequestMe,
  useSession,
  z,
} from "@packages/sdk";
import type { ComponentProps } from "react";
import { useEffect, useState } from "react";

import type { ConsentContext } from "../lib";
import {
  consentContext,
  DEFAULT_CONSENT_OPTIONS,
  DEFAULT_CONSENT_OPTIONS_STRICT,
} from "../lib";
import { Consent } from "../types";
import { getIpIsStrictConsentPermissions } from "../utils";

export type ConsentProviderProps = Omit<
  ComponentProps<typeof consentContext.Provider>,
  "value"
>;

export const ConsentProvider = ({
  children,
  ...props
}: ConsentProviderProps) => {
  const [ajsAnonymousId, setAjsAnonymousId] = useCookie({
    key: COOKIE_KEY_AJS_ANONYMOUS_ID,
  });
  const [consent, setConsent] = useCookie<number[]>({
    key: COOKIE_KEY_HLW_CONSENT,
    schema: z.number().array(),
  });
  const { mutationGet: mutationGetIp } = useRequestIp();
  const { mutationPut: mutationPutMe, query: queryMe } = useRequestMe();
  const { status } = useSession();

  const isConsenting = consent ? consent.includes(Consent.ANALYTICS) : false;
  const isLoading = consent ? false : true;

  /** TODO: remove/improve when ConsentModal/CookieManager are improved */
  const [isConsentModalVisible, setIsConsentModalVisible] = useState(false);
  const saveConsent = async ({
    consent,
  }: {
    consent: ConsentContext["consent"];
  }) => {
    setConsent(consent);
    if (status === "authenticated")
      await mutationPutMe.mutateAsync({ consent });
  };

  /**
   * If the user does not have consent preferences,
   * default: use DEFAULT_CONSENT_OPTIONS
   * strict IP: use DEFAULT_CONSENT_OPTIONS_STRICT
   * strict IP: show consent modal
   */
  useEffect(() => {
    const handleConsentFromIp = async () => {
      const ip = await mutationGetIp.mutateAsync();

      if (ip) {
        const isIpStrictConsentPermissions = getIpIsStrictConsentPermissions({
          ip,
        });
        setConsent(
          isIpStrictConsentPermissions
            ? DEFAULT_CONSENT_OPTIONS_STRICT
            : DEFAULT_CONSENT_OPTIONS,
        );
        if (isIpStrictConsentPermissions) setIsConsentModalVisible(true);
      }
    };

    if (!consent) handleConsentFromIp();
  }, [consent]);

  /** Sync any inconsistent consents */
  useEffect(() => {
    if (queryMe.data?.consent) {
      if (JSON.stringify(consent) !== JSON.stringify(queryMe.data.consent))
        setConsent(queryMe.data.consent);
    } else if (consent && status === "authenticated")
      mutationPutMe.mutate({ consent });
  }, [consent, queryMe.data?.consent]);

  return (
    <consentContext.Provider
      value={{
        ajsAnonymousId,
        consent,
        isConsenting,
        isLoading,
        isConsentModalVisible,
        saveConsent,
        setAjsAnonymousId,
        setConsent,
        setIsConsentModalVisible,
      }}
      {...props}
    >
      {children}
    </consentContext.Provider>
  );
};
