"use client";

import * as RadixUIAvatar from "@radix-ui/react-avatar";
import * as stylex from "@stylexjs/stylex";
import type { ComponentProps } from "react";

import {
  numericPercentages,
  numericPixels,
  radius,
  semanticColors,
} from "../../../../../global/stylex/vars.stylex";
import { stylexDynamics } from "../../../../lib";
import type { WithStylexArray } from "../../../../types";
import { Skeleton } from "../Skeleton";

const styles = stylex.create({
  base: {
    borderRadius: radius.full,
    display: "flex",
    flexShrink: 0,
  },
  selected: {
    outlineColor: semanticColors.primary,
    outlineOffset: numericPixels[1],
    outlineStyle: "solid",
    outlineWidth: numericPixels[2],
  },
});

const stylesImage = stylex.create({
  base: {
    borderRadius: radius.full,
    height: numericPercentages[100],
    objectFit: "cover",
    width: numericPercentages[100],
  },
});

const stylesFallback = stylex.create({
  base: {
    backgroundColor: semanticColors.neutralsLower,
    borderRadius: radius.full,
    height: numericPercentages[100],
    width: numericPercentages[100],
  },
});

export type AvatarProps = WithStylexArray<
  ComponentProps<typeof RadixUIAvatar.Root>
> & {
  imgSrc?: string;
  loading?: boolean;
  name?: string;
  selected?: boolean;
  size?: number | "full";
};

export const Avatar = ({
  imgSrc = "",
  loading = false,
  name = "",
  selected = false,
  size = 24,
  styleXArray = [],
  children,
  ...props
}: AvatarProps) => {
  if (loading)
    return (
      <Skeleton
        styleXArray={[
          styles.base,
          stylexDynamics.heightWidth({ height: size, width: size }),
          styleXArray,
        ]}
        {...props}
      />
    );

  return (
    <RadixUIAvatar.Root
      {...stylex.props(
        styles.base,
        selected && styles.selected,
        stylexDynamics.heightWidth({
          height: size === "full" ? "100%" : size,
          width: size === "full" ? "100%" : size,
        }),
        styleXArray,
      )}
      {...props}
    >
      {imgSrc && (
        <RadixUIAvatar.Image
          alt={name}
          src={imgSrc}
          {...stylex.props(
            stylesImage.base,
            stylexDynamics.heightWidth({ height: size, width: size }),
          )}
        />
      )}
      {children}
      <RadixUIAvatar.Fallback {...stylex.props(stylesFallback.base)} />
    </RadixUIAvatar.Root>
  );
};
