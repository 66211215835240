"use client";

import * as stylex from "@stylexjs/stylex";
import type { ReactNode } from "react";
import { useHover } from "react-aria";

import type { CarouselApi } from "../../../../hooks";
import type {
  HallowElementProps,
  StyleXArray,
  WithStylexArray,
} from "../../../../types";
import { CarouselButton } from "./CarouselButton";
import { CarouselItem } from "./CarouselItem";
import { CarouselPageOffset } from "./CarouselPageOffset";

const cssMediaMinWidthTablet = "@media (min-width: 768px)";

const styles = stylex.create({
  carousel: {
    overflow: "hidden",
    position: "relative",
    width: "100%",
  },
  container: {
    "-webkit-overflow-scrolling": "touch",
    display: "flex",
    overflowX: "auto",
    overflowY: "visible",
    scrollBehavior: "smooth",
    scrollSnapType: "x mandatory",
    scrollbarWidth: "none",
    "::-webkit-scrollbar": {
      display: "none",
    },
  },
  containerPageOffset: (props: { pageOffset: CarouselApi["pageOffset"] }) => ({
    scrollPaddingLeft: {
      [cssMediaMinWidthTablet]: `${props.pageOffset[0][1]}px`,
      default: `${props.pageOffset[0][0]}px`,
    },
    scrollPaddingRight: {
      [cssMediaMinWidthTablet]: `${props.pageOffset[1][1]}px`,
      default: `${props.pageOffset[1][0]}px`,
    },
  }),
  start: {
    scrollSnapAlign: "end",
  },
});

export type CarouselProps = WithStylexArray<HallowElementProps<"div">> & {
  carouselApi?: CarouselApi;
  containerStylexArray?: StyleXArray;
};

export const Carousel = ({
  carouselApi,
  containerStylexArray,
  children,
  styleXArray,
  ...props
}: CarouselProps) => {
  const {
    canScrollNext,
    canScrollPrevious,
    hoverButtons,
    pageOffset,
    refEnd,
    refStart,
    refParent,
    scrollNext,
    scrollPrevious,
  } = carouselApi;

  const { isHovered: hover, hoverProps } = useHover({});

  const getRefPosition = (index: number) => {
    if (!pageOffset && index === (children as ReactNode[]).length - 1)
      return refEnd;
    if (!pageOffset && index === 0) return refStart;
    return null;
  };

  if (children === null || ((children as ReactNode[]) || []).length === 0)
    return null;

  return (
    <div
      {...stylex.props(styles.carousel, styleXArray)}
      {...hoverProps}
      {...props}
    >
      {hoverButtons && (
        <CarouselButton
          carouselApi={carouselApi}
          direction="previous"
          onClick={scrollPrevious}
          visible={hover && canScrollPrevious}
        />
      )}
      <div
        ref={refParent}
        {...stylex.props(
          styles.container,
          pageOffset ? styles.containerPageOffset({ pageOffset }) : null,
          containerStylexArray,
        )}
      >
        {pageOffset && (
          <CarouselPageOffset
            carouselApi={carouselApi}
            pageOffsetPosition="start"
            ref={refStart}
            styleXArray={[styles.start]}
          />
        )}
        {((children as ReactNode[]) || []).map((child, index) => (
          <CarouselItem
            carouselApi={carouselApi}
            key={index}
            isEnd={index === (children as ReactNode[]).length - 1}
            isStart={index === 0}
            ref={getRefPosition(index)}
          >
            {child}
          </CarouselItem>
        ))}
        {pageOffset && (
          <CarouselPageOffset
            carouselApi={carouselApi}
            pageOffsetPosition="end"
            ref={refEnd}
          />
        )}
      </div>
      {hoverButtons && (
        <CarouselButton
          carouselApi={carouselApi}
          direction="next"
          onClick={scrollNext}
          visible={hover && canScrollNext}
        />
      )}
    </div>
  );
};

Carousel.displayName = "Carousel";
