import * as stylex from "@stylexjs/stylex";

import type { GradientDirection } from "../types";
import type { ColorMixProps, RGBAObject } from "../utils";
import { colorMix, RGBAObjectToString } from "../utils";

/**
 * Constants
 * styleX requires these to be statically defined in this file
 */
const cssMediaQueryMinWidthDesktop = "@media (min-width: 1024px)";
const cssMediaQueryMinWidthTablet = "@media (min-width: 768px)";

export const stylexDynamics = stylex.create({
  colorFromRGBAObject: (color: RGBAObject) => ({
    color: RGBAObjectToString(color),
  }),
  backgroundColorFromRGBAObject: (color: RGBAObject) => ({
    backgroundColor: RGBAObjectToString(color),
  }),
  backgroundGradient: (options: { red: number; green: number; blue: number; direction: GradientDirection }) => ({
    backgroundImage: `linear-gradient(${options.direction},
                rgba(${options.red}, ${options.green}, ${options.blue}, 0) 0%,
                rgba(${options.red}, ${options.green}, ${options.blue}, 0.013) 8.1%,
                rgba(${options.red}, ${options.green}, ${options.blue}, 0.049) 15.5%,
                rgba(${options.red}, ${options.green}, ${options.blue}, 0.104) 22.5%,
                rgba(${options.red}, ${options.green}, ${options.blue}, 0.175) 29%,
                rgba(${options.red}, ${options.green}, ${options.blue}, 0.259) 35.3%,
                rgba(${options.red}, ${options.green}, ${options.blue}, 0.352) 41.2%,
                rgba(${options.red}, ${options.green}, ${options.blue}, 0.45) 47.1%,
                rgba(${options.red}, ${options.green}, ${options.blue}, 0.55) 52.9%,
                rgba(${options.red}, ${options.green}, ${options.blue}, 0.648) 58.8%,
                rgba(${options.red}, ${options.green}, ${options.blue}, 0.741) 64.7%,
                rgba(${options.red}, ${options.green}, ${options.blue}, 0.825) 71%,
                rgba(${options.red}, ${options.green}, ${options.blue}, 0.896) 77.5%,
                rgba(${options.red}, ${options.green}, ${options.blue}, 0.951) 84.5%,
                rgba(${options.red}, ${options.green}, ${options.blue}, 0.987) 91.9%,
                rgba(${options.red}, ${options.green}, ${options.blue}, 1))`,
  }),
  backgroundGradientSpread: (options: { red: number; green: number; blue: number; direction: GradientDirection; spread: number }) => ({
    backgroundImage: `linear-gradient(${options.direction},
                    rgba(${options.red}, ${options.green}, ${options.blue}, 0) 0%,
                    rgba(${options.red}, ${options.green}, ${options.blue}, 0.013) ${options.spread * 0.081}px,
                    rgba(${options.red}, ${options.green}, ${options.blue}, 0.049) ${options.spread * 0.155}px,
                    rgba(${options.red}, ${options.green}, ${options.blue}, 0.104) ${options.spread * 0.225}px,
                    rgba(${options.red}, ${options.green}, ${options.blue}, 0.175) ${options.spread * 0.29}px,
                    rgba(${options.red}, ${options.green}, ${options.blue}, 0.259) ${options.spread * 0.353}px,
                    rgba(${options.red}, ${options.green}, ${options.blue}, 0.352) ${options.spread * 0.412}px,
                    rgba(${options.red}, ${options.green}, ${options.blue}, 0.45) ${options.spread * 0.471}px,
                    rgba(${options.red}, ${options.green}, ${options.blue}, 0.55) ${options.spread * 0.529}px,
                    rgba(${options.red}, ${options.green}, ${options.blue}, 0.648) ${options.spread * 0.588}px,
                    rgba(${options.red}, ${options.green}, ${options.blue}, 0.741) ${options.spread * 0.647}px,
                    rgba(${options.red}, ${options.green}, ${options.blue}, 0.825) ${options.spread * 0.71}px,
                    rgba(${options.red}, ${options.green}, ${options.blue}, 0.896) ${options.spread * 0.775}px,
                    rgba(${options.red}, ${options.green}, ${options.blue}, 0.951) ${options.spread * 0.845}px,
                    rgba(${options.red}, ${options.green}, ${options.blue}, 0.987) ${options.spread * 0.919}px,
                    rgba(${options.red}, ${options.green}, ${options.blue}, 1) ${options.spread}px)`,
  }),
  backgroundGradientStartSpread: (options: {
    red: number;
    green: number;
    blue: number;
    direction: GradientDirection;
    spread: number;
    start: string;
  }) => ({
    backgroundImage: `linear-gradient(${options.direction},
                    rgba(${options.red}, ${options.green}, ${options.blue}, 0) ${options.start},
                    rgba(${options.red}, ${options.green}, ${options.blue}, 0.013) calc(${options.start} + ${options.spread * 0.081}px),
                    rgba(${options.red}, ${options.green}, ${options.blue}, 0.049) calc(${options.start} + ${options.spread * 0.155}px),
                    rgba(${options.red}, ${options.green}, ${options.blue}, 0.104) calc(${options.start} + ${options.spread * 0.225}px),
                    rgba(${options.red}, ${options.green}, ${options.blue}, 0.175) calc(${options.start} + ${options.spread * 0.29}px),
                    rgba(${options.red}, ${options.green}, ${options.blue}, 0.259) calc(${options.start} + ${options.spread * 0.353}px),
                    rgba(${options.red}, ${options.green}, ${options.blue}, 0.352) calc(${options.start} + ${options.spread * 0.412}px),
                    rgba(${options.red}, ${options.green}, ${options.blue}, 0.45) calc(${options.start} + ${options.spread * 0.471}px),
                    rgba(${options.red}, ${options.green}, ${options.blue}, 0.55) calc(${options.start} + ${options.spread * 0.529}px),
                    rgba(${options.red}, ${options.green}, ${options.blue}, 0.648) calc(${options.start} + ${options.spread * 0.588}px),
                    rgba(${options.red}, ${options.green}, ${options.blue}, 0.741) calc(${options.start} + ${options.spread * 0.647}px),
                    rgba(${options.red}, ${options.green}, ${options.blue}, 0.825) calc(${options.start} + ${options.spread * 0.71}px),
                    rgba(${options.red}, ${options.green}, ${options.blue}, 0.896) calc(${options.start} + ${options.spread * 0.775}px),
                    rgba(${options.red}, ${options.green}, ${options.blue}, 0.951) calc(${options.start} + ${options.spread * 0.845}px),
                    rgba(${options.red}, ${options.green}, ${options.blue}, 0.987) calc(${options.start} + ${options.spread * 0.919}px),
                    rgba(${options.red}, ${options.green}, ${options.blue}, 1) calc(${options.start} + ${options.spread}px)`,
  }),
  colorMixBackgroundColor: (colorMixProps: ColorMixProps) => ({
    backgroundColor: colorMix(colorMixProps),
  }),
  displayDesktopViewport: (display?: string) => ({
    display: {
      default: "none",
      [cssMediaQueryMinWidthDesktop]: display ?? "inherit",
    },
  }),
  displayTabletViewport: (display?: string) => ({
    display: {
      default: "none",
      [cssMediaQueryMinWidthTablet]: display ?? "inherit",
    },
  }),
  heightWidth: (sizes: { height: number | string; width: number | string }) => ({
    height: sizes.height,
    width: sizes.width,
  }),
  hideDesktopViewport: (display?: string) => ({
    display: {
      default: display ?? "inherit",
      [cssMediaQueryMinWidthDesktop]: "none",
    },
  }),
  hideTabletViewport: (display?: string) => ({
    display: {
      default: display ?? "inherit",
      [cssMediaQueryMinWidthTablet]: "none",
    },
  }),
  mask: (options: { directions: GradientDirection[]; spreads: number[] }) => ({
    mask: options.directions
      .map(
        (d, idx) => `linear-gradient(${d},
                    rgba(0, 0, 0, 0) 0%,
                    rgba(0, 0, 0, 0.013) ${options.spreads[idx] * 0.081}px,
                    rgba(0, 0, 0, 0.049) ${options.spreads[idx] * 0.155}px,
                    rgba(0, 0, 0, 0.104) ${options.spreads[idx] * 0.225}px,
                    rgba(0, 0, 0, 0.175) ${options.spreads[idx] * 0.29}px,
                    rgba(0, 0, 0, 0.259) ${options.spreads[idx] * 0.353}px,
                    rgba(0, 0, 0, 0.352) ${options.spreads[idx] * 0.412}px,
                    rgba(0, 0, 0, 0.45) ${options.spreads[idx] * 0.471}px,
                    rgba(0, 0, 0, 0.55) ${options.spreads[idx] * 0.529}px,
                    rgba(0, 0, 0, 0.648) ${options.spreads[idx] * 0.588}px,
                    rgba(0, 0, 0, 0.741) ${options.spreads[idx] * 0.647}px,
                    rgba(0, 0, 0, 0.825) ${options.spreads[idx] * 0.71}px,
                    rgba(0, 0, 0, 0.896) ${options.spreads[idx] * 0.775}px,
                    rgba(0, 0, 0, 0.951) ${options.spreads[idx] * 0.845}px,
                    rgba(0, 0, 0, 0.987) ${options.spreads[idx] * 0.919}px,
                    rgba(0, 0, 0, 1) ${options.spreads[idx]}px)`,
      )
      .join(", "),
    maskComposite: "intersect",
  }),
  fullMask: (direction: GradientDirection) => ({
    mask: `linear-gradient(${direction},
      rgba(0, 0, 0, 1) 0%,
      rgba(0, 0, 0, 0.987) 8.1%,
      rgba(0, 0, 0, 0.951) 15.5%,
      rgba(0, 0, 0, 0.896) 22.5%,
      rgba(0, 0, 0, 0.825) 29%,
      rgba(0, 0, 0, 0.741) 35.3%,
      rgba(0, 0, 0, 0.648) 41.2%,
      rgba(0, 0, 0, 0.55) 47.1%,
      rgba(0, 0, 0, 0.45) 52.9%,
      rgba(0, 0, 0, 0.352) 58.8%,
      rgba(0, 0, 0, 0.259) 64.7%,
      rgba(0, 0, 0, 0.175) 71%,
      rgba(0, 0, 0, 0.104) 77.5%,
      rgba(0, 0, 0, 0.049) 84.5%,
      rgba(0, 0, 0, 0.013) 91.9%,
      rgba(0, 0, 0, 0))`,
  }),
});
