"use client";

import { useFormatter, useTranslations } from "@packages/i18n";
import type { GetDateByRangeProps } from "@packages/sdk";
import {
  getDateByRange,
  useRequestCommunitiesStatsTotalsOverTimeProps,
} from "@packages/sdk";
import stylex from "@stylexjs/stylex";

import { spacing } from "../../../../../../global/stylex/vars.stylex";
import { useErrorNotification } from "../../../../../hooks/useErrorNotification";
import { StatisticCard } from "../../../../_base";

/**
 * Constants
 * styleX requires these to be statically defined in this file
 */
const cssMediaMinWidthDesktop = "@media (min-width: 768px)";

const styles = stylex.create({
  container: {
    display: "grid",
    gap: spacing.m,
    gridTemplateColumns: {
      [cssMediaMinWidthDesktop]: "repeat(4, minmax(0, 1fr))",
      default: "repeat(2, minmax(0, 1fr))",
    },
    paddingBottom: spacing.l,
    paddingTop: spacing.l,
  },
});

export type CommunityTotalsStatisticCardsProps = {
  id: number;
  range?: GetDateByRangeProps["range"] | "all";
};

export const CommunityTotalsStatisticCards = ({
  id,
  range = "all",
}: CommunityTotalsStatisticCardsProps) => {
  const format = useFormatter();
  const t = useTranslations();

  const { query: queryCommunitiesTotalsOverTimeWeekly } =
    useRequestCommunitiesStatsTotalsOverTimeProps({
      id,
      startDate: getDateByRange({ range: "weekly" }),
    });

  const { query: queryCommunitiesTotalsOverMonthly } =
    useRequestCommunitiesStatsTotalsOverTimeProps({
      id,
      startDate: getDateByRange({ range: "monthly" }),
    });

  const { query: queryCommunitiesTotalsOverQuarterly } =
    useRequestCommunitiesStatsTotalsOverTimeProps({
      id,
      startDate: getDateByRange({ range: "quarterly" }),
    });

  const { query: queryCommunitiesTotalsOverYearly } =
    useRequestCommunitiesStatsTotalsOverTimeProps({
      id,
      startDate: getDateByRange({ range: "yearly" }),
    });

  const { query: queryCommunitiesTotalsOverAll } =
    useRequestCommunitiesStatsTotalsOverTimeProps({ id });

  const getQueryCommunitiesTotalsOver = () => {
    switch (range) {
      case "weekly":
        return queryCommunitiesTotalsOverTimeWeekly;
      case "monthly":
        return queryCommunitiesTotalsOverMonthly;
      case "quarterly":
        return queryCommunitiesTotalsOverQuarterly;
      case "yearly":
        return queryCommunitiesTotalsOverYearly;
      default:
        return queryCommunitiesTotalsOverAll;
    }
  };

  const queryCommunitiesTotalsOverTime = getQueryCommunitiesTotalsOver();

  useErrorNotification({ isError: queryCommunitiesTotalsOverTime.isError });

  return (
    <div {...stylex.props(styles.container)}>
      <StatisticCard
        value={format.number(
          queryCommunitiesTotalsOverTime.data?.prayers_completed,
        )}
        label={t("parish_admin_dashboard_prayers_completed")}
        loading={!queryCommunitiesTotalsOverTime.data}
      />
      <StatisticCard
        value={format.number(
          queryCommunitiesTotalsOverTime.data?.total_minutes_prayed,
        )}
        label={t("total_minutes_prayed")}
        loading={!queryCommunitiesTotalsOverTime.data}
      />
      <StatisticCard
        value={format.number(queryCommunitiesTotalsOverTime.data?.member_count)}
        label={t("total_members")}
        loading={!queryCommunitiesTotalsOverTime.data}
      />
      <StatisticCard
        value={format.number(
          queryCommunitiesTotalsOverTime.data?.intentions_count,
        )}
        label={t("total_intentions")}
        loading={!queryCommunitiesTotalsOverTime.data}
      />
    </div>
  );
};

CommunityTotalsStatisticCards.displayName = "CommunityTotalsStatisticCards";
