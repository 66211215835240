"use client";

import { useTranslations } from "@packages/i18n";
import { useCurrentQueueItem, usePlayer } from "@packages/media";
import * as stylex from "@stylexjs/stylex";
import { useCallback, useEffect, useState } from "react";

import {
  numericPercentages,
  numericPixels,
} from "../../../../../../global/stylex/vars.stylex";
import {
  Button,
  Minus10Icon,
  RewindIcon,
  Transition,
} from "../../../../../components";
import { TransitionRoot } from "../../../../../providers";
import type { StyleXArray, WithButtonWrapper } from "../../../../../types";
import { MediaButton } from "../MediaButton";

export type BackMediaButtonProps = WithButtonWrapper<{
  canRestart: boolean;
  restartStyleXArray?: StyleXArray;
  backStyleXArray?: StyleXArray;
}>;

const styles = stylex.create({
  container: {
    display: "flex",
    placeItems: "center",
    width: numericPixels[40],
  },
  buttonWrapper: {
    left: "revert",
    position: "absolute",
    right: 0,
    top: numericPercentages[50],
    transform: "translateY(-50%)",
  },
  containerEXPANDED: {
    width: numericPixels[80],
  },
  restartExiting: {
    opacity: 0,
  },
  restartEntering: {
    opacity: 0,
  },
  restartEntered: {
    opacity: 1,
  },
});

export const BackMediaButton = ({
  canRestart,
  Wrapper = ({ children }) => <>{children}</>,
  backStyleXArray = [],
  restartStyleXArray = [],
}: BackMediaButtonProps) => {
  const [value, setValue] = useState<"restart" | "back">(
    canRestart ? "restart" : "back",
  );
  const t = useTranslations();
  const player = usePlayer();
  const { currentItem } = useCurrentQueueItem();

  useEffect(() => {
    setValue(canRestart ? "restart" : "back");
  }, [canRestart]);

  const handleBack = useCallback(
    (e) => {
      e.stopPropagation();
      player?.skipBack();
    },
    [player],
  );

  const handleRestart = useCallback(
    (e) => {
      e.stopPropagation();
      player?.restart();
    },
    [player],
  );

  return (
    <>
      <TransitionRoot
        value={value}
        defaultValue={canRestart ? "restart" : "back"}
        styleXArray={[
          styles.container,
          styles[`container${player.display.toUpperCase()}`],
        ]}
      >
        <Transition
          value={"restart"}
          enteringStyleXArray={[styles.buttonWrapper, styles.restartEntering]}
          enteredStyleXArray={[styles.buttonWrapper, styles.restartEntered]}
          exitingStyleXArray={[styles.buttonWrapper, styles.restartExiting]}
          exitedStyleXArray={[styles.buttonWrapper, styles.restartExiting]}
        >
          <Wrapper asChild>
            <Button
              variant={"lighten"}
              onClick={handleRestart}
              styleXArray={restartStyleXArray}
              size={"s"}
            >
              {t("audio_player_restart")}
            </Button>
          </Wrapper>
        </Transition>
        <Transition value={"back"}>
          <Wrapper asChild>
            <MediaButton
              largeIcon={
                currentItem?.selected_audio?.show_music_controls ? (
                  <RewindIcon.XL />
                ) : (
                  <Minus10Icon.XL />
                )
              }
              smallIcon={
                currentItem?.selected_audio?.show_music_controls ? (
                  <RewindIcon.Small />
                ) : (
                  <Minus10Icon.Medium />
                )
              }
              styleXArray={backStyleXArray}
              onClick={handleBack}
              tabIndex={0}
            />
          </Wrapper>
        </Transition>
      </TransitionRoot>
    </>
  );
};
