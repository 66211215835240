"use client";

import * as stylex from "@stylexjs/stylex";
import { createRef, useEffect, useRef, useState } from "react";

import { voxAnimation } from "../../../../../global/stylex/vars.stylex";
import { END_OF_BROWSER_ASYNC_QUEUE } from "../../../../lib";
import type { WithStylexArray } from "../../../../types";

const BASE_HEIGHT = 2;
const TRANSITION_MS = 250;

const dotStyles = stylex.create({
  dot: {
    animationDuration: "4s",
    animationIterationCount: "infinite",
    animationTimingFunction: "ease-out",
    transitionDuration: `${TRANSITION_MS}ms`,
    transitionProperty: "height, transform",
    transitionTimingFunction: "ease-out",
    willChange: "height, transform",
  },
  paused: {
    animationPlayState: "paused",
  },
  stopped: {
    animationName: "none",
  },
  dot1: {
    animationName: voxAnimation.voxDot1,
  },
  dot2: {
    animationName: voxAnimation.voxDot2,
  },
  dot3: {
    animationName: voxAnimation.voxDot3,
  },
  dot4: {
    animationName: voxAnimation.voxDot4,
  },
  dot5: {
    animationName: voxAnimation.voxDot5,
  },
});

export type VoxProps = WithStylexArray<{
  playing: boolean;
  size?: number;
  fill?: string;
}>;

export const VoxIcon = ({
  playing,
  size = 24,
  fill = "white",
  styleXArray,
}: VoxProps) => {
  const [running, setRunning] = useState<boolean>(playing);
  const [unanimated, setUnanimated] = useState<boolean>(!playing);
  const dotRefs = [
    createRef<SVGRectElement>(),
    createRef<SVGRectElement>(),
    createRef<SVGRectElement>(),
    createRef<SVGRectElement>(),
    createRef<SVGRectElement>(),
  ];
  const resumeRef1 = useRef<number>(BASE_HEIGHT);
  const resumeRef2 = useRef<number>(BASE_HEIGHT);
  const resumeRef3 = useRef<number>(BASE_HEIGHT);
  const resumeRef4 = useRef<number>(BASE_HEIGHT);
  const resumeRef5 = useRef<number>(BASE_HEIGHT);
  const [dot1PauseHeight, setDot1PauseHeight] = useState<number>(BASE_HEIGHT);
  const [dot2PauseHeight, setDot2PauseHeight] = useState<number>(BASE_HEIGHT);
  const [dot3PauseHeight, setDot3PauseHeight] = useState<number>(BASE_HEIGHT);
  const [dot4PauseHeight, setDot4PauseHeight] = useState<number>(BASE_HEIGHT);
  const [dot5PauseHeight, setDot5PauseHeight] = useState<number>(BASE_HEIGHT);

  useEffect(() => {
    if (!!dotRefs[0].current) {
      if (playing) {
        setDot1PauseHeight(resumeRef1.current);
        setDot2PauseHeight(resumeRef2.current);
        setDot3PauseHeight(resumeRef3.current);
        setDot4PauseHeight(resumeRef4.current);
        setDot5PauseHeight(resumeRef5.current);
        setTimeout(() => {
          setUnanimated(false);
          setTimeout(() => {
            setRunning(true);
          }, END_OF_BROWSER_ASYNC_QUEUE);
        }, TRANSITION_MS);
      } else {
        setRunning(false);
        resumeRef1.current = dotRefs[0].current.getBBox().height;
        setDot1PauseHeight(resumeRef1.current);
        resumeRef2.current = dotRefs[1].current.getBBox().height;
        setDot2PauseHeight(resumeRef2.current);
        resumeRef3.current = dotRefs[2].current.getBBox().height;
        setDot3PauseHeight(resumeRef3.current);
        resumeRef4.current = dotRefs[3].current.getBBox().height;
        setDot4PauseHeight(resumeRef4.current);
        resumeRef5.current = dotRefs[4].current.getBBox().height;
        setDot5PauseHeight(resumeRef5.current);
        setTimeout(() => {
          setUnanimated(true);
          setTimeout(() => {
            setDot1PauseHeight(BASE_HEIGHT);
            setDot2PauseHeight(BASE_HEIGHT);
            setDot3PauseHeight(BASE_HEIGHT);
            setDot4PauseHeight(BASE_HEIGHT);
            setDot5PauseHeight(BASE_HEIGHT);
          }, END_OF_BROWSER_ASYNC_QUEUE);
        }, END_OF_BROWSER_ASYNC_QUEUE);
      }
    }
  }, [playing]);

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...stylex.props(styleXArray)}
    >
      <rect
        ref={dotRefs[0]}
        {...stylex.props(
          dotStyles.dot,
          dotStyles.dot1,
          running ? null : dotStyles.paused,
          unanimated ? dotStyles.stopped : null,
        )}
        style={
          running
            ? null
            : {
                height: `${dot1PauseHeight}px`,
                transform: `translateY(-${(dot1PauseHeight - 2) / 2}px)`,
              }
        }
        x="3"
        y="11"
        width="2"
        height="2"
        rx="1"
        fill={fill}
      />
      <rect
        ref={dotRefs[1]}
        {...stylex.props(
          dotStyles.dot,
          dotStyles.dot2,
          running ? null : dotStyles.paused,
          unanimated ? dotStyles.stopped : null,
        )}
        style={
          running
            ? null
            : {
                height: `${dot2PauseHeight}px`,
                transform: `translateY(-${(dot2PauseHeight - 2) / 2}px)`,
              }
        }
        x="7"
        y="11"
        width="2"
        height="2"
        rx="1"
        fill={fill}
      />
      <rect
        ref={dotRefs[2]}
        {...stylex.props(
          dotStyles.dot,
          dotStyles.dot3,
          running ? null : dotStyles.paused,
          unanimated ? dotStyles.stopped : null,
        )}
        style={
          running
            ? null
            : {
                height: `${dot3PauseHeight}px`,
                transform: `translateY(-${(dot3PauseHeight - 2) / 2}px)`,
              }
        }
        x="11"
        y="11"
        width="2"
        height="2"
        rx="1"
        fill={fill}
      />
      <rect
        ref={dotRefs[3]}
        {...stylex.props(
          dotStyles.dot,
          dotStyles.dot4,
          running ? null : dotStyles.paused,
          unanimated ? dotStyles.stopped : null,
        )}
        style={
          running
            ? null
            : {
                height: `${dot4PauseHeight}px`,
                transform: `translateY(-${(dot4PauseHeight - 2) / 2}px)`,
              }
        }
        x="15"
        y="11"
        width="2"
        height="2"
        rx="1"
        fill={fill}
      />
      <rect
        ref={dotRefs[4]}
        {...stylex.props(
          dotStyles.dot,
          dotStyles.dot5,
          running ? null : dotStyles.paused,
          unanimated ? dotStyles.stopped : null,
        )}
        style={
          running
            ? null
            : {
                height: `${dot5PauseHeight}px`,
                transform: `translateY(-${(dot5PauseHeight - 2) / 2}px)`,
              }
        }
        x="19"
        y="11"
        width="2"
        height="2"
        rx="1"
        fill={fill}
      />
    </svg>
  );
};
