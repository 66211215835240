"use client";

import { useLocalStorageState, useRequestTranscript } from "@packages/sdk";
import type { UseRequestQueryProps } from "@packages/sdk/src/request";
import * as stylex from "@stylexjs/stylex";
import type {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  UIEvent,
} from "react";
import { useCallback, useEffect, useRef } from "react";

import { themes } from "../../../../../global/stylex/themes.stylex";
import {
  fontSizes,
  numericPercentages,
  numericPixels,
  semanticColors,
  spacing,
} from "../../../../../global/stylex/vars.stylex";
import { Loader, ScrollArea, Text } from "../../../../components";
import type { StyleXArray, WithStylexArray } from "../../../../types";
import { Theme } from "../../../../types";
import { ReaderThumbnail, TextSettings } from "../../blocks";

const ON_DESKTOP = "@media screen and (min-width: 768px)";

const styles = stylex.create({
  body: {
    fontFamily: "Hallow Moderat Serif, serif",
    lineHeight: 2,
    marginTop: {
      [ON_DESKTOP]: "280px",
      default: "210px",
    },
    maxWidth: "640px",
    padding: {
      [ON_DESKTOP]: `${spacing.l} ${spacing.l} ${spacing.mediaPlayerDesktop}`,
      default: `${spacing.l} ${spacing.l} ${spacing.mediaPlayerMobile}`,
    },
    transitionDuration: "250ms",
    transitionProperty: "font-size, line-height",
    transitionTimingFunction: "ease-out",
    whiteSpace: "pre-line",
    willChange: "background-color",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "nowrap",
    placeContent: "flex-start",
    placeItems: "center",
    position: "relative",
  },
  fontS: {
    fontSize: fontSizes.tosS,
  },
  fontM: {
    fontSize: fontSizes.tosM,
  },
  fontL: {
    fontSize: fontSizes.tosL,
  },
  immersive: {
    backgroundColor: "var(--special-color)",
  },
  scrollArea: {
    height: "100vh",
    width: "100vw",
  },
});

const headerStyles = stylex.create({
  default: {
    background: semanticColors.background,
    display: "flex",
    flexDirection: "column",
    flexWrap: "nowrap",
    padding: {
      [ON_DESKTOP]: "144px 0 0",
      default: "76px 0 0",
    },
    placeContent: "flex-start",
    placeItems: "center",
    pointerEvents: "none",
    position: "fixed",
    transition: "top 250ms ease-out",
    width: numericPercentages[100],
    willChange: "top",
  },
  stage0: {
    top: 0,
  },
  stage1: {
    top: {
      [ON_DESKTOP]: "-162px",
      default: "-94px",
    },
  },
});

const maskStyles = stylex.create({
  default: {
    position: "absolute",
    transition: "top 250ms ease-out",
    willChange: "top",
  },
  stage0: {
    top: {
      [ON_DESKTOP]: "258px",
      default: "192px",
    },
  },
  stage1: {
    top: "108px",
  },
});

const headingStyles = stylex.create({
  default: {
    marginBottom: spacing.m,
    marginTop: spacing.m,
  },
});

const thumbnailContainerStyles = stylex.create({
  default: {
    display: "flex",
    height: numericPixels[80],
    placeContent: "center",
    placeItems: "flex-end",
    width: numericPixels[80],
  },
});

export type TextOnScreenContentProps = {
  audioId: number | null;
  scrollStage: 0 | 1;
  setScrollStage: Dispatch<SetStateAction<0 | 1>>;
  immersive: "immersive" | "default";
  setImmersive: Dispatch<SetStateAction<"immersive" | "default">>;
  playing: boolean;
  play: () => Promise<void>;
  isCurrentItem: boolean;
  headerProps?: WithStylexArray<
    PropsWithChildren<{
      hideSettings?: boolean;
    }>
  >;
  bodyStyleXArray?: StyleXArray;
  onAuthError?: (error: Response | DOMException | Error) => void;
  onColorDetermination?: (color: string) => void;
  t: (key: string, values?: any, formats?: any) => string;
  mobileApp?: boolean;
  queryProps?: UseRequestQueryProps;
  theme?: Theme;
};

export const TextOnScreenContent = ({
  audioId,
  scrollStage,
  setScrollStage,
  immersive,
  setImmersive,
  playing,
  play,
  isCurrentItem,
  onAuthError,
  onColorDetermination,
  headerProps: {
    children: headerChildren,
    hideSettings,
    styleXArray: headerStyleXArray,
  } = { children: null, hideSettings: false, styleXArray: [] },
  bodyStyleXArray = [],
  t,
  mobileApp = false,
  queryProps = {},
  theme,
}: TextOnScreenContentProps) => {
  const [fontSize, setFontSize] = useLocalStorageState<"s" | "m" | "l">({
    key: "tos-size",
    defaultValue: "m",
  });
  const lastScrollTop = useRef<number>(0);
  const { query: transcriptQuery } = useRequestTranscript({
    id: audioId,
    enabled: !!audioId,
    ...queryProps,
  });

  const handleScroll = useCallback(
    (e: UIEvent<HTMLDivElement>) => {
      // why the typecast? Because UIEvent<T> adds `currentTarget` to the event type as T, but scroll events don't
      // have a currentTarget property
      const scrollTop = (e.target as HTMLDivElement).scrollTop ?? 0;
      if (scrollTop > 75) {
        setScrollStage(1);
      } else {
        setScrollStage(0);
      }
      lastScrollTop.current = scrollTop;
    },
    [scrollStage],
  );

  useEffect(() => {
    if (transcriptQuery?.data && onColorDetermination) {
      onColorDetermination(transcriptQuery.data.images?.color_hex ?? "#171717");
    }
  }, [transcriptQuery?.data, onColorDetermination]);

  if (transcriptQuery.isPending) {
    return <Loader />;
  }

  if (transcriptQuery.isError) {
    console.error(transcriptQuery.error);
    onAuthError?.(transcriptQuery.error);
    return null;
  }

  if (!transcriptQuery.isSuccess) return null;

  if (!audioId) return null;

  const {
    data: { title, images, transcript },
  } = transcriptQuery;

  const thumbnail = (
    <div {...stylex.props(thumbnailContainerStyles.default)}>
      <ReaderThumbnail
        activated={isCurrentItem}
        imageSrc={images}
        playing={playing}
        scrollStage={scrollStage}
        title={title}
        onClick={play}
      />
    </div>
  );

  const body = (
    <article
      {...stylex.props(
        styles.body,
        mobileApp ? null : styles[`font${fontSize.toUpperCase()}`],
        bodyStyleXArray,
      )}
    >
      {transcript ?? t("no_transcript_available_text")}
    </article>
  );

  const headingTitle = (
    <div className={`stage${scrollStage}`}>
      <Text
        as={"h1"}
        type={"title"}
        size={scrollStage > 0 ? "xs" : "l"}
        styleXArray={[headingStyles.default]}
      >
        {title}
      </Text>
    </div>
  );

  const header = (
    <header
      {...stylex.props(
        headerStyles.default,
        immersive === "immersive" ? styles.immersive : null,
        headerStyles[`stage${scrollStage}`],
        headerStyleXArray,
      )}
    >
      {headerChildren}
      {thumbnail}
      {headingTitle}
      {hideSettings ? null : (
        <TextSettings
          fontSize={fontSize}
          setFontSize={setFontSize}
          immersive={immersive}
          setImmersive={setImmersive}
          scrollStage={scrollStage}
        />
      )}
    </header>
  );

  const content = (
    <div {...stylex.props(styles.container)}>
      {header}
      {body}
    </div>
  );

  return (
    <ScrollArea
      viewportProps={{ onScroll: handleScroll }}
      maskBottom
      maskTop
      maskTopStyleXArray={[
        maskStyles.default,
        maskStyles[`stage${scrollStage}`],
      ]}
      masksColor={
        immersive === "immersive"
          ? ((images.color_hex ?? "#171717") as `#${string}`)
          : theme === Theme.DARK
            ? "#171717"
            : undefined
      }
      styleXArray={[styles.scrollArea]}
      scrollbarVerticalStyleXArray={[
        theme === Theme.DARK ? themes.dark : null,
        immersive === "immersive" ? styles.immersive : null,
      ]}
    >
      {content}
    </ScrollArea>
  );
};
