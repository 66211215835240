"use client";

import * as RadixUIScrollArea from "@radix-ui/react-scroll-area";
import * as stylex from "@stylexjs/stylex";
import type { ComponentProps, MutableRefObject } from "react";
import { forwardRef } from "react";

import {
  colors,
  radius,
  semanticColors,
  spacing,
  stroke,
  zIndices,
} from "../../../../../global/stylex/vars.stylex";
import type { UseScrollAreaProps } from "../../../../hooks";
import { useLocalRef, useScrollArea } from "../../../../hooks";
import type { StyleXArray, WithStylexArray } from "../../../../types";
import type { MaskProps } from "../../../_base";
import { Mask } from "../../../_base";

const styles = stylex.create({
  mask: {
    pointerEvents: "none",
    position: "absolute",
    transition: "opacity 250ms ease-out",
    zIndex: zIndices.mask,
  },
  maskBottom: (options: { canScrollBottom: boolean }) => ({
    bottom: spacing.none,
    opacity: options.canScrollBottom ? 1 : 0,
  }),
  maskLeft: (options: { canScrollLeft: boolean }) => ({
    left: spacing.none,
    opacity: options.canScrollLeft ? 1 : 0,
    top: spacing.none,
  }),
  maskRight: (options: { canScrollRight: boolean }) => ({
    opacity: options.canScrollRight ? 1 : 0,
    right: spacing.none,
    top: spacing.none,
  }),
  maskTop: (options: { canScrollTop: boolean }) => ({
    opacity: options.canScrollTop ? 1 : 0,
    top: spacing.none,
  }),
  root: {
    overflow: "hidden",
    position: "relative",
  },
  scrollbar: {
    backgroundColor: {
      default: colors.transparent,
      ":hover": semanticColors.neutralsLowest,
    },
    display: "flex",
    touchAction: "none",
    transition:
      "background 200ms ease-out, border-color 200ms ease-out, opacity 200ms ease-out",
    userSelect: "none",
    zIndex: zIndices.scrollbar,
  },
  scrollbarHorizontal: {
    borderTopColor: {
      default: colors.transparent,
      ":hover": semanticColors.neutralsLower,
    },
    borderTopStyle: "solid",
    borderTopWidth: stroke.light,
    paddingBottom: spacing.xxs,
    paddingLeft: spacing.xs,
    paddingRight: spacing.xs,
    paddingTop: spacing.xxs,
  },
  scrollbarOpacity: (options: { opacity: number }) => ({
    opacity: options.opacity,
  }),
  scrollbarOpacityWithHover: (options: { opacity: number }) => ({
    opacity: {
      default: options.opacity,
      ":hover": 1,
    },
  }),
  scrollbarVertical: {
    borderLeftColor: {
      default: colors.transparent,
      ":hover": semanticColors.neutralsLower,
    },
    borderLeftStyle: "solid",
    borderLeftWidth: stroke.light,
    paddingBottom: spacing.t,
    paddingLeft: spacing.xxs,
    paddingRight: spacing.xxs,
    paddingTop: spacing.t,
  },
  thumb: {
    background: semanticColors.neutralsMedium,
    borderRadius: radius.full,
  },
  thumbHorizontal: {
    minHeight: spacing.xs,
  },
  thumbVertical: {
    minWidth: spacing.xs,
  },
  viewport: {
    height: spacing.full,
    position: "relative",
    width: spacing.full,
  },
});

export type ScrollAreaProps = WithStylexArray<
  Omit<ComponentProps<typeof RadixUIScrollArea.Root>, "type">
> & {
  dependencies?: UseScrollAreaProps["dependencies"];
  horizontal?: boolean;
  maskBottom?: boolean;
  maskBottomProps?: MaskProps;
  maskBottomStyleXArray?: StyleXArray;
  maskLeft?: boolean;
  maskLeftProps?: MaskProps;
  maskLeftStyleXArray?: StyleXArray;
  maskRight?: boolean;
  maskRightProps?: MaskProps;
  maskRightStyleXArray?: StyleXArray;
  masksColor?: MaskProps["color"];
  masksSize?: MaskProps["size"];
  maskTop?: boolean;
  maskTopProps?: MaskProps;
  maskTopStyleXArray?: StyleXArray;
  scrollbarHorizontalProps?: ComponentProps<typeof RadixUIScrollArea.Scrollbar>;
  scrollbarHorizontalStyleXArray?: StyleXArray;
  scrollbarVerticalProps?: ComponentProps<typeof RadixUIScrollArea.Scrollbar>;
  scrollbarVerticalStyleXArray?: StyleXArray;
  showScrollbarsOnHover?: boolean;
  tableMarginAuto?: boolean;
  thumbHorizontalProps?: ComponentProps<typeof RadixUIScrollArea.Thumb>;
  thumbHorizontalStyleXArray?: StyleXArray;
  thumbVerticalProps?: ComponentProps<typeof RadixUIScrollArea.Thumb>;
  thumbVerticalStyleXArray?: StyleXArray;
  vertical?: boolean;
  viewportProps?: ComponentProps<typeof RadixUIScrollArea.Viewport>;
  viewportStyleXArray?: StyleXArray;
};

export const ScrollArea = forwardRef<HTMLDivElement, ScrollAreaProps>(
  (
    {
      children,
      dependencies = [],
      horizontal = true,
      maskBottom,
      maskBottomProps,
      maskBottomStyleXArray,
      maskLeft,
      maskLeftProps,
      maskLeftStyleXArray,
      maskRight,
      maskRightProps,
      maskRightStyleXArray,
      masksColor,
      masksSize,
      maskTop,
      maskTopProps,
      maskTopStyleXArray,
      scrollbarHorizontalProps,
      scrollbarHorizontalStyleXArray,
      scrollbarVerticalProps,
      scrollbarVerticalStyleXArray,
      showScrollbarsOnHover = false,
      styleXArray,
      tableMarginAuto = false,
      thumbHorizontalProps,
      thumbHorizontalStyleXArray,
      thumbVerticalProps,
      thumbVerticalStyleXArray,
      vertical = true,
      viewportProps,
      viewportStyleXArray,
      ...props
    },
    ref,
  ) => {
    const viewportRef = useLocalRef(ref);

    const {
      canScrollBottom,
      canScrollLeft,
      canScrollRight,
      canScrollTop,
      scrollingX,
      scrollingY,
    } = useScrollArea({
      dependencies,
      viewportRef:
        (viewportProps?.ref as MutableRefObject<HTMLElement>) ?? viewportRef,
    });

    return (
      <RadixUIScrollArea.Root
        type="scroll"
        {...props}
        {...stylex.props(styles.root, styleXArray)}
      >
        {maskBottom && (
          <Mask
            direction="to top"
            styleXArray={[
              styles.mask,
              styles.maskBottom({ canScrollBottom }),
              maskBottomStyleXArray,
            ]}
            {...{ color: masksColor, size: masksSize, ...maskBottomProps }}
          />
        )}
        {maskLeft && (
          <Mask
            direction="to right"
            orientation="vertical"
            styleXArray={[
              styles.mask,
              styles.maskLeft({ canScrollLeft }),
              maskLeftStyleXArray,
            ]}
            {...{ color: masksColor, size: masksSize, ...maskLeftProps }}
          />
        )}
        {maskRight && (
          <Mask
            direction="to left"
            orientation="vertical"
            styleXArray={[
              styles.mask,
              styles.maskRight({ canScrollRight }),
              maskRightStyleXArray,
            ]}
            {...{ color: masksColor, size: masksSize, ...maskRightProps }}
          />
        )}
        {maskTop && (
          <Mask
            styleXArray={[
              styles.mask,
              styles.maskTop({ canScrollTop }),
              maskTopStyleXArray,
            ]}
            {...{ color: masksColor, size: masksSize, ...maskTopProps }}
          />
        )}
        <RadixUIScrollArea.Viewport
          data-scroll-area-table-margin-auto={tableMarginAuto ? "" : null}
          {...{ ...viewportProps, ref: viewportProps?.ref ?? viewportRef }}
          {...stylex.props(styles.viewport, viewportStyleXArray)}
        >
          {children}
        </RadixUIScrollArea.Viewport>
        {horizontal && (
          <RadixUIScrollArea.Scrollbar
            forceMount
            orientation="horizontal"
            {...scrollbarHorizontalProps}
            {...stylex.props(
              styles.scrollbar,
              styles.scrollbarHorizontal,
              styles[
                showScrollbarsOnHover
                  ? "scrollbarOpacityWithHover"
                  : "scrollbarOpacity"
              ]({ opacity: scrollingX ? 1 : 0 }),
              scrollbarHorizontalStyleXArray,
            )}
          >
            <RadixUIScrollArea.Thumb
              {...thumbHorizontalProps}
              {...stylex.props(
                styles.thumb,
                styles.thumbHorizontal,
                thumbHorizontalStyleXArray,
              )}
            />
          </RadixUIScrollArea.Scrollbar>
        )}
        {vertical && (
          <RadixUIScrollArea.Scrollbar
            forceMount
            orientation="vertical"
            {...scrollbarVerticalProps}
            {...stylex.props(
              styles.scrollbar,
              styles[
                showScrollbarsOnHover
                  ? "scrollbarOpacityWithHover"
                  : "scrollbarOpacity"
              ]({ opacity: scrollingY ? 1 : 0 }),
              styles.scrollbarVertical,
              scrollbarVerticalStyleXArray,
            )}
          >
            <RadixUIScrollArea.Thumb
              {...thumbVerticalProps}
              {...stylex.props(
                styles.thumb,
                styles.thumbVertical,
                thumbVerticalStyleXArray,
              )}
            />
          </RadixUIScrollArea.Scrollbar>
        )}
      </RadixUIScrollArea.Root>
    );
  },
);

ScrollArea.displayName = "ScrollArea";
