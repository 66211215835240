"use client";

import { HallowAnalyticsEvent, useAnalytics } from "@packages/analytics";
import {
  getContentDescription,
  getContentImage,
  getContentTitle,
  usePlayer,
} from "@packages/media";
import type { QueueManagedItem } from "@packages/sdk";
import * as stylex from "@stylexjs/stylex";
import { forwardRef, useCallback } from "react";
import type { DragOptions } from "react-aria";
import { DragPreview } from "react-aria";

import {
  numericPercentages,
  semanticColors,
  spacing,
} from "../../../../../../global/stylex/vars.stylex";
import {
  ContentSmall,
  MediaMoreMenu,
  VoxIcon,
} from "../../../../../components";
import { useQueueItem } from "../../../../hooks";
import { QueueHandle } from "../../controls";

const itemStyles = stylex.create({
  default: {
    padding: `${spacing.ms} ${spacing.m}`,
    scrollSnapAlign: "none",
    width: numericPercentages[100],
  },
  vox: {
    left: numericPercentages[50],
    position: "absolute",
    top: numericPercentages[50],
    transform: "translate(-50%, -50%)",
  },
  ghost: {
    color: semanticColors.onColorVeryHigh,
    filter: "saturate(0.5)",
  },
  clickable: {
    cursor: "pointer",
  },
});

export type QueueItemProps = Pick<
  DragOptions,
  "onDragStart" | "onDragMove" | "onDragEnd"
> & {
  variant: "past" | "current" | "future" | "repeat";
  item: QueueManagedItem | null;
};

export const QueueItem = forwardRef<HTMLDivElement, QueueItemProps>(
  ({ onDragStart, onDragMove, onDragEnd, variant, item }, ref) => {
    const getItems = useCallback(
      () => [
        {
          "text/json": JSON.stringify(item),
        },
      ],
      [item],
    );

    const { handleProps, containerProps, childProps, previewRef } =
      useQueueItem({ onDragStart, onDragMove, onDragEnd, getItems });
    const analytics = useAnalytics();

    const player = usePlayer();

    if (!item) {
      throw "Tried to include a queue item with no content";
    }

    let rightAction = null;

    if (item.type === "prayer") {
      if (variant === "current") {
        rightAction = <MediaMoreMenu content={item} />;
      } else if (variant === "future") {
        rightAction = <QueueHandle {...handleProps} />;
      }
    }

    return (
      <>
        <ContentSmall
          ref={ref}
          key={item.uuid}
          size={variant === "current" ? "s" : "xs"}
          imgSrc={getContentImage(item)}
          imgOverlay={
            variant === "current" ? (
              <VoxIcon
                playing={!!player && !player.paused}
                styleXArray={[itemStyles.vox]}
              />
            ) : null
          }
          title={getContentTitle(item)}
          description={getContentDescription(item)}
          rightActions={[rightAction]}
          disabled={variant !== "future" || item.type === "radio_song"}
          onClick={() => {
            if (variant === "future" && item.type !== "radio_song") {
              analytics.track({
                event: HallowAnalyticsEvent.TappedQueue,
                properties: {
                  content_type: "prayer",
                  content_id: item.prayer.id,
                },
              });
              return player.goToQueueId(item.uuid);
            }
            return Promise.resolve();
          }}
          {...(variant === "future" ? containerProps : {})}
          styleXArray={[
            itemStyles.default,
            variant === "repeat" ? itemStyles.ghost : null,
            variant === "future" && item.type !== "radio_song"
              ? itemStyles.clickable
              : null,
          ]}
        />
        {variant === "future" ? (
          <DragPreview
            ref={previewRef}
            key={`${item.selected_audio.id}_preview`}
          >
            {(items) => {
              const itm = JSON.parse(items[0]["text/json"]) as QueueManagedItem;
              return <div>{getContentTitle(itm)}</div>;
            }}
          </DragPreview>
        ) : null}
      </>
    );
  },
);

QueueItem.displayName = "QueueItem";
