"use client";

import { HallowAnalyticsEvent, useAnalytics } from "@packages/analytics";
import { useTranslations } from "@packages/i18n";
import { getContentId, useCurrentQueueItem, usePlayer } from "@packages/media";
import {
  useLocalStorageState,
  useRequestPrayer,
  useRequestTranscript,
} from "@packages/sdk";
import * as stylex from "@stylexjs/stylex";
import { type MouseEventHandler, useState } from "react";

import { themes } from "../../../../../global/stylex/themes.stylex";
import {
  semanticColors,
  spacing,
  zIndices,
} from "../../../../../global/stylex/vars.stylex";
import {
  FullscreenModal,
  IconButton,
  TextOnScreenIcon,
} from "../../../../components";
import { stylexDynamics } from "../../../../lib";
import { useTheme } from "../../../../theme";
import type { WithButtonWrapper, WithStylexArray } from "../../../../types";
import { getRGBAFromColor } from "../../../../utils";
import { TextOnScreenContent } from "./TextOnScreenContent";

const ON_DESKTOP = "@media screen and (min-width: 768px)";

const styles = stylex.create({
  modal: {
    color: semanticColors.primary,
    padding: 0,
    zIndex: zIndices.transcript,
  },
  establishBackground: (bgColor: string) => ({
    "special-color": bgColor,
  }),
});

// we put a simple z-index here because the layer context of this button is INSIDE the fullscreen-modal,
// so we only need it on top of its parent
const closeButtonStyles = stylex.create({
  default: {
    left: {
      [ON_DESKTOP]: "80px",
      default: spacing.m,
    },
    opacity: 1,
    pointerEvents: "auto",
    position: "fixed",
    top: {
      [ON_DESKTOP]: "80px",
      default: spacing.m,
    },
    transition: "opacity 250ms ease-out",
    zIndex: 1,
  },
});

export type TextOnScreenProps = WithStylexArray<
  WithButtonWrapper<{
    id?: number;
    prayerId?: number;
    iconColor?: string;
    onClick?: MouseEventHandler<HTMLButtonElement>;
    open: boolean;
    onOpenChange: (open: boolean) => void;
  }>
>;

export const TextOnScreen = ({
  id,
  prayerId,
  iconColor,
  Wrapper = ({ children }) => <>{children}</>,
  styleXArray,
  onClick,
  open,
  onOpenChange,
}: TextOnScreenProps) => {
  const {
    query: { data: prayerData },
  } = useRequestPrayer({ id: prayerId });
  const {
    query: { data: transcriptData },
  } = useRequestTranscript({ id });
  const analytics = useAnalytics();
  const t = useTranslations();

  const [immersive, setImmersive] = useLocalStorageState<
    "immersive" | "default"
  >({ key: "tos-immersive", defaultValue: "default" });
  const [scrollStage, setScrollStage] = useState<0 | 1>(0);
  const { currentItem } = useCurrentQueueItem();
  const player = usePlayer();
  const { theme } = useTheme();

  if (!prayerData) return null;
  if (!transcriptData) return null;

  const { images } = prayerData;
  const { transcript } = transcriptData;

  if (!transcript?.length)
    return (
      <Wrapper asChild>
        <IconButton
          variant={"transparentWhite"}
          icon={<TextOnScreenIcon fill={iconColor ? iconColor : null} />}
          disabled={true}
          title={t("no_transcript_available_text")}
        />
      </Wrapper>
    );

  return (
    <FullscreenModal
      trigger={
        <Wrapper asChild>
          <IconButton
            variant={"transparentWhite"}
            icon={<TextOnScreenIcon fill={iconColor ? iconColor : null} />}
            onClick={onClick}
            onClickCapture={() =>
              analytics.track({
                event: HallowAnalyticsEvent.TappedTextOnScreen,
                properties: {
                  content_id: getContentId(currentItem),
                  content_type: currentItem.type,
                  has_transcript: !!transcriptData?.transcript,
                },
              })
            }
            title={t("text_on_screen_read_along")}
            tabIndex={0}
          />
        </Wrapper>
      }
      open={open}
      onOpenChange={onOpenChange}
      modal={true}
      contentProps={{
        onInteractOutside: (e) => e.preventDefault(),
      }}
      closeStyleXArray={[closeButtonStyles.default]}
      contentStyleXArray={[
        styles.modal,
        immersive === "immersive" ? themes.dark : themes[theme],
        immersive === "immersive"
          ? stylexDynamics.backgroundColorFromRGBAObject(
              getRGBAFromColor({
                color: images.color_hex ?? "#000000",
                format: "object",
              }),
            )
          : null,
        immersive === "immersive"
          ? styles.establishBackground(images.color_hex ?? "#000000")
          : null,
        styleXArray,
      ]}
    >
      <TextOnScreenContent
        immersive={immersive}
        setImmersive={setImmersive}
        scrollStage={scrollStage}
        setScrollStage={setScrollStage}
        audioId={id}
        playing={
          currentItem?.type === "prayer" &&
          currentItem.selected_audio.id === id &&
          !!player &&
          !player.paused
        }
        play={() => player?.playNew({ type: "audio", id })}
        isCurrentItem={
          currentItem?.type === "prayer" && currentItem.selected_audio.id === id
        }
        t={t}
        theme={theme}
      />
    </FullscreenModal>
  );
};
