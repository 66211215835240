"use client";

import * as SelectPrimitive from "@radix-ui/react-select";
import stylex from "@stylexjs/stylex";
import * as React from "react";

import { spacing } from "../../../../../global/stylex/vars.stylex";
import { ChevronDownIcon } from "../../../_base/icons";

const styles = stylex.create({
  scrollButton: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    paddingBottom: spacing.s,
    paddingTop: spacing.s,
  },
});

type SelectScrollDownButtonProps = React.ComponentPropsWithoutRef<
  typeof SelectPrimitive.ScrollDownButton
>;

export const SelectScrollDownButton = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.ScrollDownButton>,
  SelectScrollDownButtonProps
>((props, ref) => (
  <SelectPrimitive.ScrollDownButton
    ref={ref}
    {...stylex.props(styles.scrollButton)}
    {...props}
  >
    <ChevronDownIcon />
  </SelectPrimitive.ScrollDownButton>
));

SelectScrollDownButton.displayName = "SelectScrollDownButton";
