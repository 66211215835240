"use client";

import { useCurrentGuide, usePlayer } from "@packages/media";
import type { Track } from "@packages/sdk";

import { ModalDropdownMenuItem } from "../../../../../components";
import { GuideInfo } from "../../data";

export const SelectGuideContent = () => {
  const { currentItem, guides } = useCurrentGuide();
  const player = usePlayer();

  if (currentItem?.type === "radio_song") return null;

  const options: Track[] = [];
  for (const guide of guides) {
    let closestTimeDiff: number = 1000000;
    let closestTimeTrack: Track | null = null;
    for (const track of currentItem.prayer.tracks) {
      if (track.guide_id !== guide.id) continue;
      const timeDiff = Math.abs(
        track.duration - currentItem.selected_audio.duration,
      );
      if (timeDiff < closestTimeDiff) {
        closestTimeDiff = timeDiff;
        closestTimeTrack = track;
      }
    }
    if (closestTimeTrack) options.push(closestTimeTrack);
  }

  return (
    <>
      {options.map((opt) => (
        <ModalDropdownMenuItem
          key={`mediaMenu_selectGuide_guide_${opt.guide_id}`}
          selected={opt.guide_id === currentItem.selected_audio.guide_id}
          onSelect={() => player?.switchTo(opt)}
        >
          <GuideInfo guide={guides.find((g) => g.id === opt.guide_id)} />
        </ModalDropdownMenuItem>
      ))}
    </>
  );
};
