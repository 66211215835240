import stylex from "@stylexjs/stylex";
import { forwardRef } from "react";

import { animations } from "../../../../../global/stylex/animations.stylex";
import {
  radius,
  semanticColors,
  spacing,
} from "../../../../../global/stylex/vars.stylex";
import type { HallowElementProps, WithStylexArray } from "../../../../types";

export type SkeletonProps = WithStylexArray<HallowElementProps<"div">>;

const styles = stylex.create({
  base: {
    animation: `${animations.pulse} 2s cubic-bezier(0.4, 0, 0.6, 1) infinite`,
    backgroundColor: semanticColors.neutralsLowest,
    borderRadius: radius.m,
    height: spacing.full,
    width: spacing.full,
  },
});

export const Skeleton = forwardRef<any, SkeletonProps>(
  ({ styleXArray, ...props }, ref) => (
    <div {...stylex.props(styles.base, styleXArray)} {...props} />
  ),
);
