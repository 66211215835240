"use client";

import { HallowAnalyticsEvent, useAnalytics } from "@packages/analytics";
import { useTranslations } from "@packages/i18n";
import { type FlaggableType, URL_SELF } from "@packages/sdk";
import * as stylex from "@stylexjs/stylex";

import {
  semanticColors,
  spacing,
  stroke,
} from "../../../../../../global/stylex/vars.stylex";
import { FlagRecordButton } from "../../../../blocks";
import {
  CampaignJoinLeaveButton,
  ShareCampaignButton,
} from "../../../../blocks/primitives";
import { EnterIcon, ExitIcon, ReportIcon, ShareIcon } from "../../../icons";
import type { ModalDropdownMenuProps } from "../../../modals";
import {
  Cell,
  ModalDropdownMenu,
  ModalDropdownMenuItem,
} from "../../../modals";
import type { CampaignSmallProps } from "../CampaignSmall";
import { CampaignSmall } from "../CampaignSmall";

/**
 * Constants
 * styleX requires these to be statically defined in this file
 */
const cssMediaMinWithTablet = "@media (min-width: 768px)";

const styles = stylex.create({
  buttonReset: {
    border: "none",
    margin: spacing.none,
    textAlign: "left",
  },
  campaignSmall: {
    marginBottom: spacing.s,
    paddingLeft: spacing.s,
    paddingRight: spacing.s,
  },
  cellReport: {
    color: `${semanticColors.errorMedium} !important`,
  },
  content: {
    borderColor: semanticColors.neutralsLowest,
    borderStroke: stroke.regular,
    borderStyle: "solid",
    gap: spacing.none,
    maxWidth: {
      [cssMediaMinWithTablet]: 360,
    },
    paddingTop: spacing.l,
    width: {
      [cssMediaMinWithTablet]: "100vw",
      default: `calc(${spacing.full} - calc(2 * ${spacing.s}))`,
    },
  },
  menu: {
    display: "flex",
    flexDirection: "column",
    gap: spacing.xxs,
    width: spacing.full,
  },
  modalOverlay: {
    display: "none",
  },
});

export type CampaignModalDropdownMenuProps = ModalDropdownMenuProps & {
  campaignId: number;
  campaignName?: string;
  campaignSmallProps?: CampaignSmallProps;
  joined?: boolean;
  hasEnded?: boolean;
};

export const CampaignModalDropdownMenu = ({
  campaignId,
  campaignName,
  campaignSmallProps,
  joined,
  hasEnded,
  ...props
}: CampaignModalDropdownMenuProps) => {
  const analytics = useAnalytics();
  const t = useTranslations();

  const itemProps = (option) => {
    return {
      asChild: true,
      onPointerDown: () => {
        analytics.track({
          event: HallowAnalyticsEvent.TappedCampaignOption,
          properties: {
            campaign: campaignId,
            campaign_name: campaignName,
            option: option,
          },
        });
      },
    };
  };

  return (
    <ModalDropdownMenu
      {...props}
      contentStyleXArray={[props.contentStyleXArray, styles.content]}
      dropdownMenuProps={props.dropdownMenuProps}
      modalProps={{
        modal: false,
        overlayStyleXArray: [styles.modalOverlay],
      }}
    >
      <CampaignSmall
        activeState={false}
        focusState={false}
        hoverState={false}
        size="s"
        {...campaignSmallProps}
        {...stylex.props(styles.campaignSmall)}
      />
      <div {...stylex.props(styles.menu)}>
        <ModalDropdownMenuItem
          dropdownMenuItemProps={{ asChild: true }}
          modalItemProps={{ asChild: true }}
        >
          <Cell
            asChild
            icon={<ShareIcon />}
            text="Share"
            onPointerDown={() => {
              analytics.track({
                event: HallowAnalyticsEvent.ViewedScreen,
                properties: {
                  screen_name: "share_audio_view",
                  type: "campaign",
                },
              });
              // TODO: get this dropdown item working with itemProps() above
              analytics.track({
                event: HallowAnalyticsEvent.TappedCampaignOption,
                properties: {
                  campaign: campaignId,
                  campaign_name: campaignName,
                  option: "share",
                },
              });
            }}
            {...stylex.props(styles.buttonReset)}
          >
            <ShareCampaignButton
              shareOptions={{
                color: campaignSmallProps.color,
                description: campaignSmallProps.description,
                imgSrc: campaignSmallProps.imgSrc.large,
                link: `${URL_SELF}/campaigns/${campaignId}`,
                title: campaignSmallProps.title,
                type: "campaign",
              }}
            />
          </Cell>
        </ModalDropdownMenuItem>
        {!hasEnded && (
          <ModalDropdownMenuItem
            dropdownMenuItemProps={itemProps(joined ? "leave" : "join")}
            modalItemProps={itemProps(joined ? "leave" : "join")}
          >
            <Cell
              asChild
              icon={joined ? <ExitIcon /> : <EnterIcon />}
              text={
                joined ? t("campaign_details_leave") : t("action_join_campaign")
              }
              {...stylex.props(styles.buttonReset)}
            >
              <CampaignJoinLeaveButton
                campaignId={campaignId}
                campaignName={campaignName}
                joined={joined}
              />
            </Cell>
          </ModalDropdownMenuItem>
        )}
        <ModalDropdownMenuItem
          dropdownMenuItemProps={itemProps("report")}
          modalItemProps={itemProps("report")}
          options={{ closeModalOnSelect: false }}
        >
          <Cell
            asChild
            icon={<ReportIcon />}
            text={t("dialog_option_report_campaign")}
            {...stylex.props(styles.buttonReset, styles.cellReport)}
          >
            <FlagRecordButton
              flaggableId={campaignId}
              flaggableType={"campaign" as FlaggableType}
            />
          </Cell>
        </ModalDropdownMenuItem>
      </div>
    </ModalDropdownMenu>
  );
};
