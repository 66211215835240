import * as stylex from "@stylexjs/stylex";
import type { ReactElement } from "react";
import { forwardRef, Fragment } from "react";

import {
  numericPercentages,
  numericValues,
  radius,
  semanticColors,
  spacing,
} from "../../../../../global/stylex/vars.stylex";
import {
  type HallowElement,
  type HallowElementProps,
  type WithAsChild,
  type WithStylexArray,
} from "../../../../types";
import {
  determineChildrenInject,
  determineElementFromAsChild,
} from "../../../../utils";
import type { IconElement } from "../../icons";
import { ChevronRightIcon } from "../../icons";
import { Avatar } from "../Avatar";
import { Skeleton } from "../Skeleton";
import { Text } from "../Text";

const hallowElement: HallowElement = "div";

const styles = stylex.create({
  base: {
    alignItems: "center",
    backgroundColor: {
      default: semanticColors.background,
      ":hover": semanticColors.neutralsLowest,
      ":focus": semanticColors.neutralsLowest,
      ":active": semanticColors.neutralsLowest,
    },
    borderRadius: radius.m,
    color: semanticColors.primary,
    cursor: "pointer",
    display: "flex",
    gap: spacing.ms,
    justifyContent: "space-between",
    opacity: {
      default: numericValues[1],
      ":focus": numericValues[0.6],
      ":active": numericValues[0.6],
    },
    paddingBottom: spacing.s,
    paddingLeft: spacing.m,
    paddingRight: spacing.m,
    paddingTop: spacing.s,
    width: numericPercentages[100],
  },
  content: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    gap: spacing.t,
    width: spacing.t,
  },
  disabled: {
    backgroundColor: semanticColors.background,
    cursor: "not-allowed",
    opacity: numericValues[0.5],
  },
  metadata: {
    color: semanticColors.neutralsMedium,
  },
});

const stylesLoading = stylex.create({
  base: {
    alignItems: "center",
    display: "flex",
    gap: spacing.ms,
    paddingBottom: spacing.s,
    paddingLeft: spacing.m,
    paddingRight: spacing.m,
    paddingTop: spacing.s,
    width: numericPercentages[100],
  },
  content: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    gap: spacing.xs,
  },
  description: {
    height: spacing.m,
  },
  title: {
    height: 20,
  },
});

export type ListProfileProps = WithAsChild<
  WithStylexArray<HallowElementProps<typeof hallowElement>>
> & {
  avatar?: ReactElement;
  description?: string;
  disabled?: boolean;
  icon?: IconElement;
  loading?: boolean;
  metadata?: string;
  title: string;
};

export const ListProfile = forwardRef<any, ListProfileProps>(
  (
    {
      asChild = false,
      avatar,
      children,
      description,
      disabled,
      icon = <ChevronRightIcon />,
      loading = false,
      metadata,
      styleXArray = [],
      title,
      ...props
    },
    ref,
  ) => {
    if (loading)
      return (
        <div
          ref={ref}
          {...props}
          {...stylex.props(stylesLoading.base, styleXArray)}
        >
          <Avatar loading size={64} />
          <div {...stylex.props(stylesLoading.content)}>
            <Skeleton styleXArray={[stylesLoading.title]} />
            <Skeleton styleXArray={[stylesLoading.description]} />
          </div>
        </div>
      );

    const DeterminedElement = determineElementFromAsChild({
      asChild,
      hallowElement,
    });

    const DeterminedChildren = determineChildrenInject({
      afterChildrenInject: [
        avatar,
        <span key="content" {...stylex.props(styles.content)}>
          <Text overflow="ellipsis" size="m" type="title">
            {title}
          </Text>
          {description && (
            <Text overflow="ellipsis" size="s" type="body">
              {description}
            </Text>
          )}
          {metadata && (
            <Text
              overflow="ellipsis"
              size="l"
              styleXArray={[styles.metadata]}
              type="detail"
            >
              {metadata}
            </Text>
          )}
        </span>,
        <Fragment key="icon">{icon}</Fragment>,
      ],
      children: children as JSX.Element,
    });

    return (
      <DeterminedElement
        ref={ref}
        {...stylex.props(
          styles.base,
          disabled ? styles.disabled : null,
          styleXArray,
        )}
        {...{ disabled, ...props }}
      >
        {DeterminedChildren}
      </DeterminedElement>
    );
  },
);

ListProfile.displayName = "ListProfile";
