"use client";

import stylex from "@stylexjs/stylex";
import type { ReactNode } from "react";
import { useEffect, useState } from "react";

import { semanticColors, zIndices } from "../../../global/stylex/vars.stylex";
import { useScroll } from "../../hooks";
import { Loader } from "../blocks";

const styles = stylex.create({
  div: {
    alignItems: "center",
    backgroundColor: semanticColors.background,
    display: "flex",
    height: "100vh",
    justifyContent: "center",
    position: "fixed",
    width: "100vw",
    zIndex: zIndices.loading,
  },
});

export type LoadingWrapperProps = { children: ReactNode; isLoading?: boolean };

export const LoadingWrapper = ({
  children,
  isLoading = false,
}: LoadingWrapperProps) => {
  const [isMounted, setIsMounted] = useState(false);

  const { allowScroll, disableScroll } = useScroll();

  useEffect(() => {
    setIsMounted(true);

    return () => {
      setIsMounted(false);
    };
  }, []);

  useEffect(() => {
    if (!isLoading && isMounted) allowScroll();
    else disableScroll();

    return () => {
      disableScroll();
    };
  }, [isLoading, isMounted]);

  if (isLoading || !isMounted)
    return (
      <div {...stylex.props(styles.div)}>
        <Loader />
      </div>
    );

  return children;
};
