"use client";

import type { MediaPlayerProps, MediaSizes } from "@packages/media";
import { getContentImage, usePlayer } from "@packages/media";
import * as Dialog from "@radix-ui/react-dialog";
import * as Toolbar from "@radix-ui/react-toolbar";
import * as stylex from "@stylexjs/stylex";
import type { MouseEventHandler } from "react";
import { createContext, useCallback, useEffect, useState } from "react";
import { RemoveScroll } from "react-remove-scroll";

import {
  numericPercentages,
  numericPixels,
  numericValues,
  radius,
  shadesRaw,
  spacing,
  zIndices,
} from "../../../../../global/stylex/vars.stylex";
import {
  useDynamicViewport,
  useDynamicViewportHeight,
  useIsDesktopViewport,
} from "../../../../hooks";
import { stylexDynamics } from "../../../../lib";
import { useToastPositioning } from "../../../../providers";
import { colorMix } from "../../../../utils";
import {
  MediaControls,
  MediaHeader,
  MediaInfo,
  SettingsContainer,
} from "../../blocks";

export type PlayerInternalContextShape = {
  collapsePlayer: MouseEventHandler<HTMLButtonElement>;
  transcriptOpen: boolean;
  transcriptOpenChangeHandler: (open: boolean) => void;
};

export const PlayerInternalContext = createContext<PlayerInternalContextShape>({
  collapsePlayer: () => {},
  transcriptOpen: false,
  transcriptOpenChangeHandler: () => {},
});

const NORMAL_HEIGHT = "@media screen and (min-height: 800px)";
const MEDIUM_HEIGHT =
  "@media screen and (min-height: 700px) and (max-height: 800px)";
const SMALL_HEIGHT =
  "@media screen and (min-height: 500px) and (max-height: 700px)";

const outerContainerStyles = stylex.create({
  "collapsed-desktop": {
    bottom: spacing.l,
    left: numericPixels[80],
    pointerEvents: "all",
    position: "fixed",
    right: numericPixels[80],
    width: "calc(100vw - 160px)",
  },
  "collapsed-mobile": {
    pointerEvents: "all",
    position: "fixed",
  },
  default: {
    display: "block", // this is necessary because it's an anchor element
    opacity: 1,
    transition: "opacity 250ms ease-out",
    zIndex: zIndices.mediaPlayer,
  },
  "expanded-desktop": {
    bottom: numericValues[0],
    display: "flex",
    left: numericValues[0],
    placeContent: "center",
    placeItems: "center",
    pointerEvents: "none",
    position: "fixed",
    right: numericValues[0],
    top: numericValues[0],
  },
  "expanded-mobile": {
    pointerEvents: "none",
    position: "fixed",
  },
  "fullscreen-desktop": {},
  "fullscreen-mobile": {},
  "hidden-desktop": {
    display: "none",
  },
  "hidden-mobile": {
    display: "none",
  },
  transition: {
    opacity: 0,
  },
});

const clickCatcherStyles = stylex.create({
  default: {
    bottom: 0,
    left: 0,
    // We need this so that we can "disable" the anchor that opens the expanded media player without letting
    // pointer events fall through to whatever is behind the media player
    pointerEvents: "all",
    position: "absolute",
    right: 0,
    top: 0,
    zIndex: -1,
  },
  "collapsed-mobile": {
    display: "none",
  },
  "collapsed-desktop": {
    display: "none",
  },
});

const innerContainerStyles = stylex.create({
  "collapsed-desktop": {
    borderRadius: radius.m,
    padding: spacing.s,
    position: "relative",
  },
  "collapsed-mobile": {
    alignItems: "center",
    borderRadius: radius.m,
    bottom: spacing.l,
    columnGap: spacing.s,
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    left: spacing.m,
    padding: spacing.s,
    position: "fixed",
    right: spacing.m,
    width: "calc(100vw - 32px)",
  },
  "expanded-desktop": {
    display: "grid",
    gridTemplateAreas: '"header" "main" "controls"',
    gridTemplateColumns: "1fr",
    gridTemplateRows: "min-content 1fr min-content",
    height: numericPercentages[100],
    maxWidth: "1376px", // 1280 + spaces.xxl
    padding: spacing.xxl,
    pointerEvents: "auto",
    width: numericPercentages[100],
  },
  "expanded-mobile": {
    bottom: numericValues[0],
    display: "grid",
    gridTemplateAreas: '"collapse" "media" "details"',
    gridTemplateColumns: "1fr",
    gridTemplateRows: "0 1fr min-content",
    left: numericValues[0],
    pointerEvents: "auto",
    position: "fixed",
    right: numericValues[0],
    top: numericValues[0],
  },
  "fullscreen-desktop": {},
  "fullscreen-mobile": {},
  "hidden-desktop": {
    display: "none",
  },
  "hidden-mobile": {
    display: "none",
  },
  "collapsed-classic-mobile": {
    bottom: numericPixels[64],
  },
});

const mediaDisplayContainerStyles = stylex.create({
  "collapsed-desktop": {
    alignItems: "center",
    columnGap: spacing.m,
    display: "grid",
    gridTemplateAreas: '"info playback settings"',
    gridTemplateColumns: "1fr max-content 1fr",
    gridTemplateRows: "1fr",
  },
  "collapsed-mobile": {
    alignItems: "center",
    columnGap: spacing.s,
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    justifyContent: "space-between",
  },
  "expanded-desktop": {
    display: "flex",
    flexDirection: "column",
    gridArea: "main",
    justifySelf: "center",
    maxWidth: numericPixels[480],
    placeContent: "center",
    placeItems: "center",
    position: "relative",
    rowGap: spacing.ml,
  },
  "expanded-mobile": {
    display: "flex",
    flexDirection: "column",
    justifySelf: "center",
    maxWidth: "100vw",
    placeContent: "center",
    placeItems: "center",
    position: "relative",
    rowGap: spacing.ml,
    width: numericPercentages[100],
  },
  "fullscreen-desktop": {},
  "fullscreen-mobile": {},
  "hidden-desktop": {},
  "hidden-mobile": {},
});

const mediaStyles = stylex.create({
  "collapsed-desktop": {
    gridArea: "info",
  },
  "collapsed-mobile": {},
  "expanded-desktop": {
    height: {
      [MEDIUM_HEIGHT]: "auto",
      [NORMAL_HEIGHT]: numericPixels[480],
      [SMALL_HEIGHT]: "auto",
      default: 0,
    },
    width: {
      [MEDIUM_HEIGHT]: numericPercentages[100],
      [NORMAL_HEIGHT]: numericPercentages[100],
      default: numericPercentages[50],
    },
  },
  "expanded-mobile": {
    display: "flex",
    gridColumn: "media",
    gridRowEnd: "details-start",
    gridRowStart: "collapse-end",
    height: numericPercentages[100],
    margin: "-64px 0 -48px",
    width: numericPercentages[100],
    zIndex: -1,
  },
  "fullscreen-desktop": {},
  "fullscreen-mobile": {},
  "hidden-desktop": {},
  "hidden-mobile": {},
});

const infoStyles = stylex.create({
  "collapsed-desktop": {
    gridArea: "info",
    paddingLeft: numericPixels[80], // image: 64, gap: 16
  },
  "collapsed-mobile": {
    flexGrow: 1,
  },
  "expanded-desktop": {
    marginTop: numericPixels[4], // difference between ml and l for row gaps
    width: numericPercentages[100],
  },
  "expanded-mobile": {
    width: numericPercentages[100],
  },
  "fullscreen-desktop": {},
  "fullscreen-mobile": {},
  "hidden-desktop": {},
  "hidden-mobile": {},
});

const lowerSectionStyles = stylex.create({
  "collapsed-desktop": {},
  "collapsed-mobile": {},
  "expanded-desktop": {},
  "expanded-mobile": {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    flexWrap: "nowrap",
    gridColumn: "details",
    gridRowEnd: "details-end",
    gridRowStart: "media-end",
    justifyContent: "space-between",
    paddingBottom: spacing.xl,
    paddingLeft: spacing.xl,
    paddingRight: spacing.xl,
    paddingTop: spacing.xl,
    position: "relative",
    rowGap: spacing.m,
    width: numericPercentages[100],
  },
  "fullscreen-desktop": {},
  "fullscreen-mobile": {},
  "hidden-desktop": {},
  "hidden-mobile": {},
});

export const MediaPlayer = ({
  children,
  mediaDetails,
  onClose,
  onCollapse,
  onExpand,
}: MediaPlayerProps) => {
  const player = usePlayer();
  const isDesktop = useDynamicViewport({ minimumWidth: 1024 });
  const isTallEnough = useDynamicViewportHeight({
    minimumHeight: 325,
    defaultState: true,
  });
  const classicIsDesktop = useIsDesktopViewport();
  const size: MediaSizes = `${player?.display ?? "hidden"}-${isDesktop ? "desktop" : "mobile"}`;
  const colorHex = getContentImage(mediaDetails)?.color_hex ?? "#000000";
  const [transcriptOpen, setTranscriptOpen] = useState<boolean>(false);
  const { setHasPlayer } = useToastPositioning();

  useEffect(() => {
    setHasPlayer(player?.display === "collapsed");
  }, [player?.display]);

  useEffect(() => {
    if (!isTallEnough) onCollapse();
  }, [isTallEnough]);

  const colors = colorMix<"array">({
    source: colorHex,
    adjustment: shadesRaw.shade30,
    format: "array",
  }) as number[];
  let red = 0,
    green = 0,
    blue = 0;
  if (Array.isArray(colors)) [red, green, blue] = colors;

  const handleClose: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation();
    onClose();
  };
  const handleCollapse: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation();
    onCollapse();
  };

  const ControlsContainer = useCallback(
    ({ children }) => {
      if (size === "expanded-mobile") {
        return (
          <div
            key={"mediaLowerSection"}
            {...stylex.props(
              lowerSectionStyles[size],
              size === "expanded-mobile"
                ? stylexDynamics.backgroundGradientSpread({
                    direction: "to bottom",
                    red,
                    green,
                    blue,
                    spread: 32,
                  })
                : null,
            )}
          >
            {children}
          </div>
        );
      }
      return <>{children}</>;
    },
    [size],
  );

  return (
    <Dialog.Root>
      <RemoveScroll enabled={player?.display === "expanded"}>
        <Toolbar.Root asChild>
          <Dialog.Trigger asChild>
            <Toolbar.Button asChild>
              <a
                key={"mediaOuterContainer"}
                tabIndex={player?.display !== "collapsed" ? -1 : 0}
                onClick={(e) => {
                  if (player?.display === "collapsed") onExpand(e);
                }}
                {...stylex.props(
                  outerContainerStyles.default,
                  outerContainerStyles[size],
                  size === "expanded-desktop"
                    ? stylexDynamics.colorMixBackgroundColor({
                        source: colorHex,
                        adjustment: shadesRaw.shade30,
                      })
                    : null,
                  player?.inTransition ? outerContainerStyles.transition : null,
                )}
              >
                <PlayerInternalContext.Provider
                  value={{
                    collapsePlayer: onCollapse,
                    transcriptOpenChangeHandler: setTranscriptOpen,
                    transcriptOpen,
                  }}
                >
                  <div
                    key={"mediaClickCatcher"}
                    {...stylex.props(
                      clickCatcherStyles.default,
                      clickCatcherStyles[size],
                    )}
                    onClick={(e) => e.stopPropagation()}
                  />
                  <div
                    key={"mediaInnerContainer"}
                    {...stylex.props(
                      innerContainerStyles[size],
                      size === "expanded-desktop"
                        ? null
                        : stylexDynamics.colorMixBackgroundColor({
                            source: colorHex,
                            adjustment: shadesRaw.shade30,
                          }),
                      size === "collapsed-mobile" && !classicIsDesktop
                        ? innerContainerStyles["collapsed-classic-mobile"]
                        : null,
                    )}
                  >
                    <MediaHeader size={size} onCollapse={handleCollapse} />
                    <div
                      key={"mediaAllDisplay"}
                      {...stylex.props(mediaDisplayContainerStyles[size])}
                    >
                      <div
                        key={"mediaItself"}
                        {...stylex.props(mediaStyles[size])}
                      >
                        {/* Never ever ever change my ancestors dynamically */}
                        {children}
                      </div>
                      <ControlsContainer>
                        <MediaInfo
                          styleXArray={[infoStyles[size]]}
                          size={size}
                          showMenu={isDesktop || player?.display === "expanded"}
                        />
                        <MediaControls size={size} onClose={handleClose} />
                        {size === "expanded-desktop" ? null : (
                          <SettingsContainer size={size} />
                        )}
                      </ControlsContainer>
                    </div>
                    {size === "expanded-desktop" ? (
                      <SettingsContainer size={size} />
                    ) : null}
                  </div>
                </PlayerInternalContext.Provider>
              </a>
            </Toolbar.Button>
          </Dialog.Trigger>
        </Toolbar.Root>
      </RemoveScroll>
    </Dialog.Root>
  );
};
