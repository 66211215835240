"use client";

import { useTranslations } from "@packages/i18n";
import * as stylex from "@stylexjs/stylex";
import { useState } from "react";

import { themes } from "../../../../../../global/stylex/themes.stylex";
import {
  numericPercentages,
  radius,
  semanticColors,
  spacing,
  stroke,
  zIndices,
} from "../../../../../../global/stylex/vars.stylex";
import {
  ColorIcon,
  DropdownMenu,
  DropdownMenuHeader,
  DropdownMenuItem,
  IconButton,
  Modal,
  ModalHeader,
  ModeIcon,
  TabGroup,
  TabGroupItem,
  Text,
  TextSizeIcon,
  Toggle,
} from "../../../../../components";
import { useIsDesktopViewport } from "../../../../../hooks";

const ON_DESKTOP = "@media screen and (min-width: 768px)";

const toggleStyles = stylex.create({
  container: {
    alignItems: "center",
    border: `${stroke.regular} solid ${semanticColors.fixedLightenLowest}`,
    borderRadius: radius.m,
    columnGap: spacing.m,
    display: "grid",
    gridTemplateAreas: '"icon text toggle"',
    gridTemplateColumns: "min-content 1fr min-content",
    gridTemplateRows: "1fr",
    padding: spacing.m,
  },
  text: {
    alignItems: "flex-start",
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    flexWrap: "nowrap",
    justifyContent: "flex-start",
    rowGap: spacing.t,
  },
});

const mobileStyles = stylex.create({
  menuItemContainer: {
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    justifyContent: "center",
    width: numericPercentages[100],
  },
  menuItem: {
    alignItems: "center",
    borderRadius: radius.m,
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    flexShrink: 1,
    height: "max-content",
    justifyContent: "center",
    minWidth: "revert",
    padding: `${spacing.m} 0`,
    rowGap: spacing.s,
  },
  menuHeader: {
    marginTop: spacing.m,
  },
});

const settingsStyles = stylex.create({
  default: {
    opacity: 1,
    pointerEvents: "auto",
    position: "fixed",
    right: {
      [ON_DESKTOP]: "80px",
      default: spacing.m,
    },
    top: {
      [ON_DESKTOP]: "80px",
      default: spacing.m,
    },
    transition: "opacity 250ms ease-out",
    willChange: "opacity",
  },
});

const colorStyles = stylex.create({
  dark: {
    color: semanticColors.primary,
  },
});

const desktopStyles = stylex.create({
  item: {
    justifyContent: "flex-start",
  },
  text: {
    flexGrow: 1,
  },
});

const dropdownStyles = stylex.create({
  default: {
    zIndex: zIndices.modalOverTranscript,
  },
});

export type TextSettingsProps = {
  scrollStage: 0 | 1;
  immersive: "immersive" | "default";
  fontSize: "s" | "m" | "l";
  setFontSize: (f: "s" | "m" | "l") => void;
  setImmersive: (i: "immersive" | "default") => void;
};

export const TextSettings = ({
  scrollStage,
  immersive,
  fontSize,
  setFontSize,
  setImmersive,
}: TextSettingsProps) => {
  const onDesktop = useIsDesktopViewport();
  const t = useTranslations();
  const [open, setOpen] = useState<boolean>(false);

  const menuTrigger = (
    <IconButton
      icon={<TextSizeIcon.Small />}
      variant={"transparentPrimary"}
      styleXArray={[settingsStyles.default]}
    />
  );

  if (onDesktop) {
    return (
      <DropdownMenu
        trigger={menuTrigger}
        contentStyleXArray={[
          immersive === "immersive" ? [themes.dark, colorStyles.dark] : null,
          dropdownStyles.default,
        ]}
        align={"end"}
      >
        <DropdownMenuHeader key={"tosSettingsHeader"}>
          {t("text_on_screen_text_options")}
        </DropdownMenuHeader>
        <DropdownMenuItem
          key={"tosFontS"}
          startIcon={<TextSizeIcon.Small />}
          selected={fontSize === "s"}
          onSelect={() => setFontSize("s")}
          styleXArray={[desktopStyles.item]}
          asChild
        >
          <div>
            <span {...stylex.props(desktopStyles.text)}>
              {t("transcript_options_dialog_text_size_small")}
            </span>
          </div>
        </DropdownMenuItem>
        <DropdownMenuItem
          key={"tosFontM"}
          startIcon={<TextSizeIcon.Medium />}
          selected={fontSize === "m"}
          onSelect={() => setFontSize("m")}
          styleXArray={[desktopStyles.item]}
          asChild
        >
          <div>
            <span {...stylex.props(desktopStyles.text)}>
              {t("transcript_options_dialog_text_size_medium")}
            </span>
          </div>
        </DropdownMenuItem>
        <DropdownMenuItem
          key={"tosFontL"}
          startIcon={<TextSizeIcon.Large />}
          selected={fontSize === "l"}
          onSelect={() => setFontSize("l")}
          styleXArray={[desktopStyles.item]}
          asChild
        >
          <div>
            <span {...stylex.props(desktopStyles.text)}>
              {t("transcript_options_dialog_text_size_large")}
            </span>
          </div>
        </DropdownMenuItem>
        <div key={"tosImmersion"} {...stylex.props(toggleStyles.container)}>
          <ModeIcon color={semanticColors.onOverlayDarkMed} />
          <div {...stylex.props(toggleStyles.text)}>
            <Text key={"immersionModeTitle"} type={"title"} size={"m"}>
              {t("text_on_screen_immersion_mode")}
            </Text>
            <Text key={"immersionModeExplanation"} type={"detail"} size={"xl"}>
              {t("text_on_screen_immersion_mode_explanation")}
            </Text>
          </div>
          <Toggle
            pressed={immersive === "immersive"}
            onPressedChange={(b) => setImmersive(b ? "immersive" : "default")}
          />
        </div>
      </DropdownMenu>
    );
  }

  return (
    <Modal
      trigger={menuTrigger}
      contentStyleXArray={[immersive ? [themes.dark, colorStyles.dark] : null]}
      open={open}
      onOpenChange={setOpen}
      overlayProps={{ onClick: () => setOpen(false) }}
    >
      <ModalHeader>{t("transcript_options_dialog_text_size")}</ModalHeader>
      <TabGroup
        type={"single"}
        value={fontSize}
        styleXArray={[mobileStyles.menuItemContainer]}
        onValueChange={setFontSize}
      >
        <TabGroupItem
          styleXArray={[mobileStyles.menuItem]}
          value={"s"}
          parentState={fontSize}
          variant={"select"}
        >
          <>
            <TextSizeIcon.Small />
            <Text type={"button"} size={"s"}>
              {t("transcript_options_dialog_text_size_small")}
            </Text>
          </>
        </TabGroupItem>
        <TabGroupItem
          styleXArray={[mobileStyles.menuItem]}
          key={"tosFontM"}
          value={"m"}
          parentState={fontSize}
          variant={"select"}
        >
          <>
            <TextSizeIcon.Medium />
            <Text type={"button"} size={"s"}>
              {t("transcript_options_dialog_text_size_medium")}
            </Text>
          </>
        </TabGroupItem>
        <TabGroupItem
          styleXArray={[mobileStyles.menuItem]}
          key={"tosFontL"}
          value={"l"}
          parentState={fontSize}
          variant={"select"}
        >
          <>
            <TextSizeIcon.Large />
            <Text type={"button"} size={"s"}>
              {t("transcript_options_dialog_text_size_large")}
            </Text>
          </>
        </TabGroupItem>
      </TabGroup>
      <ModalHeader styleXArray={[mobileStyles.menuHeader]}>
        {t("transcript_options_dialog_background")}
      </ModalHeader>
      <TabGroup
        type={"single"}
        value={immersive}
        onValueChange={setImmersive}
        styleXArray={[mobileStyles.menuItemContainer]}
      >
        <TabGroupItem
          key={"tosImmersive"}
          styleXArray={[mobileStyles.menuItem]}
          value={"immersive"}
          parentState={immersive}
          variant={"select"}
        >
          <>
            <ColorIcon />
            <Text type={"button"} size={"s"}>
              {t("transcript_options_dialog_background_color")}
            </Text>
          </>
        </TabGroupItem>
        <TabGroupItem
          key={"tosDefaultBg"}
          styleXArray={[mobileStyles.menuItem]}
          value={"default"}
          parentState={immersive}
          variant={"select"}
        >
          <>
            <ModeIcon />
            <Text type={"button"} size={"s"}>
              {t("transcript_options_dialog_background_theme")}
            </Text>
          </>
        </TabGroupItem>
      </TabGroup>
    </Modal>
  );
};
