"use client";

import type { MediaSizes } from "@packages/media";
import { useCurrentQueueItem, usePlayer } from "@packages/media";
import * as stylex from "@stylexjs/stylex";
import type { MouseEventHandler } from "react";
import { Fragment } from "react";

import {
  numericValues,
  spacing,
} from "../../../../../../global/stylex/vars.stylex";
import type { WithButtonWrapper } from "../../../../../types";
import {
  BackMediaButton,
  CloseButton,
  ForwardButton,
  PlayPause,
} from "../../controls";

const buttonStyles = stylex.create({
  default: {
    height: "auto",
    width: "auto",
  },
  "expanded-mobile": {
    padding: numericValues[0],
  },
  "expanded-desktop": {
    padding: numericValues[0],
  },
});

const placeholderStyles = stylex.create({
  default: {
    height: "80px",
    width: "80px",
  },
  "collapsed-desktop": {
    height: "40px",
    width: "40px",
  },
});

const containerStyles = stylex.create({
  controlSet: {
    alignContent: "center",
    columnGap: spacing.s,
    display: "grid",
    gridAutoColumns: "min-content",
    gridTemplateAreas: '"back play forward"',
    gridTemplateRows: "1fr",
    placeContent: "end",
  },
  "collapsed-mobile": {
    columnGap: numericValues[0],
    gridTemplateAreas: '"play close"',
  },
  "collapsed-desktop": {
    columnGap: spacing.l,
  },
  "expanded-mobile": {
    justifyContent: "space-between",
  },
  "expanded-desktop": {
    columnGap: spacing.xxl,
  },
});

export type PlaybackControlsProps = WithButtonWrapper<{
  size: MediaSizes;
  onClose: MouseEventHandler<HTMLButtonElement>;
}>;

const SECS_BEFORE_HIDING_RESTART_BUTTON = 10;

export const PlaybackControls = ({
  Wrapper = ({ children }) => <Fragment>{children}</Fragment>,
  size,
  onClose,
}: PlaybackControlsProps) => {
  const player = usePlayer();
  const { currentItem } = useCurrentQueueItem();

  const canSkipIntro =
    player &&
    player.currentTime < 15 &&
    currentItem?.selected_audio.intro_ends_at &&
    player.currentTime < currentItem.selected_audio.intro_ends_at / 1000;

  const canRestart =
    currentItem?.selected_audio?.resumes_at &&
    player &&
    player.autoResumed &&
    player.currentTime <
      currentItem.selected_audio.resumes_at / 1000 +
        SECS_BEFORE_HIDING_RESTART_BUTTON;

  return (
    <div {...stylex.props(containerStyles.controlSet, containerStyles[size])}>
      {size === "collapsed-mobile" ? null : currentItem?.type ===
        "radio_song" ? (
        <div
          {...stylex.props(placeholderStyles.default, placeholderStyles[size])}
        />
      ) : (
        <BackMediaButton
          canRestart={canRestart}
          Wrapper={Wrapper}
          backStyleXArray={[buttonStyles.default, buttonStyles[size]]}
        />
      )}
      <Wrapper asChild>
        <PlayPause tabIndex={0} />
      </Wrapper>
      {size === "collapsed-mobile" ? (
        <CloseButton
          onClick={onClose}
          styleXArray={[buttonStyles.default, buttonStyles[size]]}
        />
      ) : (
        <ForwardButton
          canSkipIntro={canSkipIntro}
          Wrapper={Wrapper}
          forwardStyleXArray={[buttonStyles.default, buttonStyles[size]]}
        />
      )}
    </div>
  );
};
